import React, { forwardRef, useState, useEffect } from "react";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import {
  balanceExceeded,
  createInstantPayout,
  ethereumLabel,
  lnInfoText,
  onchainEstimateMsg,
  onchainInfoText,
  proceedBtn,
  sats,
  sendMaxMsg,
  usdt,
  USDTEthereumMinAmountTxt,
  onchain,
  lightning,
  tronLabel,
  infoText,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  boltzSource,
  callAPIInterface,
  getPayoutBalanceAPI,
  idealTimeLimitReached,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../constants";
import { useFormik } from "formik";
import {
  setExecuteAPICall,
  setIsModalOpen,
  setLoading,
  showToast,
} from "../../redux/common/actions";
import * as yup from "yup";
import history from "@speed/common/src/components/history";
import RequestInstantPayout from "./RequestInstantPayout";
import {
  btcToSatsAmount,
  satsToBtcAmount,
  showAmount,
} from "@speed/common/src/components/constants";
import { sessionService } from "redux-react-session";
import FeesExceedModal from "../Common/FeesExceedModal";
import InsufficientBalanceModal from "../Common/InsufficientBalanceModal";
import { currency } from "@speed/common/src/components/currency";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateInstantPayout = () => {
  const dispatch = useDispatch();

  const [disableConfirm, setDisableConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [balance, setBalance] = useState();
  const [selectedWallet, setSelectedWallet] = useState({});
  const [estimatedFees, setEstimatedFees] = useState(0);
  const [newEstimatedFees, setNewEstimatedFees] = useState();
  const [openFeesModal, setOpenFeesModal] = useState(false);
  const [openInsufficientBalanceModal, setOpenInsufficientBalanceModal] =
    useState(false);
  const [feesLoader, setFeesLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [balanceLoader, setBalanceLoader] = useState(false);
  const [amountInSats, setAmountInSats] = useState(0);
  const [feesBtnLoader, setFeesBtnLoader] = useState(false);
  const [sendMaxAmount, setSendMaxAmount] = useState(null);
  const [session, setSession] = useState(null);
  const [speedFeeValue, setSpeedFeeValue] = useState(0);
  const [currencyAmount, setCurrencyAmount] = useState(0);

  const { isModalOpen, executeAPICall } = useSelector((state) => state.common);
  const user = useSelector((state) => state.auth.user);
  const speedFees = currencyAmount * (speedFeeValue / 100);
  const abortController = new AbortController();
  const exludedLightningPayoutMethods = [onchain, ethereumLabel];

  const initialValues = {
    wallet: null,
    payoutType: "",
    payoutCurrencyType: { name: "Bitcoin", code: "BTC", symbol: "฿" },
    payoutAmount: "",
    note: "",
    asset: "",
    is_boltz: false,
  };

  const validationSchema = yup.object({
    payoutType: yup.string().required(""),
    payoutAmount: yup
      .number()
      .required("")
      .test("validation-payout-amount", async function () {
        const { isValid, errorMessage } = await validatePayoutAmountAndFees({
          payoutType: this?.parent?.payoutType,
          currencyAmount,
          sendMaxAmount,
        });
        if (!isValid) {
          return this.createError({
            path: this.path,
            message: errorMessage,
          });
        } else {
          return true;
        }
      }),
    wallet: yup.mixed().required(),
  });

  const getEstimatedFees = (address, shouldStateSet = true) => {
    setFeesLoader(true);
    let params = {
      address_with_amount: {
        [address]: showAmount({
          amount: currencyAmount,
          currency: targetCurrency,
          divideAmount: targetCurrency === usdt,
        }),
      },
    };
    if (exludedLightningPayoutMethods.includes(values.payoutType))
      params.target_confirmation = 2;

    if (values?.is_boltz) params.source = boltzSource;

    const { signal } = abortController;
    return callAPIInterface(
      "POST",
      "/withdraws/estimate",
      params,
      null,
      null,
      signal
    )
      .then((res) => {
        const convertedFee = +showAmount({
          amount: res?.fee,
          currency: targetCurrency,
        });
        if (shouldStateSet) {
          setEstimatedFees(convertedFee);
          validateForm();
        }
        setFeesLoader(false);
        setDisableConfirm(false);
        return convertedFee;
      })
      .catch((_e) => {
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: onchainEstimateMsg,
            toastVariant: "error",
          })
        );
        setFeesLoader(false);
        setDisableConfirm(true);
      });
  };

  const handleProceed = async (values) => {
    setButtonLoader(true);
    formik.setSubmitting(false);
    const { payoutType, wallet } = values;
    const { totalBalance } = await getTotalBalance();
    if (exludedLightningPayoutMethods.includes(payoutType)) {
      const newFees = await getEstimatedFees(wallet?.address, false);
      if (estimatedFees < newFees) {
        setOpenFeesModal(true);
        setNewEstimatedFees(newFees);
      } else proceedFunc(totalBalance);
    } else {
      proceedFunc(totalBalance);
    }
    setButtonLoader(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleProceed,
    enableReinitialize: true,
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    isSubmitting,
    dirty,
    validateForm,
  } = formik;

  const targetCurrency = values?.asset?.uuid;

  // Get the current balance of account.
  const getPayoutBalance = async () => {
    try {
      const { balance: result } = await getPayoutBalanceAPI(targetCurrency);
      setDisableConfirm(false);
      return result;
    } catch (e) {
      setDisableConfirm(true);
    }
  };

  const getMinMaxAmountLimit = (payoutMethod) => {
    const lightningMaxLimit = Number(process.env.REACT_APP_LIGHTNING_MAX_LIMIT);
    const onchainMaxLimit = Number(process.env.REACT_APP_ONCHAIN_MAX_LIMIT);
    const ethereumMinLimit = Number(process.env.REACT_APP_ETHEREUM_MIN_LIMIT);
    const ethereumMaxLimit = Number(process.env.REACT_APP_ETHEREUM_MAX_LIMIT);
    const boltzMinLimit = Number(process.env.REACT_APP_BOLTZ_MIN_LIMIT);
    const boltzMaxLimit = Number(process.env.REACT_APP_BOLTZ_MAX_LIMIT);

    switch (payoutMethod) {
      case lightning:
        return {
          min: 1,
          max: lightningMaxLimit,
          message: lnInfoText(lightningMaxLimit),
        };
      case onchain:
        if (values?.is_boltz) {
          return {
            min: boltzMinLimit,
            max: boltzMaxLimit,
            message: infoText(
              satsToBtcAmount(boltzMinLimit),
              satsToBtcAmount(boltzMaxLimit)
            ),
          };
        } else {
          return {
            min: 1000,
            max: onchainMaxLimit,
            message: onchainInfoText(satsToBtcAmount(onchainMaxLimit)),
          };
        }
      case ethereumLabel:
      case tronLabel:
        return {
          min: ethereumMinLimit,
          max: ethereumMaxLimit,
          message: USDTEthereumMinAmountTxt(
            payoutMethod,
            ethereumMinLimit,
            ethereumMaxLimit
          ),
        };
      default:
        return {
          min: 0,
          max: 0,
          message: "",
        };
    }
  };

  const validatePayoutAmountAndFees = async ({
    payoutType,
    currencyAmount,
    sendMaxAmount,
    callEstimateFeeAPI = false,
  }) => {
    let isValid = true,
      hideFeeBox = false,
      errorMessage = "";
    const { min, max, message } = getMinMaxAmountLimit(payoutType);

    const totalBalance =
      targetCurrency === sats ? btcToSatsAmount(balance) : balance;

    //validating whether enter amount is having range in min amount/max amount/less then total balance
    if (currencyAmount > totalBalance) {
      isValid = false;
      errorMessage = balanceExceeded;
      hideFeeBox = true;
    } else if (currencyAmount < min || currencyAmount > max) {
      isValid = false;
      hideFeeBox = true;
      errorMessage = message;
    } else if (sendMaxAmount && sendMaxAmount < currencyAmount) {
      isValid = false;
      hideFeeBox = true;
      errorMessage = sendMaxMsg;
    }

    //calling estimate api only when valid entered amount
    if (callEstimateFeeAPI && currencyAmount && isValid) {
      await getEstimatedFees(values.wallet?.address);
    }

    //checking whether amount not exceeds total balance available when entered valid amount
    if (isValid && currencyAmount + estimatedFees + speedFees > totalBalance) {
      isValid = false;
      errorMessage = balanceExceeded;
    }

    return {
      isValid,
      errorMessage,
      hideFeeBox,
    };
  };

  const getTotalBalance = async () => {
    const balanceInTargetCurrency = await getPayoutBalance();
    const totalBalance =
      targetCurrency === sats
        ? btcToSatsAmount(balanceInTargetCurrency)
        : balanceInTargetCurrency;
    return { totalBalance };
  };

  const createNewRequest = () => {
    const params = {
      payout_method_id: values.wallet?.id,
      amount: values.payoutAmount,
      currency: values.payoutCurrencyType?.code,
      target_currency: targetCurrency,
    };
    if (values.note !== "") params.note = values.note;
    if (values.is_boltz) params.source = boltzSource;

    setDisableConfirm(true);
    callAPIInterface("POST", "/payouts", JSON.stringify(params))
      .then((result) => {
        result && history.push(`/payouts/${result.id}`);
        setDisableConfirm(false);
        handleModalClose();
      })
      .catch((_e) => {
        setDisableConfirm(false);
        handleModalClose();
      });
  };

  const proceedFunc = (totalBalance) => {
    if (totalBalance < currencyAmount + estimatedFees + speedFees)
      setOpenInsufficientBalanceModal(true);
    else if (idealTimeLimitReached(session.last_action_time))
      setShowCrucialActionModal(user, true);
    else createNewRequest();
  };

  const handleFeesProceed = async () => {
    setFeesBtnLoader(true);
    const { totalBalance } = await getTotalBalance();
    if (totalBalance < currencyAmount + newEstimatedFees + speedFees) {
      setEstimatedFees(newEstimatedFees);
      setOpenInsufficientBalanceModal(true);
    } else if (idealTimeLimitReached(session.last_action_time))
      setShowCrucialActionModal(user, true);
    else createNewRequest();
    setOpenFeesModal(false);
    setFeesBtnLoader(false);
  };

  const handleTryAgain = () => {
    setOpenInsufficientBalanceModal(false);
    setFieldValue("payoutAmount", "");
    const currencyObj = currency.find(
      (item) => item.code === values.payoutCurrencyType?.code
    );
    setFieldValue("payoutCurrencyType", currencyObj);
    setEstimatedFees(0);
    setBalanceLoader(true);
    // call the balance API to update balance
    getPayoutBalance()
      .then((balance) => {
        setBalanceLoader(false);
        setBalance(balance);
      })
      .catch((_e) => {
        setBalanceLoader(false);
      });
  };

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
  }, []);

  useEffect(() => {
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      createNewRequest();
      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  useEffect(() => {
    formik.validateForm();
  }, [currencyAmount, values.payoutAmount]);

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    setSelectedWallet({});
    setBalance();
    setIsChecked(false);
    setEstimatedFees(0);
  };

  const getFeeBoxValues = (fees) => {
    return targetCurrency === usdt ? fees * 100 : fees;
  };

  const disableBtnCheck =
    !(isValid && dirty) ||
    isSubmitting ||
    !isChecked ||
    disableConfirm ||
    feesLoader;

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={isModalOpen}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={handleModalClose}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {createInstantPayout}
          </Text>
          <Button
            label={proceedBtn}
            variant="contained"
            color="primary"
            className="payment-link-btn"
            onClick={handleSubmit}
            loading={buttonLoader}
            disabled={disableBtnCheck}
          />
        </Toolbar>
      </AppBar>
      <RequestInstantPayout
        formik={formik}
        selectedWallet={selectedWallet}
        setSelectedWallet={setSelectedWallet}
        isModalOpen={isModalOpen}
        setBalance={setBalance}
        balance={balance}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        estimatedFees={estimatedFees}
        getPayoutBalance={getPayoutBalance}
        feesLoader={feesLoader}
        setFeesLoader={setFeesLoader}
        setEstimatedFees={setEstimatedFees}
        balanceLoader={balanceLoader}
        amountInSats={amountInSats}
        setAmountInSats={setAmountInSats}
        validatePayoutAmountAndFees={validatePayoutAmountAndFees}
        setSendMaxAmount={setSendMaxAmount}
        speedFeeValue={speedFeeValue}
        setSpeedFeeValue={setSpeedFeeValue}
        currencyAmount={currencyAmount}
        setCurrencyAmount={setCurrencyAmount}
        getFeeBoxValues={getFeeBoxValues}
        getMinMaxAmountLimit={getMinMaxAmountLimit}
      />
      {openFeesModal && (
        <FeesExceedModal
          openFeesModal={openFeesModal}
          handleClose={() => setOpenFeesModal(false)}
          btnLoader={feesBtnLoader}
          targetCurrency={targetCurrency}
          estimatedFees={getFeeBoxValues(estimatedFees)}
          newEstimatedFees={getFeeBoxValues(newEstimatedFees)}
          handleFeesProceed={handleFeesProceed}
        />
      )}
      {openInsufficientBalanceModal && (
        <InsufficientBalanceModal
          open={openInsufficientBalanceModal}
          handleClose={() => setOpenInsufficientBalanceModal(false)}
          type="Payout"
          targetCurrency={targetCurrency}
          totalAmount={getFeeBoxValues(
            currencyAmount + estimatedFees + speedFees
          )}
          handleTryAgain={handleTryAgain}
        />
      )}
    </Dialog>
  );
};

export default CreateInstantPayout;
