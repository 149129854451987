import { Box, Grid, TableCell, TableRow } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { BTC, emptySubText } from "@speed/common/src/components/messages";
import CustomTable from "@speed/common/src/components/Table/Table";
import { emptyTransaction, calendarTime } from "../images";
import Text from "@speed/common/src/components/Text/Text";
import { callAPIInterface, noOfRecords } from "../constants";
import { currencyLabel, sats, loadingMsg, btc } from "../messages";
import {
  convertExponentialToDecimal,
  fetchRawBalanceData,
  getDefaultWalletCurrencyObj,
  removeTrailingZeros,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import { useDispatch, useSelector } from "react-redux";
import CreatePaymentAddressModal from "./CreatePaymentAddressModal";
import {
  setDefaultSelectedAsset,
  setShowBTCAmount,
  setShowMaintenanceBar,
  setWalletPaymentAddress,
} from "../../redux/common/actions";
import JoinInvite from "../Common/JoinInvite";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import AssetsAmount from "./AssetsAmount";
import SelectCountry from "./SelectCountry";
import { sessionService } from "redux-react-session";
import { HelloBar } from "@speed/common/src/components/HelloBar/HelloBar";
import CancelIcon from "@mui/icons-material/Cancel";
import MaintenanceHelloBar from "./MaintenanceHelloBar";
import PendingTransaction from "./PendingTransaction";
// import PaymentAddressModal from "./PaymentAddress/index";

let fiatCurrency;
const columns = [{ id: "1", title: currencyLabel }];

const MyAssets = () => {
  const queryParam = "?limit=" + noOfRecords;
  const liveMode = useSelector((state) => state.auth.liveMode);
  const {
    defaultSelectedAsset,
    paymentProcessingRecallAPis,
    showMaintenanceBar,
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const isCountryAvailable = currentAccount?.account?.country;
  const defaultCurrency = JSON.parse(
    localStorage.getItem("account_default_currency")
  );

  const [currency, setCurrency] = useState("");
  const [inviteLoading, setInviteLoading] = useState(false);
  const [selectedCurrencyType, setSelectedCurrencyType] =
    useState(defaultSelectedAsset);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [isAssetsLoading, setIsAssetsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [assetsData, setAssetsData] = useState([]);
  const [targetAmountLoading, setTargetAmountLoading] = useState(true);
  const [exchangeRateAmount, setExchangeRateAmount] = useState(null);
  const [balanceData, setBalanceData] = useState(null);
  const [totalUnconfirmed, setTotalUnconfirmed] = useState(0);
  const [isSettingupLnAddress, setIsSettingupLnAddress] = useState(false);
  const [isPaymentAddressLoading, setIsPaymentAddressLoading] = useState(false);
  const [openCountryModal, setOpenCountryModal] = useState(false);
  const [isNoTransactionPerformed, setIsNoTransactionPerformed] =
    useState(false);
  const [currentSession, setCurrentSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [isOpenPaymentAddressModal, setIsOpenPaymentAddressModal] =
  //   useState(false);

  useEffect(() => {
    if (!isCountryAvailable) {
      setOpenCountryModal(true);
    }
  }, []);

  useEffect(() => {
    getCurrentSession();
  }, [liveMode]);

  useEffect(() => {
    fiatCurrency =
      defaultCurrency ||
      getDefaultWalletCurrencyObj(currentAccount?.account?.country);
  }, [defaultCurrency]);

  useEffect(() => {
    if (isCountryAvailable || paymentProcessingRecallAPis) {
      setIsLoading(true);
      fetchAssets();
      getPaymentAddress();
    }
  }, [liveMode, paymentProcessingRecallAPis, isCountryAvailable]);

  useEffect(() => {
    if (isCountryAvailable && selectedCurrencyType?.uuid && balanceData) {
      getExchangeRate();
    }
  }, [balanceData, selectedCurrencyType, isCountryAvailable]);

  useEffect(() => {
    let uuid = [];
    const selectedCurrencyUuid = selectedCurrencyType?.uuid;
    if (selectedCurrencyUuid) {
      setIsNoTransactionPerformed(false);
      getTransaction(selectedCurrencyUuid);
      if (selectedCurrencyUuid === sats) {
        setCurrency(currentSession?.showBtcAmount ? btc : sats);
        currentSession?.showBtcAmount && setBtcFlag(sats);
      } else {
        uuid.push(selectedCurrencyUuid);
        setCurrency(uuid?.[0]);
      }
    }
  }, [liveMode, selectedCurrencyType, paymentProcessingRecallAPis]);

  const getCurrentSession = async () => {
    const session = await sessionService.loadSession();
    if (selectedCurrencyType?.uuid === sats && session?.showBtcAmount) {
      dispatch(
        setShowBTCAmount(currency === sats ? session?.showBtcAmount : false)
      );
      setCurrency(btc);
    }
    setCurrentSession(session);
  };

  const setBtcFlag = (value) => {
    dispatch(
      setShowBTCAmount(value === sats ? currentSession?.showBtcAmount : false)
    );
  };

  // get selected currency recent transactions
  const getTransaction = (currency) => {
    const reqObj = {
      limit: 5,
      target_currency: { in: [currency] },
    };
    callAPIInterface("POST", "/balance-transactions/filter", reqObj)
      .then((res) => {
        setIsNoTransactionPerformed(res?.data?.length === 0);
      })
      .catch((_err) => {});
  };

  const getPaymentAddress = () => {
    dispatch(setWalletPaymentAddress(null));
    fetchPaymentAddress();
  };

  const fetchPaymentAddress = () => {
    setIsPaymentAddressLoading(true);
    callAPIInterface("GET", "/payment-addresses?limit=1")
      .then((res) => {
        if (res) {
          dispatch(setWalletPaymentAddress(res?.data?.[0]?.address));
          setIsPaymentAddressLoading(false);
        }
      })
      .catch((_e) => {
        setIsPaymentAddressLoading(false);
      });
  };

  const fetchAssets = () => {
    getAssets(queryParam);
  };

  const getAssets = (params) => {
    setIsAssetsLoading(true);
    callAPIInterface("GET", "/assets-account" + params, {})
      .then((res) => {
        if (res?.data) {
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1]?.id
            );
          }
          const sortedPaymentMethodAsset = getSortedPaymentMethod(res.data);
          const sortedAsset = getSortedAssets(sortedPaymentMethodAsset);
          const supportedAssets = getSupportedAssets(sortedAsset);
          setAssetsData(supportedAssets);
          if (!defaultSelectedAsset) {
            setSelectedCurrencyType(supportedAssets?.[0]);
            dispatch(setDefaultSelectedAsset(supportedAssets?.[0]));
          }
          getBalanceData();
        }
      })
      .catch(() => {});
  };

  // sort payment method according to weight
  const getSortedPaymentMethod = (myassets) => {
    const sortedPaymentMethodAssetArr = [];
    myassets.forEach((asset) => {
      asset?.payment_method_details?.sort((a, b) => a.weight - b.weight);
      sortedPaymentMethodAssetArr.push(asset);
    });
    return sortedPaymentMethodAssetArr;
  };

  // sort the assets with btc at top
  const getSortedAssets = (myassets) => {
    const sortedArr = [];
    if (myassets?.[0]?.uuid !== sats) {
      myassets.forEach((asset) => {
        if (asset.uuid !== sats) {
          sortedArr.push(asset);
        } else {
          sortedArr.unshift(asset);
        }
      });
    } else {
      return myassets;
    }
    return sortedArr;
  };

  // show only current supported assets
  const getSupportedAssets = (supportedAssets) =>
    supportedAssets.filter(({ uuid }) => ["SATS", "USDT"].includes(uuid));

  const getBalance = (uuid) => {
    const currencyData = balanceData?.find(
      (bal) => bal?.target_currency === uuid
    );
    return (
      Number(
        uuid === sats ? currencyData?.amount : currencyData?.amount / 100
      ) || 0
    );
  };

  const getBalanceData = () => {
    callAPIInterface("GET", "/balances")
      .then((resData) => {
        if (resData) {
          const data = fetchRawBalanceData(resData);
          setBalanceData(data);
        }
      })
      .catch(() => {})
      .finally(() => setIsAssetsLoading(false));
  };

  // return formatted amount
  const getAmount = (uuid) => {
    const amount = getBalance(uuid);
    return removeTrailingZeros(
      (uuid === sats ? Math.floor(amount) : amount) || 0,
      currency
    );
  };

  const getExchangeRate = () => {
    setTargetAmountLoading(true);
    setIsLoading(true);

    const targetCurrency = selectedCurrencyType?.uuid || sats;
    const enteredAmount = getBalance(targetCurrency) || 0;
    const data = {
      currency: fiatCurrency?.code ?? "USD",
      target_currency: targetCurrency,
    };

    callAPIInterface("POST", "/utility/exchange-rate", data)
      .then((res) => {
        const exchangeRate = res?.target_lowest_rate;
        const targetAmount = Number(enteredAmount) / exchangeRate;
        setExchangeRateAmount(targetAmount ?? 0);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
        setTargetAmountLoading(false);
      });
  };

  const getBtcAmount = (uuid) => {
    const amount = getBalance(uuid);
    const btcAmount = amount ? satsToBtcAmount(amount) : 0;
    return convertExponentialToDecimal(btcAmount);
  };

  let columnsData = columns.map((column) => (
    <TableCell style={{ paddingLeft: "36px" }} colSpan="3" key={column.id}>
      {column.title}
    </TableCell>
  ));

  const handleSelectCurrencyType = (rowItem) => {
    setTotalUnconfirmed(0);
    setSelectedCurrencyType(rowItem);
    dispatch(setDefaultSelectedAsset(rowItem));
  };

  const rowsData =
    !isAssetsLoading &&
    assetsData?.map((rowItem) => (
      <TableRow
        key={rowItem?.id}
        className="clickable"
        style={{
          backgroundColor:
            defaultSelectedAsset?.id === rowItem?.id && "#EFF6FF",
          marginRight: "24px",
        }}
        onClick={() => handleSelectCurrencyType(rowItem)}
      >
        <TableCell sx={{ width: "500px", paddingLeft: "36px !important" }}>
          <Text font="semibold" size={16} withIcon="start">
            <img src={rowItem?.icon} className="asset-icon" alt="icon" />
            {rowItem?.name}
          </Text>
        </TableCell>
        <TableCell width="150px">
          <Box display="flex" justifyContent="flex-end">
            <Box className="asset-amount">
              <Text font="semibold" size={16} className="grey-text">
                {rowItem?.uuid === sats && currentSession?.showBtcAmount
                  ? getBtcAmount(rowItem?.uuid)
                  : getAmount(rowItem?.uuid)}
              </Text>
              <span className="asset-currency">
                {currentSession?.showBtcAmount && rowItem?.uuid === sats
                  ? BTC
                  : rowItem?.uuid}
              </span>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    ));

  const loadMore = useCallback(() => {
    getAssets(queryParams);
  }, [assetsData]);

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    hasMore: hasMore,
    tableRowSkeleton: isAssetsLoading,
    className: "logs-table assets-table",
    noDataImage: emptyTransaction,
    textOnNoData: emptySubText(liveMode, "My assets"),
    loadMore: loadMore,
  };

  const getTxnCurrency = () => {
    let txnCurrency = currency;
    if (currency === BTC) {
      txnCurrency = sats;
    }
    return txnCurrency;
  };

  if (openCountryModal && !isCountryAvailable) {
    return (
      <SelectCountry
        openCountryModal={openCountryModal}
        setOpenCountryModal={setOpenCountryModal}
      />
    );
  }

  const pendingTransactionProps = {
    isNoTransactionPerformed,
    selectedCurrencyType,
    getTxnCurrency,
    currency,
    setTotalUnconfirmed,
    isPaymentAddressLoading,
    setIsSettingupLnAddress,
    totalUnconfirmed,
  };

  return (
    <>
      <JoinInvite setLoading={setInviteLoading} />
      {isCountryAvailable && (
        <Box className="section-wrapper">
          <BackdropLoader
            open={inviteLoading}
            alt="Loading..."
            text={loadingMsg}
            customClass="loading-in-auth"
          />
          {showMaintenanceBar && (
            <HelloBar
              id="maintenance-hello-bar"
              hide={!showMaintenanceBar}
              color="#F1C40F"
              descriptionText={<MaintenanceHelloBar />}
              besideComponent={
                <CancelIcon
                  sx={{
                    height: 20,
                    width: 20,
                    cursor: "pointer",
                    color: "#fff !important",
                  }}
                  onClick={() => dispatch(setShowMaintenanceBar(false))}
                />
              }
              startIcon={<img src={calendarTime} alt="calendar-time" />}
            />
          )}
          <CommonHeader />
          <CustomDivider />
          <Box className="assets-container">
            <Grid container height="100%">
              <Grid item xs={assetsData?.length > 0 ? 5.49 : 12} height="100%">
                <CustomTable {...tableProps} />
              </Grid>
              {assetsData?.length > 0 && (
                <>
                  <CustomDivider flexItem orientation="vertical" />
                  <Grid
                    item
                    mt="16px"
                    xs={6.49}
                    height="100%"
                    position="relative"
                  >
                    <AssetsAmount
                      isAssetsLoading={isLoading}
                      selectedCurrencyType={selectedCurrencyType}
                      targetAmountLoading={targetAmountLoading}
                      fiatCurrency={fiatCurrency}
                      exchangeRateAmount={exchangeRateAmount}
                      getAmount={getAmount}
                      currency={currency}
                      setCurrency={setCurrency}
                      accountAssets={assetsData}
                      currentSession={currentSession}
                      setCurrentSession={setCurrentSession}
                      getBtcAmount={getBtcAmount}
                      getBalance={getBalance}
                    />
                    <PendingTransaction {...pendingTransactionProps} />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {/* <PaymentAddressModal
            isOpenPaymentAddressModal={isOpenPaymentAddressModal}
            setIsOpenPaymentAddressModal={setIsOpenPaymentAddressModal}
          /> */}
          <CreatePaymentAddressModal
            isSettingupLnAddress={isSettingupLnAddress}
            setIsSettingupLnAddress={setIsSettingupLnAddress}
            fetchPaymentAddress={fetchPaymentAddress}
          />
        </Box>
      )}
    </>
  );
};

export default MyAssets;
