import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  receive,
  send,
  sats,
  usdt,
  currentBalance,
  settings,
  denomination,
  btc,
  showBalanceText,
  walletConst,
  paymentConst,
  withdrawConst,
  onTap,
  always,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { getDisplayAmount } from "@speed/common/src/components/constants";
import { BTC } from "@speed/common/src/components/messages";
import ReceiveModal from "./ReceivePayment/ReceiveModal";
import SendModal from "./SendPayment/SendModal";
import {
  setPaymentProcessingRecallApis,
  setShowBTCAmount,
} from "../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { walletBackGroundImage } from "../images";
import { sessionService } from "redux-react-session";
import classNames from "classnames";
import { displayTaprootCurrency } from "../constants";
import NewToSpeedWallet from "./NewToSpeedWallet";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { ButtonToggle } from "@speed/common/src/components/ButtonToggle/ButtonToggle";
import { Modal } from "@speed/common/src/components/Modal/Modal";

const AssetsAmount = ({
  isAssetsLoading,
  selectedCurrencyType,
  targetAmountLoading,
  fiatCurrency,
  exchangeRateAmount,
  getAmount,
  currency,
  setCurrency,
  accountAssets,
  setCurrentSession,
  currentSession,
  getBtcAmount,
  getBalance,
}) => {
  const dispatch = useDispatch();
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);

  const [openReceiveModal, setOpenReceiveModal] = useState(false);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [selectedModeOfShowBalance, setSelectedModeOfShowBalance] =
    useState("always");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [selectedDenomination, setSelectedDenomination] = useState();

  useEffect(() => {
    setSelectedModeOfShowBalance(currentSession?.onTap ? onTap : always);
  }, [currentSession, selectedCurrencyType]);

  useEffect(() => {
    setSelectedDenomination(showBtcAmount ? btc : sats);
  }, [showBtcAmount]);

  const currencyColor = classNames("bg-color-" + currency);
  const isModuleSupportedInWallet =
    selectedCurrencyType?.supported_account_type?.includes(walletConst);
  const isShowReceiveButton =
    isModuleSupportedInWallet &&
    selectedCurrencyType?.supported_modules?.includes(paymentConst);
  const isShowSendButton =
    isModuleSupportedInWallet &&
    selectedCurrencyType?.supported_modules?.includes(withdrawConst);

  const denominationContent = [
    { children: sats, value: sats },
    { children: btc, value: btc },
  ];

  const showBalanceContent = [
    { children: "Always", value: always },
    { children: "On tap", value: onTap },
  ];

  const balanceAmountBoxProps = {
    className: `amount-section ${currencyColor}`,
    sx: {
      padding: "24px",
      margin: "0 0 10px",
      position: "relative",
    },
  };

  const amount = getBalance(selectedCurrencyType?.uuid);

  const walletBGImage = (
    <img
      src={walletBackGroundImage}
      height="100%"
      width="100%"
      alt="wallet-bg-img"
      className="card-bg-img"
    />
  );

  const showAssetAmount = () => {
    return selectedCurrencyType?.uuid === sats && currency === BTC
      ? getBtcAmount(selectedCurrencyType?.uuid)
      : getAmount(selectedCurrencyType?.uuid);
  };

  let sendReceiveClassName = currency === usdt ? "btn-usdt" : "btn-btc";

  const sendReceiveBtnJsx = () => {
    return (
      <Box className="send-receive-btn">
        {isShowReceiveButton && (
          <Button
            icon="expandCircleIcon"
            className={`receive-btn receive-${sendReceiveClassName}`}
            label={receive}
            variant="outlined"
            color="primary"
            fullWidth
            onClick={() => {
              dispatch(setPaymentProcessingRecallApis(false));
              setOpenReceiveModal(true);
            }}
            disabled={!selectedCurrencyType || targetAmountLoading}
          />
        )}
        {isShowSendButton && (
          <Button
            icon="expandCircleIcon"
            className={`send-btn send-${sendReceiveClassName}`}
            variant="contained"
            fullWidth
            label={send}
            onClick={() => {
              dispatch(setPaymentProcessingRecallApis(false));
              setOpenSendModal(true);
            }}
            disabled={!selectedCurrencyType || targetAmountLoading}
          />
        )}
      </Box>
    );
  };

  const handleOnClickAmount = () => {
    currentSession?.onTap &&
      setSelectedModeOfShowBalance(
        selectedModeOfShowBalance === always ? onTap : always
      );
  };

  const handleSettings = () => setIsSettingsOpen(true);

  const handleCloseModal = () => {
    setIsSettingsOpen(false);
  };

  const handleChangeSatsToBtc = async (value) => {
    dispatch(setShowBTCAmount(value === btc));
    const sessionToUpdate = {
      ...currentSession,
      showBtcAmount: value === btc,
    };
    currentSession && (await sessionService.saveSession(sessionToUpdate));
    setCurrentSession(sessionToUpdate);
    setCurrency(sessionToUpdate?.showBtcAmount ? btc : sats);
  };

  const handleChangeShowBalanceType = async (value) => {
    const sessionToUpdate = {
      ...currentSession,
      onTap: value === onTap,
    };
    currentSession && (await sessionService.saveSession(sessionToUpdate));
    setCurrentSession(sessionToUpdate);
  };

  const handleToggleChange = (_e, type, value) => {
    if (type === "denomination") {
      setSelectedDenomination(value);
      handleChangeSatsToBtc(value);
    } else if (type === "balance") {
      handleChangeShowBalanceType(value);
    }
  };

  const settingsModalContent = () => {
    return (
      <Box>
        <Box className="flex-between" mb="16px">
          {selectedCurrencyType?.uuid === sats && (
            <>
              <Text className="default-text" size={16} font="regular">
                {denomination}
              </Text>
              <ButtonToggle
                content={denominationContent}
                value={selectedDenomination}
                onChange={(e) =>
                  handleToggleChange(e, "denomination", e.target.value)
                }
                stackclass="modal-item"
              />
            </>
          )}
        </Box>
        <Box className="flex-between">
          <Text className="default-text" size={16} font="regular">
            {showBalanceText}
          </Text>
          <ButtonToggle
            content={showBalanceContent}
            value={currentSession?.onTap ? onTap : always}
            onChange={(e) => handleToggleChange(e, "balance", e.target.value)}
            stackclass="modal-item"
          />
        </Box>
      </Box>
    );
  };

  const showAssetAmountSectionJsx = () => {
    return amount > 0 ? (
      <Box {...balanceAmountBoxProps}>
        <Box className="flex-between" position={"relative"}>
          <Box sx={{ position: "absolute" }}>
            <img
              src={selectedCurrencyType?.icon}
              className="assets-amount-currency-icon"
              alt="currency-btc"
            />
            <Text
              className="txt-uppercase"
              withIcon="end"
              size={24}
              font="regular"
              variant="caption"
            >
              {displayTaprootCurrency(amount, currency)}
            </Text>
          </Box>
          <TuneRoundedIcon
            className="tune-round-icon"
            onClick={handleSettings}
          />
        </Box>
        <Box mt="36px" textAlign="right" sx={{ zIndex: "1" }}>
          <Box onClick={() => handleOnClickAmount()} sx={{ cursor: "pointer" }}>
            <Text
              className="grey-text txt-uppercase"
              size={16}
              font="regular"
              variant="caption"
            >
              {currentBalance}
            </Text>
            <Text
              className="default-text amount"
              sx={{ textAlign: "right" }}
              size={44}
              font="semibold"
            >
              {selectedModeOfShowBalance === "always"
                ? showAssetAmount()
                : "****"}
            </Text>
          </Box>
          <Text
            className="grey-text"
            size={24}
            variant="h6"
            font="regular"
            sx={{ textAlign: "right" }}
          >
            {selectedModeOfShowBalance === "always"
              ? exchangeRateAmount && (
                  <>
                    {fiatCurrency?.symbol}
                    {getDisplayAmount(true, exchangeRateAmount, currency)}
                  </>
                )
              : "****"}
          </Text>
        </Box>
        {walletBGImage}
        {sendReceiveBtnJsx()}
      </Box>
    ) : (
      <NewToSpeedWallet
        currency={currency}
        currencyIcon={selectedCurrencyType?.icon}
        currencyColor={currencyColor}
        walletBGImage={walletBGImage}
        setOpenReceiveModal={setOpenReceiveModal}
        sendReceiveClassName={sendReceiveClassName}
      />
    );
  };

  return (
    <>
      {isAssetsLoading ? (
        <Box {...balanceAmountBoxProps}>
          <CommonSkeleton
            variant="circle"
            sx={{ borderRadius: "20px" }}
            height={42}
            width={42}
          />
          <CommonSkeleton width={44} className={"mt-16"} />
          <Box justifyItems="right">
            <Box>
              <Text className="default-text amount" size={44} font="semibold">
                <CommonSkeleton height={44} />
              </Text>
            </Box>
            <Text
              className="grey-text"
              size={24}
              font="regular"
              variant="caption"
            >
              <CommonSkeleton width={100} />
            </Text>
          </Box>
          {walletBGImage}
          <Box className="send-receive-btn">
            <Button
              icon="expandCircleIcon"
              className="receive-btn"
              label={receive}
              variant="outlined"
              color="primary"
              fullWidth
              disabled={true}
            />
            <Button
              icon="expandCircleIcon"
              className="send-btn"
              variant="contained"
              label={send}
              fullWidth
              disabled={true}
            />
          </Box>
        </Box>
      ) : (
        showAssetAmountSectionJsx()
      )}
      <SendModal
        setOpenSendModal={setOpenSendModal}
        openSendModal={openSendModal}
        selectedCurrencyType={selectedCurrencyType}
      />
      <ReceiveModal
        setOpenReceiveModal={setOpenReceiveModal}
        openReceiveModal={openReceiveModal}
        selectedCurrencyType={selectedCurrencyType}
        accountAssets={accountAssets}
      />
      <Modal
        maxWidth="xs"
        body={settingsModalContent()}
        handleClose={handleCloseModal}
        open={isSettingsOpen}
        title={settings}
      />
    </>
  );
};

export default AssetsAmount;
