import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import {
  btc,
  insufficientBalance,
  insufficientBalanceMessage,
  usdt,
} from "../../messages";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useSelector } from "react-redux";
import {
  btcToSatsAmount,
  showAmount,
} from "@speed/common/src/components/constants";

const InsufficientBalanceErrorSection = ({
  switchCurrency,
  assetBalance,
  amount,
  isLightningMethod,
  calculatedSpeedFee,
  networkFeeAmount,
  exchangeRate,
  decodedCurrency,
}) => {
  const fiatCurrency = JSON.parse(
    localStorage.getItem("account_default_currency")
  );
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const showSubMessage = isLightningMethod && amount < assetBalance;

  const satsAmount = showBtcAmount
    ? btcToSatsAmount(assetBalance)
    : assetBalance;
  const convertedAmount = satsAmount / exchangeRate;
  const totalToKeepInWallet =
    Number(calculatedSpeedFee) + Number(networkFeeAmount);
  const amountToKeepInWallet = showBtcAmount
    ? totalToKeepInWallet?.toFixed(8)
    : Math.floor(totalToKeepInWallet);

  return (
    <Box className="send-error-section">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <ErrorOutlineIcon
            sx={{
              height: "20px",
              width: "20px",
              color: "#fd371e",
              mr: "2px",
            }}
          />
          <Text
            size={16}
            font="semibold"
            className="default-text"
            variant="h6"
            sx={{ textColor: "#FD371E" }}
          >
            {insufficientBalance}
          </Text>
        </Box>
        <Text
          size={16}
          font="semibold"
          className="default-text"
          variant="h6"
          sx={{ color: "#FD371E" }}
        >
          {switchCurrency && fiatCurrency?.symbol}
          {showAmount({
            amount: switchCurrency ? convertedAmount : assetBalance,
            currency: !switchCurrency && showBtcAmount ? btc : decodedCurrency,
            showCommaSeparated: true,
            appendCurrency: !switchCurrency && decodedCurrency,
            multiplier: decodedCurrency === usdt,
          })}
        </Text>
      </Box>
      {showSubMessage && (
        <Text
          size={14}
          font="regular"
          className="default-text"
          variant="h6"
          sx={{ color: "#FD371E", mt: "8px", width: "380px" }}
        >
          {insufficientBalanceMessage(amountToKeepInWallet, showBtcAmount)}
        </Text>
      )}
    </Box>
  );
};

export default InsufficientBalanceErrorSection;
