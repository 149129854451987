import Button from "@speed/common/src/components/Button/Button";
import {
  amountRangeLabel,
  cancel,
  checkReportLabel,
  dateOrderLabel,
  documentRequestFormatLabel,
  from,
  reconciliationFormat,
  requestInProgress,
  requestLabel,
  requestProgressMsg,
  requestSentLabel,
  requestStatementLabel,
  selectAssetLabel,
  selectStatementType,
  sourcePlaceHolder,
  statementPeriodTitle,
  to,
  transactionSourceLabel,
  transactionTypeLabel,
  usdt,
} from "../messages";
import { Box, MenuItem } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useState, useRef, useEffect } from "react";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import {
  callAPIInterface,
  dateOrderOptions,
  documentFormatOptions,
  getTimeZoneAndDateFormat,
  statementPeriodOptions,
  statementTypeOptions,
  statmentTransactionTypeOptions,
  transactionSourceOptions,
} from "../constants";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";
import RadioGroup from "@mui/material/RadioGroup";
import CommonDateFilter from "@speed/common/src/components/CommonDateFilter";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import CustomSelect from "@speed/common/src/components/Select/Select";
import InputMask from "@speed/common/src/components/MaskInput";
import { find } from "lodash";
import MultiValueInput from "@speed/common/src/components/MultiValueInput";
import {
  getTimezoneMomentObj,
  utcTimestampForDashboard,
  validateAmountRange,
} from "@speed/common/src/components/constants";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import moment from "moment";

function RequestStatement({ defaultTargetCurrency }) {
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openRequestSentModal, setOpenRequestSentModal] = useState(false);
  const [amountValidationMessage, setAmountValidationMessage] = useState();

  const { timezone: timezoneToUse } = getTimeZoneAndDateFormat();
  const timezone = timezoneToUse || "UTC";

  const { history, accountAsset } = useSelector((state) => state.common);
  const currencyList = accountAsset?.map((assetData) => ({
    label: assetData.uuid,
    value: assetData.uuid,
  }));

  const statementPeriodRef = useRef();

  const initialValues = {
    statement_type: "account",
    statement_period: "last_month",
    start_at: null,
    end_at: null,
    target_currency: defaultTargetCurrency || "SATS",
    min_amount: "",
    max_amount: "",
    transaction_type: "all",
    transaction_source: [],
    sort_order: "ascending",
    document_type: "pdf",
    is_data_reconciliation_needed: false,
  };

  const validateAmount = (formikObj) => {
    const { min_amount, max_amount } = formikObj.parent || {};
    return validateAmountRange({
      startAmount: min_amount,
      endAmount: max_amount,
      setErrorMessage: setAmountValidationMessage,
      optionalEndAmount: true,
    });
  };

  const validationSchema = yup.object({
    max_amount: yup.mixed().test({
      name: "max_amount",
      test: function () {
        return validateAmount(this);
      },
    }),
    ...statementPeriodRef?.current?.dateFilterValidationSchema,
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
  });

  const { values, setFieldValue, isValid, errors, isSubmitting, resetForm } =
    formik;

  const isForAccountStatement = values?.statement_type === "account";

  useEffect(() => setLastMonthDate(), [defaultTargetCurrency]);

  const dividerSection = () => <CustomDivider sx={{ margin: "24px 0px" }} />;

  const renderText = (text) => (
    <Text size={16} className="default-text" variant="h4">
      {text}
    </Text>
  );

  const commonRadioGroup = ({ radioOptions, keyName, onchange }) => (
    <RadioGroup sx={{ display: "flex", flexDirection: "row" }}>
      {radioOptions.map((type) => {
        return (
          <RadioButton
            key={type.id}
            label={type.label}
            value={type.value}
            name={keyName}
            sx={{ marginTop: "16px" }}
            onChange={(e) => {
              setFieldValue(keyName, e.target.value);
              onchange?.(e);
            }}
            checked={type.value === values?.[keyName]}
          />
        );
      })}
    </RadioGroup>
  );

  const commonSelection = ({ label, keyName, optionsList, onchange }) => (
    <CustomSelect
      name={label}
      className="promo-avail-on"
      displayEmpty
      showLabel
      label={label}
      value={values[keyName]}
      MenuProps={{
        id: "request-statement-asset",
      }}
      onChange={(e) => {
        setFieldValue(keyName, e.target.value);
        onchange?.();
      }}
      renderValue={(value) =>
        find(optionsList, {
          value,
        })?.label
      }
    >
      {optionsList?.map((option) => (
        <MenuItem value={option.value} key={option.value}>
          <Box>{option.label}</Box>
        </MenuItem>
      ))}
    </CustomSelect>
  );

  const statementType = () => (
    <>
      <Box className="statement-type-box">
        {renderText(selectStatementType)}
        {commonRadioGroup({
          radioOptions: statementTypeOptions,
          keyName: "statement_type",
          onchange: (e) => {
            resetForm();
            setLastMonthDate();
            setFieldValue("statement_type", e.target.value);
            setFieldValue(
              "document_type",
              e.target.value === "account" ? "pdf" : "csv"
            );
          },
        })}
      </Box>
      {dividerSection()}
    </>
  );

  const setCustomDateRangeValue = async (statDate = null, endDate = null) => {
    await setFieldValue("start_at", statDate);
    await setFieldValue("end_at", endDate);
  };

  const getTimeForGivenTypeRange = (
    valueToSubstract,
    typeToSubstrect,
    isStartOfDay = true
  ) => {
    const givenTypeTimestamp = utcTimestampForDashboard({
      timezone,
      isStartDay: true,
      days: valueToSubstract,
      typeOfSubstraction: typeToSubstrect,
      operation: "subtract",
    });

    const methodName = isStartOfDay ? "startOf" : "endOf";
    return moment(givenTypeTimestamp)
      ?.[methodName]?.(typeToSubstrect)
      ?.valueOf();
  };

  const setLastMonthDate = () => {
    const startOfMonthTime = getTimeForGivenTypeRange(1, "months");
    const endOfMonthTime = getTimeForGivenTypeRange(1, "months", false);
    setCustomDateRangeValue(startOfMonthTime, endOfMonthTime);
  };

  const setGivenDateRangeValue = (dateType) => {
    let startTime, endTime;

    switch (dateType) {
      case "last_month":
        setLastMonthDate();
        break;
      case "last_three_month":
        startTime = getTimeForGivenTypeRange(3, "months");
        endTime = getTimeForGivenTypeRange(1, "months", false);
        setCustomDateRangeValue(startTime, endTime);
        break;
      case "last_six_month":
        startTime = getTimeForGivenTypeRange(6, "months");
        endTime = getTimeForGivenTypeRange(1, "months", false);
        setCustomDateRangeValue(startTime, endTime);
        break;
      default:
        setLastMonthDate();
        break;
    }
  };

  const statementPeriod = () => (
    <>
      {renderText(statementPeriodTitle)}
      {commonRadioGroup({
        radioOptions: statementPeriodOptions,
        keyName: "statement_period",
        onchange: async (e) =>
          e.target.value === "custom_date"
            ? setCustomDateRangeValue()
            : setGivenDateRangeValue(e.target.value),
      })}
      {values.statement_period === "custom_date" && (
        <CommonDateFilter
          timezone={timezone}
          onlyLastYearSelection
          ref={statementPeriodRef}
          formik={formik}
        />
      )}
      {dividerSection()}
    </>
  );

  const assetSelection = () =>
    commonSelection({
      label: selectAssetLabel,
      keyName: "target_currency",
      optionsList: currencyList,
      onchange: () => {
        setFieldValue("min_amount", "");
        setFieldValue("max_amount", "");
      },
    });

  const showErrorMessage =
    Boolean(errors.min_amount || errors.max_amount) && amountValidationMessage;

  const amountRange = () => (
    <Box className="amount-range-box">
      {renderText(amountRangeLabel)}
      <Box className="input-wrapper">
        <Box width="48%">
          <InputMask
            value={values.min_amount}
            label={from}
            onChange={(e) => {
              setFieldValue("min_amount", e.target.value);
            }}
          />
        </Box>
        <Box width="48%">
          <InputMask
            value={values.max_amount}
            label={to}
            onChange={(e) => {
              setFieldValue("max_amount", e.target.value);
            }}
          />
        </Box>
      </Box>
      {showErrorMessage && (
        <AlertMessage
          className="margin-top14"
          severity="error"
          message={amountValidationMessage}
        />
      )}
    </Box>
  );

  const transactionTypes = () => (
    <Box mt="16px">
      {commonSelection({
        label: transactionTypeLabel,
        keyName: "transaction_type",
        optionsList: statmentTransactionTypeOptions,
      })}
    </Box>
  );

  const transactionSources = () => (
    <Box className="transaction-source-box">
      <MultiValueInput
        options={transactionSourceOptions}
        inputName="transaction_source"
        inputPlaceholder={sourcePlaceHolder}
        inputLabel={transactionSourceLabel}
        formikValue={formik}
      />
    </Box>
  );

  const extraStatementDetails = () => (
    <>
      {assetSelection()}
      {amountRange()}
      {transactionTypes()}
      {transactionSources()}
      {dividerSection()}
    </>
  );

  const statementFormatDetails = () => (
    <>
      {isForAccountStatement && (
        <Box className="display-order">
          {renderText(dateOrderLabel)}
          {commonRadioGroup({
            radioOptions: dateOrderOptions,
            keyName: "sort_order",
          })}
        </Box>
      )}
      <Box className="display-order" mt="24px">
        {renderText(documentRequestFormatLabel)}
        {commonRadioGroup({
          radioOptions: documentFormatOptions(!isForAccountStatement),
          keyName: "document_type",
        })}
      </Box>

      {isForAccountStatement && (
        <>
          {dividerSection()}
          <CustomCheckbox
            className="reconciliation-format"
            label={reconciliationFormat}
            checked={values.is_data_reconciliation_needed}
            onChange={(e) => {
              setFieldValue(
                "is_data_reconciliation_needed",
                !values.is_data_reconciliation_needed
              );
            }}
          />
        </>
      )}
    </>
  );

  const renderReqestModalBody = () => (
    <Box className="request-statement-modal-content">
      {statementType()}
      {statementPeriod()}
      {isForAccountStatement && extraStatementDetails()}
      {statementFormatDetails()}
    </Box>
  );

  const isDisableRequestBtn = () => {
    const { statement_period, start_at, end_at } = values;
    const isValidPeriod =
      statement_period === "custom_date" ? start_at && end_at : true;
    return !isValid || isSubmitting || !isValidPeriod;
  };

  const renderRequstModalFooter = () => {
    return (
      <>
        <Button
          variant="outlined"
          label={cancel}
          sx={{ mr: "16px" }}
          onClick={handleCloseRequestModal}
        />
        <Button
          loading={loading}
          variant="contained"
          label={requestLabel}
          disabled={isDisableRequestBtn()}
          onClick={handleRequestStatement}
        />
      </>
    );
  };

  const handleCloseRequestModal = () => {
    resetForm();
    setLastMonthDate();
    setOpenRequestModal(false);
  };

  const getCalculatedAmount = (amount, currency) =>
    currency === usdt ? amount * 100 : amount;

  const getStatementPayload = () => {
    const {
      statement_type,
      statement_period,
      start_at,
      end_at,
      target_currency,
      min_amount,
      max_amount,
      transaction_type,
      transaction_source,
      is_data_reconciliation_needed,
      sort_order,
      document_type,
    } = values;

    let reportFromTimestamp = start_at,
      reportToTimestamp = end_at;

    if (statement_period === "custom_date") {
      const endTimestamp = getTimezoneMomentObj(end_at, timezone);
      const currentTimeStamp = getTimezoneMomentObj(moment(), timezone);
      const isSlectedTodaysDate = endTimestamp.isSame(currentTimeStamp, "day");

      reportFromTimestamp = getTimezoneMomentObj(start_at, timezone)
        .startOf("day")
        .valueOf();

      reportToTimestamp = isSlectedTodaysDate
        ? currentTimeStamp.valueOf()
        : endTimestamp?.endOf("day")?.valueOf();
    }

    const apiPayload = {
      statement_type,
      report_filters: {
        report_from_timestamp: reportFromTimestamp,
        report_to_timestamp: reportToTimestamp,
        target_currency,
        ...(min_amount && {
          min_amount: getCalculatedAmount(min_amount, target_currency),
        }),
        ...(max_amount && {
          max_amount: getCalculatedAmount(max_amount, target_currency),
        }),
        ...(transaction_type !== "all" && { transaction_type }),
        ...(transaction_source.length && { transaction_source }),
      },
      document_type,
      sort_order,
      is_data_reconciliation_needed,
      timezone: timezoneToUse || moment.tz.guess(),
    };
    return apiPayload;
  };

  const handleRequestStatement = () => {
    setLoading(true);
    const payload = getStatementPayload();
    callAPIInterface("POST", `/report/generate`, payload)
      .then((res) => {
        if (res) {
          handleCloseRequestModal();
          setOpenRequestSentModal(true);
        }
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const renderReqestSentModalBody = () => (
    <Box className="request-sent-modal-content">
      <Box className="content-center" flexDirection="column">
        <CheckCircleIcon className="tick-icon" />
        <Text
          size={20}
          className="default-text margin-top15"
          variant="subtitle1"
          font="semibold"
          align="center"
        >
          {requestInProgress}
        </Text>
        <Text
          size={18}
          className="grey-text margin-top15"
          variant="subtitle1"
          font="regular"
          align="center"
        >
          {requestProgressMsg}
        </Text>
      </Box>
    </Box>
  );

  const renderRequstSentModalFooter = () => (
    <Button
      fullWidth
      variant="outlined"
      label={checkReportLabel}
      onClick={() => history.push("/settings/reports")}
    />
  );

  const handleCloseRequestSentModal = () => {
    setOpenRequestSentModal(false);
  };

  return (
    <Box className="request-statement-wrapper">
      <Box className="action-btn-wrapper mui-fixed">
        <Button
          icon="statementIcon"
          label={requestStatementLabel}
          variant="outlined"
          color="primary"
          onClick={() => setOpenRequestModal(true)}
        />
      </Box>
      {openRequestModal && (
        <Modal
          className="request-statement-modal"
          maxWidth="sm"
          body={renderReqestModalBody()}
          footer={renderRequstModalFooter()}
          open={openRequestModal}
          title={requestStatementLabel}
          handleClose={handleCloseRequestModal}
        />
      )}
      <Modal
        className="request-sent-modal"
        maxWidth="sm"
        body={renderReqestSentModalBody()}
        footer={renderRequstSentModalFooter()}
        open={openRequestSentModal}
        title={requestSentLabel}
        handleClose={handleCloseRequestSentModal}
      />
    </Box>
  );
}

export default RequestStatement;
