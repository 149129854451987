import { Box } from "@mui/material";
import moment from "moment-timezone";
import { forwardRef, useImperativeHandle, useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import * as yup from "yup";
import CustomDatePicker from "@speed/common/src/components/DatePicker";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import {
  endDate,
  enterValidDateMsg,
  enterValidFutureDateMsg,
  enterValidMinRequiredDateMsg,
  greaterEndDateMsg,
  startDate,
} from "../messages";
import { utcTimestampForDashboard } from "./constants";

const CommonDateFilter = forwardRef(
  ({ onlyLastYearSelection = false, timezone, formik, dateLabel }, ref) => {
    let minDateYear, minReqPickerYear;
    const dateTimezone = timezone || moment.tz.guess();
    if (onlyLastYearSelection) {
      const oneYearDateTime = utcTimestampForDashboard({
        timezone: dateTimezone,
        isStartDay: true,
        days: 1,
        typeOfSubstraction: "year",
        operation: "subtract",
      });
      minReqPickerYear = moment(oneYearDateTime).year();
      minDateYear = moment(oneYearDateTime).add(1, "day");
    } else {
      minReqPickerYear = 2021;
      minDateYear = moment(`${minReqPickerYear}-01-01T00:00:00.000`);
    }

    const { values, setFieldValue, setTouched, errors, touched } = formik;
    const [
      startDateFilterValidationMessage,
      setStartDateFilterValidationMessage,
    ] = useState();
    const [endDateFilterValidationMessage, setEndDateFilterValidationMessage] =
      useState();
    const [disableFilterStartDate, setDisableFilterStartDate] = useState();
    const [disableFilterEndDate, setDisableFilterEndDate] = useState();

    const filterValidationSchema = {
      start_at: yup.mixed().test({
        name: "start_at",
        test: function () {
          return validateFilterStartDate(this);
        },
      }),
      end_at: yup.mixed().test({
        name: "end_at",
        test: function () {
          return validateFilterEndDate(this);
        },
      }),
    };

    const isValidFilterDate = (momentObj) => moment(momentObj).isValid();
    const isFutureDate = (momentObj) =>
      moment(momentObj).diff(moment().endOf("day")) > 0;

    const validateMinRequiredYear = (
      momentObj,
      setValidationMessage,
      isForEndDate = false
    ) => {
      const isInvalidYear = moment(momentObj).diff(minDateYear) < 0;
      if (isInvalidYear) {
        setValidationMessage(
          enterValidMinRequiredDateMsg(minReqPickerYear, isForEndDate)
        );
        return false;
      }
      return true;
    };

    const validateFilterStartDateMoment = (startDateMomentObj) => {
      const isInvalid = !isValidFilterDate(startDateMomentObj);
      if (isInvalid) {
        setStartDateFilterValidationMessage(enterValidDateMsg());
        return;
      }

      const isValidMinYear = validateMinRequiredYear(
        startDateMomentObj,
        setStartDateFilterValidationMessage
      );

      if (!isValidMinYear) {
        return;
      }

      const isFuture = isFutureDate(startDateMomentObj);
      if (isFuture) {
        setStartDateFilterValidationMessage(enterValidFutureDateMsg());
        return;
      }

      return true;
    };

    const validateDateWhenOtherAvailable = ({
      momentObj,
      setDateValidationMessage,
      isForEndDateValidation = false,
    }) => {
      if (momentObj) {
        setDateValidationMessage(enterValidDateMsg(isForEndDateValidation));
        return;
      }
      return true;
    };

    const validateFilterStartDate = (formikObj) => {
      const startDateMomentObj = formikObj?.parent?.start_at;
      const endDateMomentObj = formikObj?.parent?.end_at;
      if (startDateMomentObj) {
        return validateFilterStartDateMoment(startDateMomentObj);
      } else {
        return validateDateWhenOtherAvailable({
          momentObj: endDateMomentObj,
          setDateValidationMessage: setStartDateFilterValidationMessage,
        });
      }
    };

    const validateFilterEndDateMoment = (
      endDateMomentObj,
      startDateMomentObj
    ) => {
      const isInvalid = !isValidFilterDate(endDateMomentObj);
      if (isInvalid) {
        setEndDateFilterValidationMessage(enterValidDateMsg(true));
        return;
      }

      const isValidMinYear = validateMinRequiredYear(
        endDateMomentObj,
        setEndDateFilterValidationMessage,
        true
      );
      if (!isValidMinYear) {
        return;
      }

      const isFuture = isFutureDate(endDateMomentObj);
      if (isFuture) {
        setEndDateFilterValidationMessage(enterValidFutureDateMsg(true));
        return;
      }

      const dateDifference = moment(endDateMomentObj).diff(
        moment(startDateMomentObj)
      );
      if (dateDifference < 0) {
        setEndDateFilterValidationMessage(greaterEndDateMsg);
        return;
      }

      return true;
    };

    const validateFilterEndDate = (formikObj) => {
      const startDateMomentObj = formikObj?.parent?.start_at;
      const endDateMomentObj = formikObj?.parent?.end_at;
      if (endDateMomentObj) {
        return validateFilterEndDateMoment(
          endDateMomentObj,
          startDateMomentObj
        );
      } else {
        return validateDateWhenOtherAvailable({
          momentObj: startDateMomentObj,
          setDateValidationMessage: setEndDateFilterValidationMessage,
          isForEndDateValidation: true,
        });
      }
    };

    const handleFilterDateChange = (momentDateObj, setDisableDate) => {
      if (isValidFilterDate(momentDateObj)) {
        setDisableDate(moment(momentDateObj));
      } else {
        setDisableDate();
      }
    };

    const filterDatePickerElement = (
      labelText,
      dateValueType,
      setDisableDate
    ) => {
      const customProps = {
        disableFuture: true,
        minDate: minDateYear,
        value: values[dateValueType],
        setValue: (value) => {
          setFieldValue(dateValueType, value);
        },
        handleDateChange: (momentObj) =>
          handleFilterDateChange(momentObj, setDisableDate),
        onBlur: () => setTouched({ ...touched, [dateValueType]: true }),
      };

      if (dateTimezone) {
        customProps.timezone = dateTimezone;
      }

      if (dateValueType === "start_at") {
        customProps.maxDate = disableFilterEndDate;
      }

      if (dateValueType === "end_at") {
        customProps.minDate = disableFilterStartDate || minDateYear;
      }

      return (
        <Box
          className="account-date-picker margin-top14"
          {...(dateValueType === "end_at" && { sx: { marginLeft: "14px" } })}
        >
          <Text
            className="grey-text input-with-dropdown-label"
            font="regular"
            size={14}
            sx={{ flex: 1 }}
            variant="h6"
          >
            {labelText}
          </Text>
          <CustomDatePicker {...customProps} />
        </Box>
      );
    };

    useImperativeHandle(ref, () => ({
      dateFilterValidationSchema: filterValidationSchema,
      setDisableStartDate: setDisableFilterStartDate,
      setDisableEndDate: setDisableFilterEndDate,
    }));

    return (
      <Box className="filter-date margin-top30">
        <Text className="default-text" size={16}>
          {dateLabel}
        </Text>
        <Box className="picker-wrapper">
          {filterDatePickerElement(
            startDate,
            "start_at",
            setDisableFilterStartDate
          )}
          {filterDatePickerElement(endDate, "end_at", setDisableFilterEndDate)}
        </Box>
        {touched?.start_at &&
          Boolean(errors?.start_at) &&
          startDateFilterValidationMessage && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={startDateFilterValidationMessage}
            />
          )}
        {touched?.end_at &&
          Boolean(errors?.end_at) &&
          endDateFilterValidationMessage && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={endDateFilterValidationMessage}
            />
          )}
      </Box>
    );
  }
);

export default CommonDateFilter;
