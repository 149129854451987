import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import * as yup from "yup";
import {
  dailySendLimit,
  minUsdtSendLimit,
  successSendLimit,
} from "../../messages";
import { showToast } from "../../../redux/common/actions";
import {
  callAPIInterface,
  instantSendLimits,
  instantSendValidation,
} from "../../constants";
import { instantSendTypes } from "@speed/common/src/components/constants";
import CommonBodySection from "./CommonBodySection";

const USDTManageLimits = ({
  allTypeLimitData,
  limitsData,
  setLimitsData,
  loader,
  selectedAsset,
}) => {
  const initialUSDTCurrency = { name: "USDT", code: "USDT", symbol: "₮" };
  const [disableBtn, setDisableBtn] = useState(true);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const usdtTransactionValidation = (amountValue, parent) => {
    const usdtLimit = Number(parent.usdtLimit);
    return amountValue && usdtLimit && amountValue < usdtLimit
      ? { isValid: false, errorMessage: dailySendLimit }
      : { isValid: true };
  };

  const limitValidation = (value, parent, type) => {
    const amountValue = Number(value);
    if (amountValue < 1) {
      return {
        isValid: false,
        errorMessage: minUsdtSendLimit,
      };
    } else if (type === "usdtTransactionCurrency")
      return usdtTransactionValidation(amountValue, parent);
    else return { isValid: true };
  };

  const validationSchema = yup.object({
    ...instantSendValidation({
      type: "usdtLimit",
      currency: "usdtCurrency",
      limitValidation,
    }),
    ...instantSendValidation({
      type: "usdtTransactionLimit",
      currency: "usdtTransactionCurrency",
      limitValidation,
    }),
  });

  const getInitialData = (type) =>
    limitsData?.length > 0 && limitsData.find((item) => item.type === type);

  const formik = useFormik({
    initialValues: {
      usdtCurrency: initialUSDTCurrency,
      usdtTransactionCurrency: initialUSDTCurrency,
      usdtLimit:
        getInitialData(instantSendTypes.usdt)?.amount?.toString() || "",
      usdtTransactionLimit:
        getInitialData(instantSendTypes.daily)?.amount?.toString() || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { values, isValid, dirty, isSubmitting, resetForm } = formik;

  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      USDT: [],
    };
    const { usdt, daily } = instantSendTypes;

    const generateObj = (type, curr, limitType) => {
      const id =
        limitsData?.length > 0 &&
        limitsData.find((item) => item.type === limitType)?.id;
      const createdObj = {
        id: id || "",
        amount: +values[type] || null,
        currency: values[curr]?.code,
        type: limitType,
      };
      !(!id && !values[type]) && payload.USDT.push(createdObj);
    };
    generateObj("usdtLimit", "usdtCurrency", usdt);
    generateObj("usdtTransactionLimit", "usdtTransactionCurrency", daily);
    callAPIInterface("PUT", "/send/limits", payload)
      .then((res) => {
        setLoading(false);
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: successSendLimit,
            toastVariant: "success",
          })
        );
        !isEmpty(res) && setLimitsData({ ...allTypeLimitData, USDT: res.USDT });
        resetForm();
      })
      .catch((_e) => setLoading(false));
  };

  const reformedLimitData = useMemo(() => {
    return (
      limitsData?.length > 0 &&
      limitsData.filter((item) => item.amount !== null)
    );
  }, [limitsData]);

  useEffect(() => {
    const { usdtLimit, usdtTransactionLimit } = values;
    const whenAmountAdded =
      reformedLimitData.length === 0
        ? Boolean(usdtTransactionLimit || usdtLimit)
        : true;
    const disableBtn = !whenAmountAdded || !(isValid && dirty) || isSubmitting;
    setDisableBtn(disableBtn);
  }, [isValid, dirty, isSubmitting, values]);

  const bodyProps = {
    loader,
    sendLimitData: instantSendLimits["USDT"],
    selectedAsset,
    formik,
    loading,
    handleSubmit,
    disableBtn,
  };

  return <CommonBodySection {...bodyProps} />;
};

export default USDTManageLimits;
