import React, { useEffect, useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { Grid, Box } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import { OTPInput } from "@speed/common/src/components/OTPInput/OTPInput";
import { callAPIInterface, redirectToLogin } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { loadingMsg } from "@speed/common/src/components/messages";
import { showToast } from "../../redux/common/actions";
import {
  setAfterLoginProcess,
  setLocalVariables,
  setUpdatedSessionData,
} from "../../redux/auth/actions";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import {
  DidNotGetCodeLink,
  MFAOTPTimer,
  TwoFASubText,
  getCurrentTimeInSecond,
} from "@speed/common/src/components/constants";
import MainLogoLogin from "../Common/MainLogoLogin";
import NeedHelp from "./NeedHelp";
import {
  authenticate,
  goBack,
  mfaResendCodeMsg,
  twoFAMsg,
} from "@speed/common/src/messages";
const TwoFactorAuthentication = () => {
  const [otp, setOtp] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isFromAuth, setIsFromAuth] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginMfaAuthDetails, setLoginMfaAuthDetails] = useState([]);
  const [loginEmail, setLoginEmail] = useState();
  const [expiresAt, setExpiresAt] = useState();
  const [isExpiredOTP, setIsExpiredOTP] = useState(false);
  const [timerCount, setTimerCount] = useState();
  const dispatch = useDispatch();
  const history = useSelector((state) => state.common.history);
  const { mfaResendotp, mfaResendotploginFe } = useFlags();
  const ldClient = useLDClient();
  const isFromLoginPage = history?.location?.state?.from === "/login";
  const isFromSocialMediaLogin =
    history?.location?.state?.isFromSocialMediaLogin;
  const isFromVerifyEmailPage = history?.location?.state?.isFromVerifyEmailPage;
  const isFromSetupPage =
    history?.location?.state?.from === "/two-factor-setup";
  const mfaResendcodeLdFlag = isFromSetupPage
    ? mfaResendotploginFe
    : mfaResendotp;
  const setTimerStates = (timerExpireAt) => {
    const currentSeconds = getCurrentTimeInSecond(timerExpireAt);
    setTimerCount(currentSeconds);
    currentSeconds === 0 && setIsExpiredOTP(true);
  };
  useEffect(() => {
    if (!(isFromLoginPage || isFromSetupPage)) {
      redirectToLogin();
      return;
    }
    const ldUser = {
      key: "anonymous-anonymous",
    };
    ldClient.identify(ldUser);
  }, []);
  useEffect(() => {
    history && setIsFromAuth(history.location.pathname === "/two-factor");
  }, [history]);
  useEffect(() => {
    if (expiresAt && timerCount > 0) {
      const timer = setInterval(() => {
        setTimerStates(expiresAt);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [expiresAt, timerCount]);
  useEffect(async () => {
    setLoading(true);
    await sessionService
      .loadSession()
      .then((session) => {
        if (session?.mfa_configurations && session?.otp_expires_at) {
          !isFromSetupPage && setLoginEmail(session?.email);
          setLoginMfaAuthDetails(session?.mfa_configurations);
          setExpiresAt(session?.otp_expires_at);
          setTimerStates(session?.otp_expires_at);
          setLoading(false);
        } else {
          redirectToLogin();
        }
      })
      .catch(() => {
        redirectToLogin();
      });
  }, []);
  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
    newOtp.length >= 6 ? setIsDisabled(false) : setIsDisabled(true);
  };
  const smsConfigDetails =
    loginMfaAuthDetails?.length &&
    loginMfaAuthDetails?.filter((item) => item.mfa_type === "SMS")[0];
  const handleSubmitVerifyOTP = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    await sessionService
      .loadSession()
      .then(async (data) => {
        const OTPData = {
          session: data.session,
          otp,
        };
        const mfaAPIEndpoint = isFromSetupPage
          ? "/login/mfa/phone-number/verify"
          : "/login/mfa/phone-number/verify-otp";
        callAPIInterface("POST", mfaAPIEndpoint, OTPData)
          .then(async (response) => {
            await setUpdatedSessionData(response);
            if (isFromVerifyEmailPage) {
              await setLocalVariables(response, dispatch);
              await sessionService.deleteUser();
            } else {
              await setAfterLoginProcess(
                response,
                dispatch,
                isFromSocialMediaLogin,
                false
              );
            }
            setButtonLoading(false);
          })
          .catch(() => {
            setButtonLoading(false);
          });
      })
      .catch(() => {
        setButtonLoading(false);
      });
  };
  const handleResendCode = async () => {
    setButtonLoading(true);
    await sessionService
      .loadSession()
      .then(async (data) => {
        const mfaAPIEndpoint = isFromSetupPage
          ? "/login/mfa/phone-number/resend-verification-code"
          : "/login/mfa/phone-number/resend-otp";
        await callAPIInterface("POST", mfaAPIEndpoint, {
          session: data.session,
        })
          .then((response) => {
            if (response) {
              sessionService.saveSession({
                ...data,
                otp_expires_at: response.otp_expires_at,
              });
              setExpiresAt(response.otp_expires_at);
              setTimerCount(getCurrentTimeInSecond(response?.otp_expires_at));
            }
            dispatch(
              showToast({
                isToastOpen: true,
                toastMessage: mfaResendCodeMsg(!isFromSetupPage),
                toastVariant: "success",
              })
            );
            setIsExpiredOTP(false);
            setOtp("");
            setIsDisabled(true);
          })
          .catch(() => {});
      })
      .catch(() => {});
    setButtonLoading(false);
  };
  return (
    <>
      <MainLogoLogin />
      <Box className="container-layout">
        {loginMfaAuthDetails?.length ? (
          <Grid
            container
            className="common-grid-wrapper signin-signup-container-layout"
            sx={{ padding: "0 !important" }}
          >
            <Grid
              display="flex"
              sx={{ padding: "40px" }}
              className="common-mobile-grid"
            >
              <Grid item component="form" onSubmit={handleSubmitVerifyOTP}>
                <Text size={28} font="bold">
                  {twoFAMsg}
                </Text>
                <TwoFASubText
                  isFromAuth={isFromAuth}
                  usingPhone
                  phoneNumber={{
                    code: `+${smsConfigDetails?.mfa_calling_code}`,
                    number: smsConfigDetails?.mfa_phone_number,
                  }}
                  loginEmail={loginEmail}
                />
                <OTPInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  containerStyle={{ justifyContent: "space-between" }}
                />
                <Button
                  loading={buttonLoading}
                  type="submit"
                  className="submit-signin-btn margin-top30"
                  iconPosition="end"
                  icon="rightArrowIcon"
                  fullWidth
                  label={authenticate}
                  variant="contained"
                  disabled={isDisabled}
                />
                {isFromSetupPage && (
                  <Button
                    fullWidth
                    className="margin-top25 goback-btn"
                    label={goBack}
                    type="button"
                    variant="default"
                    onClick={() => history.go(-1)}
                  />
                )}
                {timerCount > 0 && <MFAOTPTimer timerCount={timerCount} />}
                {mfaResendcodeLdFlag && isExpiredOTP && (
                  <DidNotGetCodeLink
                    isFromAuth={isFromAuth}
                    textProps={{
                      className: "expired-2fa-otp",
                    }}
                    handleResendCode={handleResendCode}
                  />
                )}
              </Grid>
            </Grid>
            <NeedHelp />
          </Grid>
        ) : (
          <BackdropLoader
            open={loading}
            alt="loading..."
            text={loadingMsg}
            customClass="loading-in-auth"
          />
        )}
      </Box>
    </>
  );
};
export default TwoFactorAuthentication;
