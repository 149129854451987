import { Box, Skeleton } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  lightning,
  maxPaymentMsg,
  maxPaymentMsgUsdt,
  sats,
  writeYourNoteHere,
} from "../../messages";
import {
  displayTaprootCurrency,
  handleAmountChangeCommon,
} from "../../constants";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { useRef, useState } from "react";
import {
  allowedTwoAfterDecimalRegex,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import { useSelector } from "react-redux";
import { currencyExchange } from "@speed/common/src/components/images";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const AmountBox = ({
  switchCurrency,
  setFiatAmount,
  setAmount,
  exchangeRate,
  fiatAmount,
  amount,
  displayAmount,
  currency,
  note,
  setIsLargeAmount,
  isLargeAmount,
  setSwitchCurrency,
  exchangeRateLoading,
  setNote,
  isAmountEditable = true,
  isNoteClicked,
  isAmountClicked,
  selectedMethodName,
  fromSend = false,
}) => {
  const walletDefaultCurrency = JSON.parse(
    localStorage.getItem("account_default_currency")
  );
  const targetedCurrency = walletDefaultCurrency?.code;
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const inputRef = useRef();
  const noteRef = useRef();
  const isEditState = fromSend ? isAmountEditable : isAmountClicked;

  const [isEdit, setIsEdit] = useState(isEditState);
  const [isAddNote, setIsAddNote] = useState(isNoteClicked);
  const [isBackSpace, setIsBackSpace] = useState(false);

  const handleLargeAmount = (amount) => {
    const isLargeAmountCheck = showBtcAmount
      ? amount > 5
      : satsToBtcAmount(amount) > 5;
    if (!switchCurrency) {
      currency === sats
        ? setIsLargeAmount(isLargeAmountCheck)
        : setIsLargeAmount(amount > 100000);
    } else {
      const convertedAmount = amount * exchangeRate;
      const finalAmount = showBtcAmount
        ? satsToBtcAmount(convertedAmount)
        : convertedAmount;
      const isLargeAmountCheck = showBtcAmount
        ? finalAmount > 5
        : satsToBtcAmount(finalAmount) > 5;

      currency === sats
        ? setIsLargeAmount(isLargeAmountCheck)
        : setIsLargeAmount(finalAmount > 100000);
    }
  };

  const handleAmountChange = (e) => {
    const mainAmount = e.target.value;
    const currentAmount = showBtcAmount ? mainAmount?.slice(0, 10) : mainAmount;

    handleAmountChangeCommon(
      mainAmount,
      switchCurrency,
      exchangeRate,
      currency,
      setAmount,
      setFiatAmount,
      showBtcAmount
    );
    handleLargeAmount(currentAmount);
  };

  const handleKeyDown = (evt) => {
    let excludedKey = ["e", "E", "+", "-"];
    setIsBackSpace(evt.keyCode === 8); // Allow backspace
    if (showBtcAmount && (!amount || amount - 1 <= 0)) {
      excludedKey.push("ArrowDown");
    }

    if (!switchCurrency && currency === sats && !showBtcAmount)
      excludedKey.push(".");

    if (excludedKey.includes(evt.key)) evt.preventDefault();
  };

  const handleOnFocus = (e) => {
    setIsEdit(true);
    if (inputRef?.current) {
      e.target.type = "text";
      inputRef.current.selectionStart = inputRef.current.value.length;
      e.target.type = "number";
    }
  };

  const handleOnChange = (e) => {
    const val = e.target.value;
    if (
      val &&
      !allowedTwoAfterDecimalRegex.test(val) &&
      !isBackSpace &&
      (switchCurrency || currency !== sats)
    ) {
      e.preventDefault();
    } else {
      handleAmountChange(e);
    }
  };

  const inputProps = {
    style: {
      fontSize: 44,
      border: isEdit ? "1px solid #F6F3F3" : "none",
      height: "35px",
      padding: "10px 12px",
    },
  };

  const handleNoteChanged = (e) => {
    setNote(e.target.value);
  };

  const handleChangeCurrency = () => {
    !exchangeRateLoading && setSwitchCurrency(!switchCurrency);
  };

  const handleNoteClick = () => {
    setIsAddNote(true);
  };

  return (
    <Box className="enter-amount-box">
      <Box className="amount-box" padding="20px">
        <Text className="default-text" size={16} font="regular">
          {switchCurrency
            ? targetedCurrency
            : displayTaprootCurrency(amount, currency, showBtcAmount)}
        </Text>
        {isEdit && isAmountEditable ? (
          <Input
            ref={inputRef}
            fullWidth
            placeholder="0"
            customClass="amount-input"
            autoFocus={isEdit && true}
            name="amount"
            showLabel={false}
            value={switchCurrency ? fiatAmount : amount}
            type="number"
            onWheel={(e) => e.target.blur()}
            onKeyDown={handleKeyDown}
            onFocus={handleOnFocus}
            onBlur={() => setIsEdit(false)}
            onChange={handleOnChange}
            inputProps={inputProps}
          />
        ) : (
          <Text
            size={44}
            onClick={() => setIsEdit(true)}
            font="medium"
            className="default-text"
            style={{ cursor: `${isAmountEditable ? "pointer" : "default"}` }}
          >
            {displayAmount(switchCurrency)}{" "}
          </Text>
        )}
        {isLargeAmount && (
          <Text
            className="error-text"
            size={14}
            font="regular"
            sx={{ marginTop: "5px", textAlign: "left", width: "100%" }}
          >
            {currency === sats ? maxPaymentMsg : maxPaymentMsgUsdt}
          </Text>
        )}
        <img
          src={currencyExchange}
          alt="currency-exchange-icon"
          className="currency-exchange-icon"
          style={{ cursor: "pointer", marginTop: isEdit && "14px" }}
          onClick={handleChangeCurrency}
        />
        {exchangeRateLoading ? (
          <Skeleton sx={{ pt: "30px", mt: "14px" }} width={150} height={20} />
        ) : (
          <Text
            size={18}
            font="regular"
            className="grey-text"
            variant="h6"
            sx={{ mt: "10px" }}
          >
            {!switchCurrency && walletDefaultCurrency?.symbol}
            {displayAmount(!switchCurrency)}{" "}
            {switchCurrency &&
              displayTaprootCurrency(amount, currency, showBtcAmount)}
          </Text>
        )}
      </Box>
      {fromSend && (
        <>
          {!isAddNote ? (
            <Box className="send-note-section">
              <TruncatedTextTooltip
                textValue={note || writeYourNoteHere}
                className="custom-tooltip"
                cellWidth="200px"
                textProps={{
                  size: 16,
                  className: "grey-text send-add-note-btn",
                  font: "regular",
                  variant: "h6",
                  onClick: handleNoteClick,
                }}
              />
            </Box>
          ) : (
            <Box className="content-center" mb="14px">
              <Input
                ref={noteRef}
                autoFocus={true}
                showLabel={false}
                name="note"
                value={note}
                type="text"
                onWheel={(e) => e.target.blur()}
                onBlur={() => setIsAddNote(false)}
                onChange={handleNoteChanged}
                inputProps={{
                  maxLength: 100,
                  style: {
                    fontSize: 16,
                    padding: "10px 12px",
                  },
                }}
              />
            </Box>
          )}
          <CustomDivider sx={{ width: "38px" }} />
        </>
      )}
      {selectedMethodName === lightning && (
        <>
          <CustomDivider sx={{ width: "520px" }} />
          <Box className="add-notes-options">
            {!isAddNote ? (
              <Text
                size={16}
                className="grey-text add-note-btn"
                font="regular"
                variant="h6"
                onClick={() => setIsAddNote(true)}
              >
                {note || writeYourNoteHere}
              </Text>
            ) : (
              <Input
                ref={noteRef}
                autoFocus={true}
                showLabel={false}
                name="note"
                value={note}
                type="text"
                onWheel={(e) => e.target.blur()}
                onBlur={() => setIsAddNote(false)}
                onChange={handleNoteChanged}
                inputProps={{
                  maxLength: 100,
                  style: {
                    fontSize: 16,
                    padding: "10px 12px",
                  },
                }}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default AmountBox;
