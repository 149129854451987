import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import { Box, MenuItem, Skeleton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Text from "@speed/common/src/components/Text/Text";
import {
  addNote,
  addNotePlaceholder,
  addressDesc,
  balanceExceeded,
  balancePreText,
  btc,
  ethereumLabel,
  keepSatsMsg,
  lightning,
  lnurl,
  maxAllowed,
  minAllowed,
  onchain,
  payViaAmountMsg,
  payViaInfo,
  payViaLabel,
  recipientLabel,
  sats,
  selectCurrencyLabel,
  sendMax,
  sendPaymentLabel,
  tronLabel,
} from "../messages";
import { Input } from "@speed/common/src/components/Input/Input";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import {
  availableBalanceTextComponent,
  callAPIInterface,
  getPayoutBalanceAPI,
  idealTimeLimitReached,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../constants";
import {
  btcToSatsAmount,
  commaSeperatedCurrencyAmount,
  excludedCode,
  satsToBtcAmount,
  satsToFiatAmount,
  showAmount,
} from "@speed/common/src/components/constants";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import Label from "@speed/common/src/components/Label/Label";
import {
  setExecuteAPICall,
  setInstantSendButtonClicked,
  setLoading,
  showToast,
} from "../../redux/common/actions";
import FeesExceedModal from "../Common/FeesExceedModal";
import InsufficientBalanceModal from "../Common/InsufficientBalanceModal";
import history from "@speed/common/src/components/history";
import CommonEstimateFeesBox from "../Common/CommonEstimateFeesBox";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { currency } from "@speed/common/src/components/currency";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import AmountWithCurrencyInput from "../Common/AmountWithCurrencyInput";

const InstantSendForm = ({
  formik,
  payViaOptions,
  disablePayViaOption,
  disableCurrency,
  amountLimits,
  decodeResponse,
  satsAmount,
  setSatsAmount,
  disableSend,
  setDisableSend,
  setSendBtnLoader,
  handleModalClose,
  handleBack,
}) => {
  const dispatch = useDispatch();
  const [
    lightningPayViaCurrencyExchangeRate,
    setLightningPayViaCurrencyExchangeRate,
  ] = useState();
  const [initialBalanceData, setInitialBalanceData] = useState();
  const [exchangeRateValue, setExchangeRateValue] = useState();
  const [exchangeRateLoader, setExchangeRateLoader] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [speedFees, setSpeedFees] = useState(0);
  const [estimatedFeeLoader, setEstimatedFeeLoader] = useState(false);
  const [estimatedFees, setEstimatedFees] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [sendMaxLoader, setSendMaxLoader] = useState(false);
  const [balance, setBalance] = useState();
  const [balanceLoader, setBalanceLoader] = useState(true);
  const [openFeesModal, setOpenFeesModal] = useState(false);
  const [openInsufficientBalanceModal, setOpenInsufficientBalanceModal] =
    useState(false);
  const [newEstimatedFees, setNewEstimatedFees] = useState();
  const [session, setSession] = useState(null);
  const [feesBtnLoader, setFeesBtnLoader] = useState(false);
  const [speedFeeLoader, setSpeedFeeLoader] = useState(false);
  const [payViaAnchorEl, setPayViaAnchorEl] = useState(null);

  const abortController = new AbortController();

  const { values, setFieldValue } = formik;
  const targetCurrency = values.pay_via.uuid;
  const isSATSPayVia = targetCurrency === sats;
  const isSATSCurrency = values.currency?.code === sats;
  const usdtAllowedMethods = [ethereumLabel, tronLabel];
  const excludeFeeCheckMethods = [lightning, lnurl];
  const {
    withdraw_method,
    max_sendable,
    min_sendable,
    decodeAmt,
    withdraw_request,
  } = {
    ...decodeResponse,
  };
  const shouldPassFeesInCents = usdtAllowedMethods.includes(withdraw_method);

  const user = useSelector((state) => state.auth.user);
  const { instantSendButtonClicked, executeAPICall } = useSelector(
    (state) => state.common
  );

  //GET Balance data
  const getBalance = async () => {
    setBalanceLoader(true);
    try {
      const { balance: result, balanceData } = await getPayoutBalanceAPI(
        targetCurrency
      );
      setBalance(result);
      setInitialBalanceData(balanceData);
      setBalanceLoader(false);
      return result;
    } catch (e) {
      setBalanceLoader(false);
    }
  };

  const getBalanceFromPayViaCurrency = (currency) => {
    const amountBalance =
      initialBalanceData?.find((value) => value?.target_currency === currency)
        ?.amount || 0;

    return Number(
      showAmount({
        amount: amountBalance,
        currency,
        targetedCurrency: currency === sats ? btc : currency,
      })
    );
  };

  const getCalculatedBalance = async (fromInitialCall) => {
    let balanceInPayViaCurrency;
    if (!fromInitialCall || !initialBalanceData) {
      balanceInPayViaCurrency = await getBalance();
    } else {
      balanceInPayViaCurrency = getBalanceFromPayViaCurrency(targetCurrency);
      setBalance(balanceInPayViaCurrency);
      setBalanceLoader(false);
    }
    const balanceInTargetCurrency = isSATSPayVia
      ? btcToSatsAmount(balanceInPayViaCurrency)
      : balanceInPayViaCurrency;
    return { balanceInTargetCurrency };
  };

  //Get Max. lightning amount in SATS
  const getLightningMaxAmount = async () => {
    setAmountError("");
    setSendMaxLoader(true);
    try {
      const res = await callAPIInterface(
        "GET",
        "/withdraws/lightning-max-amount"
      );
      setMaxAmountToInput(res?.amount);
    } catch (e) {}
    setSendMaxLoader(false);
  };

  //Get exchange rates
  const getExchangeRate = (currency = sats, isForLightningPayVia = false) => {
    setDisableSend(true);
    setExchangeRateLoader(true);
    const data = {
      currency: currency,
      target_currency: targetCurrency,
    };
    return callAPIInterface("POST", "/utility/exchange-rate", data)
      .then((res) => {
        const setExchangeRateState = isForLightningPayVia
          ? setLightningPayViaCurrencyExchangeRate
          : setExchangeRateValue;
        setExchangeRateLoader(false);
        const exchangeRate = res?.target_lowest_rate;
        setExchangeRateState(exchangeRate);
        return exchangeRate;
      })
      .catch((_e) => setExchangeRateLoader(false));
  };

  //Get SpeedFee Data
  const getMethod = () => {
    switch (withdraw_method) {
      case lightning:
      case lnurl:
        return lightning;
      case onchain:
        return onchain;
      case ethereumLabel:
        return ethereumLabel;
      case tronLabel:
        return tronLabel;
    }
  };

  const getSpeedFee = async () => {
    setSpeedFeeLoader(true);
    try {
      const params = `?payment_method=${getMethod()}&transaction_code=04&target_currency=${targetCurrency}`;
      const response = await callAPIInterface("GET", `/account-fees${params}`);
      setSpeedFees(response?.fee_percentage);
      setSpeedFeeLoader(false);
      return response?.fee_percentage;
    } catch (_err) {
      setSpeedFeeLoader(false);
    }
  };

  //Get Estimated Fee Data
  const getEstimatedFees = (feesAmount, shouldStateSet = true) => {
    setDisableSend(true);
    setEstimatedFeeLoader(true);
    const params = {
      address_with_amount: {
        [withdraw_request]: feesAmount,
      },
      target_confirmation: 3,
    };
    const { signal } = abortController;
    return callAPIInterface(
      "POST",
      "/withdraws/estimate",
      params,
      null,
      null,
      signal
    )
      .then((res) => {
        setEstimatedFeeLoader(false);
        setDisableSend(false);
        if (shouldStateSet) {
          setEstimatedFees(res?.fee); //Fees in SATS
        }
        return res;
      })
      .catch((e) => {
        const errorMsg = e.response?.data?.errors?.[0]?.message;
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: errorMsg,
            toastVariant: "error",
          })
        );
        setEstimatedFeeLoader(false);
      });
  };

  //Common Logic

  const amountToPayViaCurrencyAmount = (amount, exRate) => {
    const payViaCurrencyAmount = showAmount({
      amount,
      currency: targetCurrency,
      targetedCurrency: targetCurrency === sats ? btc : targetCurrency,
      multiplier: exRate,
    });
    return Number(payViaCurrencyAmount);
  };

  const convertFiatToSats = (amount) => {
    // Check current currency is Fiat; If it is convert it to SATS
    return !excludedCode.includes(values.currency?.code)
      ? Math.floor(exchangeRateValue * amount) // Fiat to SATs
      : parseFloat(amount);
  };

  const getAmountValueInSATS = (amount) => {
    const fiatValue = convertFiatToSats(amount);
    return values.currency?.code === "BTC"
      ? btcToSatsAmount(amount)
      : fiatValue;
  };

  const calculateSendAmount = ({
    satsAmountValue = satsAmount,
    enteredAmount = values.amount,
    exchangeRate = exchangeRateValue,
  }) => {
    const convertedAmount =
      values.currency.code === targetCurrency
        ? enteredAmount
        : amountToPayViaCurrencyAmount(enteredAmount, exchangeRate);
    return Number(isSATSPayVia ? satsAmountValue : convertedAmount);
  };

  const calculateSpeedFees = (
    sendAmount,
    speedFeeToCompare,
    forFeeBox = false
  ) => {
    const feesSendAmount = isSATSPayVia ? sendAmount : sendAmount * 100;
    const speedFeeAmount = Math.floor(
      feesSendAmount * (speedFeeToCompare / 100)
    );
    return Number(
      isSATSPayVia || forFeeBox ? speedFeeAmount : speedFeeAmount / 100
    );
  };

  const calculateEstimatedFees = (fees, exchangeRate, forFeeBox) => {
    const feesAmountIncents = forFeeBox ? fees : fees / 100;
    const convertedFeeAmount = amountToPayViaCurrencyAmount(fees, exchangeRate);
    const feeAmountInPayViaCurrency = forFeeBox
      ? convertedFeeAmount * 100
      : convertedFeeAmount;
    const payViaCurrencyFees = shouldPassFeesInCents
      ? feesAmountIncents
      : feeAmountInPayViaCurrency;
    return Number(isSATSPayVia ? fees : payViaCurrencyFees);
  };

  const calculateTotalAmountDeducted = (estimatedFees, forFeeBox = false) => {
    const speedFeesAmount = calculateSpeedFees(feeBoxSendAmount, speedFees);
    const estimatedFeesAmount = calculateEstimatedFees(
      estimatedFees,
      lightningPayViaCurrencyExchangeRate
    );
    const totalFees = feeBoxSendAmount + speedFeesAmount + estimatedFeesAmount;
    return Number(forFeeBox ? totalFees * 100 : totalFees);
  };

  const amountToSatsAmount = (amount, exchangeRate) => {
    return Math.floor(amount / exchangeRate);
  };

  const validateAmount = ({ enteredAmount, balanceAmount, exchangeRate }) => {
    let satsOfEnteredAmount,
      amount,
      balToCompare,
      errorMsg = "";

    const { min, max } = { ...amountLimits?.[targetCurrency] };

    if (isSATSPayVia) {
      satsOfEnteredAmount = getAmountValueInSATS(enteredAmount);
      balToCompare = balanceAmount ?? btcToSatsAmount(balance);
      amount = satsOfEnteredAmount;
    } else {
      satsOfEnteredAmount = amountToSatsAmount(enteredAmount, exchangeRate);
      balToCompare = balanceAmount;
      amount = calculateSendAmount({
        satsAmountValue: satsOfEnteredAmount,
        enteredAmount,
        exchangeRate,
      });
    }
    setSatsAmount(satsOfEnteredAmount);

    const satsLabel = min === 1 ? "SAT" : sats;
    const allowedCurrency = isSATSPayVia ? satsLabel : targetCurrency;
    if (amount > +balToCompare) errorMsg = balanceExceeded;
    else if (max && amount > max) errorMsg = maxAllowed(max, allowedCurrency);
    else if (min && amount < min) errorMsg = minAllowed(min, allowedCurrency);
    setAmountError(errorMsg);
    const isValid = !errorMsg;
    !isValid && estimatedFees && setEstimatedFees(0); // To hide the fees section if error occurred in above validation
    return { isValid, satsOfEnteredAmount };
  };

  const validationForEstimateFees = ({
    sendAmount,
    fees,
    exchangeRate,
    optionalParam,
  }) => {
    const feeAmount = calculateEstimatedFees(fees, exchangeRate);
    const { instantBal, instantSpeedFee } = { ...optionalParam };
    const speedFeeToCompare = instantSpeedFee ?? speedFees;
    const speedFeeAmount = calculateSpeedFees(sendAmount, speedFeeToCompare);
    const total = sendAmount + feeAmount + (speedFeeAmount || 0);
    total > instantBal &&
      setAmountError(keepSatsMsg(feeAmount, targetCurrency));
  };

  const validateAmountAndFees = async ({
    enteredAmount,
    satsTopayViaCurrencyExRate,
    balance,
    speedFee,
  }) => {
    const { isValid, satsOfEnteredAmount } = validateAmount({
      enteredAmount,
      balanceAmount: balance,
      exchangeRate: satsTopayViaCurrencyExRate,
    });

    if (isValid) {
      const sendAmount = calculateSendAmount({
        satsAmountValue: satsOfEnteredAmount,
        enteredAmount,
        exchangeRate: satsTopayViaCurrencyExRate,
      });

      const amountForEstimateFees = shouldPassFeesInCents
        ? sendAmount * 100
        : satsOfEnteredAmount;
      const feesRes = await getEstimatedFees(amountForEstimateFees); // in SATS/CENTS

      validationForEstimateFees({
        sendAmount,
        fees: feesRes?.fee,
        exchangeRate: satsTopayViaCurrencyExRate,
        optionalParam: {
          instantBal: Number(balance),
          instantSpeedFee: Number(speedFee),
        },
      });
    }
  };

  const setInitialAmountValue = async (valueFromSendMax = 0) => {
    const decodedAmountAddress = decodeAmt > 0 ? decodeAmt : "";
    let payViaCurrencyAmount =
        withdraw_method === lnurl ? values.amount : decodedAmountAddress,
      satsTopayViaCurrencyExRate;

    // when pay via cur is other then SAT used to set SAT ---> USDT/targetCurrency Ex rate.
    if (!isSATSPayVia && withdraw_method === lightning) {
      satsTopayViaCurrencyExRate = await getExchangeRate(sats, true);
      payViaCurrencyAmount =
        amountToPayViaCurrencyAmount(satsAmount, satsTopayViaCurrencyExRate) ||
        "";
    }

    if (!valueFromSendMax) setFieldValue("amount", payViaCurrencyAmount);

    const { balanceInTargetCurrency } = await getCalculatedBalance(true);
    const speedFee = await getSpeedFee();
    const amount = valueFromSendMax || payViaCurrencyAmount; //in payViaCurrency: SATS/USDT

    if (amount) {
      validateAmountAndFees({
        enteredAmount: Number(amount),
        satsTopayViaCurrencyExRate,
        balance: balanceInTargetCurrency,
        speedFee,
      });
    }
  };

  //Logic for Changing Currency option
  const handleCurrencyOnChange = (value) => {
    const isFiat = !excludedCode.includes(value?.code);
    setFieldValue("amount", "");
    setFieldValue("currency", value);
    setSatsAmount(0);
    setAmountError("");
    ((isSATSPayVia && isFiat) || targetCurrency !== value.code) &&
      getExchangeRate(value.code);
  };

  //Logic for Changing amount input
  const debouncedHandleChange = (enteredAmount, satsOfEnteredAmount) => {
    const sendAmount = calculateSendAmount({
      satsAmountValue: satsOfEnteredAmount,
      enteredAmount,
      exchangeRate: exchangeRateValue,
    });
    const amountForEstimateFees = shouldPassFeesInCents
      ? sendAmount * 100
      : satsOfEnteredAmount;

    const newTimeoutId = setTimeout(async () => {
      if (amountForEstimateFees > 0) {
        const feesRes = await getEstimatedFees(amountForEstimateFees);
        validationForEstimateFees({
          sendAmount,
          fees: feesRes?.fee,
          exchangeRate: exchangeRateValue,
          optionalParam: {
            instantBal: isSATSPayVia ? +btcToSatsAmount(balance) : balance,
          },
        });
      }
      setEstimatedFeeLoader(false);
      amountForEstimateFees === 0 && setEstimatedFees(0);
    }, 500);
    setTimeoutId(newTimeoutId);
  };

  const handleAmountChange = async (value) => {
    const amountValidationProps = {
      enteredAmount: +value,
    };

    const { isValid, satsOfEnteredAmount } = validateAmount({
      ...amountValidationProps,
      ...(!isSATSPayVia && {
        balanceAmount: +balance,
        exchangeRate:
          values.currency.code !== targetCurrency
            ? exchangeRateValue
            : lightningPayViaCurrencyExchangeRate,
      }),
    });

    if (timeoutId) clearTimeout(timeoutId);
    isValid
      ? debouncedHandleChange(+value, satsOfEnteredAmount)
      : setEstimatedFees(0);

    setFieldValue("amount", value);
  };

  const feeBoxSendAmount = calculateSendAmount({}) || 0;
  const inputTextPayViaCurrencySection = exchangeRateLoader ? (
    <Skeleton width={50} height={8} />
  ) : (
    <>
      ≈{feeBoxSendAmount} {targetCurrency}
    </>
  );

  //Logic for Onclick popper info element
  const handleClickPopper = ({ event, anchorEl, setAnchorEl }) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClosePopper = (setAnchorEl) => {
    setAnchorEl(null);
  };

  //Logic for Onclick Send Max Amount Button
  const setMaxAmountToInput = (sendMaxAmt) => {
    const cryptoAmount = isSATSCurrency
      ? sendMaxAmt
      : satsToBtcAmount(sendMaxAmt);
    const amount = excludedCode.includes(values.currency?.code)
      ? cryptoAmount
      : satsToFiatAmount(sendMaxAmt, exchangeRateValue, 4);
    setFieldValue("amount", amount);
    setSatsAmount(sendMaxAmt);
    setInitialAmountValue(amount);
  };

  //Logic for Onclick Send Button
  const createInstantSend = async () => {
    setSendBtnLoader(true);
    const { amount, currency, note } = values;
    const params = {
      currency: currency?.code,
      target_currency: targetCurrency,
      withdraw_request,
      withdraw_method: withdraw_method === lnurl ? lightning : withdraw_method,
      amount: Number(amount),
    };
    if (note) params.note = note;
    try {
      const result = await callAPIInterface(
        "POST",
        "/send?currency_denomination=higher",
        JSON.stringify(params)
      );
      result && history.push(`/instant-sends/${result.id}`);
    } catch (e) {}
    handleModalClose();
    setSendBtnLoader(false);
  };

  const checkForSufficientBalance = (
    balanceInTargetCurrency,
    estimatedFeesAmount
  ) => {
    const balanceAvailable = Number(balanceInTargetCurrency);
    const totalDeductedAmount =
      calculateTotalAmountDeducted(estimatedFeesAmount);
    const isInsufficientBalance = balanceAvailable < totalDeductedAmount;
    return { isInsufficientBalance };
  };

  const proceedFunc = (balanceInTargetCurrency) => {
    const balanceAvailable = Number(balanceInTargetCurrency);
    const { isInsufficientBalance } = checkForSufficientBalance(
      balanceAvailable,
      estimatedFees
    );
    if (isInsufficientBalance) {
      setSendBtnLoader(false);
      setOpenInsufficientBalanceModal(true);
    } else if (idealTimeLimitReached(session.last_action_time)) {
      setSendBtnLoader(false);
      setShowCrucialActionModal(user, true);
    } else createInstantSend();
  };

  const handleSend = async () => {
    setSendBtnLoader(true);
    formik.setSubmitting(false);
    const { balanceInTargetCurrency } = await getCalculatedBalance();

    if (!excludeFeeCheckMethods.includes(withdraw_method)) {
      const amountForEstimateFees = shouldPassFeesInCents
        ? feeBoxSendAmount * 100
        : satsAmount;

      const feesResponse = await getEstimatedFees(amountForEstimateFees, false);
      const newFees = feesResponse?.fee;
      if (estimatedFees < newFees) {
        setOpenFeesModal(true);
        setSendBtnLoader(false);
        setNewEstimatedFees(newFees);
      } else proceedFunc(balanceInTargetCurrency);
    } else {
      proceedFunc(balanceInTargetCurrency);
    }
    dispatch(setInstantSendButtonClicked(false));
  };

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    setInitialAmountValue(); // When address has an amount; checking validation of amount and calling estimate fees api
  }, [targetCurrency]);

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      createInstantSend();
      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  useEffect(() => {
    const isDisable =
      !values.amount ||
      !values.currency ||
      Boolean(amountError) ||
      balanceLoader ||
      !balance ||
      sendMaxLoader ||
      speedFeeLoader ||
      disableSend;
    setDisableSend(isDisable);
  }, [
    amountError,
    values.amount,
    balance,
    balanceLoader,
    sendMaxLoader,
    speedFeeLoader,
    disableSend,
  ]);

  useEffect(() => {
    instantSendButtonClicked && handleSend();
  }, [instantSendButtonClicked]);

  //Insufficient Balance Modal
  const handleTryAgain = async () => {
    setOpenInsufficientBalanceModal(false);
    handleBack();
  };

  //Amount ExceedModal
  const handleFeesProceed = async () => {
    setFeesBtnLoader(true);
    const { balanceInTargetCurrency } = await getCalculatedBalance();
    const { isInsufficientBalance } = checkForSufficientBalance(
      Number(balanceInTargetCurrency),
      newEstimatedFees
    );

    // Check if balance is less than actual amount+fees
    if (isInsufficientBalance) {
      setEstimatedFees(newEstimatedFees);
      setOpenInsufficientBalanceModal(true);
    } else if (idealTimeLimitReached(session.last_action_time)) {
      setSendBtnLoader(false);
      setShowCrucialActionModal(user, true);
    } else createInstantSend();
    setOpenFeesModal(false);
    setFeesBtnLoader(false);
  };

  const labelWithPopperElement = ({
    label,
    infoMessage,
    anchorEl,
    setAnchorEl,
  }) => (
    <Box display="flex" alignItems="center">
      <Label>{label}</Label>
      <InfoIcon
        className="label-with-icon"
        sx={{
          height: "20px !important",
          width: "20px !important",
          marginBottom: "14px",
        }}
        onClick={(event) =>
          handleClickPopper({
            event,
            anchorEl,
            setAnchorEl,
          })
        }
      />
      <CustomPopper
        disablePortal={true}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        position="top"
        handleClose={() => handleClosePopper(setAnchorEl)}
      >
        <Text className="grey-text" size={14} variant="subtitle1">
          {infoMessage}
        </Text>
      </CustomPopper>
    </Box>
  );

  const payViaSection = () => (
    <Box className="margin-top30">
      {labelWithPopperElement({
        label: payViaLabel,
        infoMessage: payViaInfo,
        anchorEl: payViaAnchorEl,
        setAnchorEl: setPayViaAnchorEl,
      })}
      <CustomSelect
        disabled={disablePayViaOption}
        name="pay_via"
        displayEmpty
        showLabel={false}
        label={payViaLabel}
        value={values?.pay_via}
        placeholder={selectCurrencyLabel}
        renderValue={(option) => option.name}
        MenuProps={{
          id: "currency-select",
        }}
        onChange={(e) => {
          setBalanceLoader(true);
          const targetCurrency = e.target.value;
          setFieldValue("pay_via", targetCurrency);
          setFieldValue(
            "currency",
            currency.find((item) => item.code === targetCurrency.uuid)
          );
        }}
      >
        {payViaOptions?.map((option) => (
          <MenuItem key={option.name} value={option}>
            {option.name}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  );

  const recipientSection = () => (
    <>
      <Input
        value={values.formatted_request}
        showLabel={true}
        label={recipientLabel}
        fullWidth
        customClass="margin-top30"
        disabled={true}
      />
      <Label
        sx={{
          mt: "8px",
          mb: "30px !important",
          fontFamily: "Inter-Regular !important",
          lineHeight: "20px !important",
        }}
      >
        {addressDesc}
      </Label>
    </>
  );

  const isSendMaxVisible =
    (withdraw_method === lightning && !decodeAmt) ||
    (withdraw_method === lnurl && max_sendable !== min_sendable);

  const amountSection = () => {
    const disableAmountInput =
      disableCurrency ||
      exchangeRateLoader ||
      !balance ||
      Number(balance) === 0;

    const inputProps = {
      values,
      showPopper: true,
      popperInfoMsg: payViaAmountMsg,
      currencyName: "currency",
      amountName: "amount",
      isSendMaxVisible,
      sendMaxLoader,
      sendMaxBtnLabel: sendMax,
      getMaxAmount: getLightningMaxAmount,
      disableAmountInput,
      amountError,
      inputEndAmountTextVisible: values.currency?.code !== targetCurrency,
      inputEndAmountText: inputTextPayViaCurrencySection,
      handleCurrencyOnChange: (value) => handleCurrencyOnChange(value),
      handleInputChange: handleAmountChange,
    };

    return (
      <>
        <AmountWithCurrencyInput {...inputProps} />
        {availableBalanceTextComponent({
          preText: balancePreText,
          balance: showAmount({
            amount: balance,
            currency: values.pay_via.name,
            appendCurrency: true,
            multiplier: 1,
          }),
          loading: balanceLoader,
          ...(isSATSPayVia && {
            calculatedSATS: `${commaSeperatedCurrencyAmount(
              btcToSatsAmount(balance),
              "BTC"
            )} SATS`,
          }),
          sx: { mb: !amountError ? "30px" : "15px", mt: "10px" },
        })}
        {amountError && (
          <AlertMessage
            message={amountError}
            severity="error"
            sx={{ mb: "30px" }}
          />
        )}
      </>
    );
  };

  const addNoteSection = () => (
    <TextAreaComponent
      label={addNote}
      minRows={3}
      fullWidth
      name="text-area-name"
      onChange={(e) => setFieldValue("note", e.target.value)}
      placeholder={addNotePlaceholder}
      resize={false}
      value={values.note}
      maxLength={1000}
    />
  );

  const feesSectionProps = {
    amount: feeBoxSendAmount,
    speedFee: calculateSpeedFees(feeBoxSendAmount, speedFees, true),
    estimatedFees: calculateEstimatedFees(
      estimatedFees,
      lightningPayViaCurrencyExchangeRate,
      true
    ),
    exchangeRateValue,
    loader:
      speedFeeLoader ||
      sendMaxLoader ||
      estimatedFeeLoader ||
      exchangeRateLoader,
    currencyCode: values?.currency?.code,
    targetCurrency,
    payoutType: withdraw_method,
  };

  const feesSection = () =>
    Boolean(values?.amount && estimatedFees) && (
      <CommonEstimateFeesBox {...feesSectionProps} />
    );

  return (
    <Box component="div" display="flex" justifyContent="center">
      <Box
        component="div"
        width="599px"
        className="margin-top30 margin-bottom30"
      >
        <Text size={20} className="default-text" variant="h3" font="semibold">
          {sendPaymentLabel}
        </Text>
        {payViaSection()}
        {recipientSection()}
        {amountSection()}
        {addNoteSection()}
        {feesSection()}
      </Box>

      {openFeesModal && (
        <FeesExceedModal
          btnLoader={feesBtnLoader}
          targetCurrency={targetCurrency}
          estimatedFees={estimatedFees}
          handleClose={() => setOpenFeesModal(false)}
          handleFeesProceed={handleFeesProceed}
          newEstimatedFees={newEstimatedFees}
          openFeesModal={openFeesModal}
        />
      )}
      {openInsufficientBalanceModal && (
        <InsufficientBalanceModal
          targetCurrency={targetCurrency}
          handleClose={() => setOpenInsufficientBalanceModal(false)}
          handleTryAgain={handleTryAgain}
          open={openInsufficientBalanceModal}
          totalAmount={calculateTotalAmountDeducted(estimatedFees, true)}
          type="Instant send"
        />
      )}
    </Box>
  );
};

export default InstantSendForm;
