import React, { useState } from "react";
import { Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import { btc, enterAmount, sats } from "../../messages";
import { Input } from "@speed/common/src/components/Input/Input";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import Label from "@speed/common/src/components/Label/Label";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { handleClick } from "../../Common/AllLinkFormConstant";
import Text from "@speed/common/src/components/Text/Text";
import { cryptoCurrency } from "@speed/common/src/components/constants";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { currency } from "@speed/common/src/components/currency";
import { allowedFourAfterDecimalRegex } from "../../constants";

const LimitField = ({
  currencyName,
  amountInputName,
  formik,
  label,
  infoText,
  selectedAsset,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const { values, errors, setFieldValue } = formik;
  const isSATS = values[currencyName]?.code === sats;
  const openPopper = Boolean(anchorEl);

  const handleCurrencyOnChange = (_e, value) => {
    setFieldValue([currencyName], value);
    setFieldValue([amountInputName], "");
  };

  const handleAmountChange = (value) => {
    const calculatedAmount = value.slice(0, isSATS ? 9 : 10);
    setFieldValue([amountInputName], calculatedAmount);
  };

  const getSelectedAssetOptions = () => {
    return [currency.find((item) => item.code === selectedAsset)];
  };

  return (
    <Box className="instant-send-limits-box">
      <Box display="flex">
        <Label>{label}</Label>
        <InfoIcon
          className="instant-send-info-icon"
          onClick={(e) => handleClick(e, setAnchorEl, anchorEl)}
        />
        <CustomPopper
          sx={{ zIndex: "9999 !important" }}
          open={openPopper}
          anchorEl={anchorEl}
          position="bottom"
          handleClose={() => setAnchorEl(null)}
        >
          <Text
            size={14}
            variant="subtitle1"
            className="grey-text"
            font="regular"
          >
            {infoText}
          </Text>
        </CustomPopper>
      </Box>
      <Box className={`dropdown-input-wrapper`}>
        <CustomAutoComplete
          {...(selectedAsset !== btc && {
            className: "hide-dropdown-arrow-icon",
          })}
          disabled={selectedAsset !== btc}
          name={currencyName}
          options={
            selectedAsset === btc
              ? Object.values(cryptoCurrency)
              : getSelectedAssetOptions()
          }
          forcePopupIcon={selectedAsset === btc}
          getOptionLabel={(value) => value.code}
          onChange={handleCurrencyOnChange}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          value={values[currencyName]}
          showLabel={false}
          disableClearable
          renderOption={(_props, option) => (
            <li key={option.code} {..._props}>
              {option.code}
            </li>
          )}
          customClass="instant-send-select"
        />
        <Box m="auto" className="divider" />
        <Input
          tabIndex={0}
          type="number"
          className="auto-payout-amount-input"
          showLabel={false}
          placeholder={enterAmount}
          name={amountInputName}
          value={values[amountInputName]}
          onWheel={(e) => e.target.blur()}
          error={Boolean(errors[amountInputName])}
          onKeyDown={(event) => {
            isSATS && event.key === "." && event.preventDefault();
          }}
          onChange={(e) => {
            const value = e.target.value;
            if (
              value &&
              selectedAsset !== btc &&
              !allowedFourAfterDecimalRegex.test(value)
            ) {
              e.preventDefault();
            } else handleAmountChange(value);
          }}
        />
      </Box>
      {errors[amountInputName] && (
        <AlertMessage
          message={errors[amountInputName]}
          severity="error"
          sx={{ mt: "15px" }}
        />
      )}
    </Box>
  );
};

export default LimitField;
