import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import {
  enterCustomAmount,
  maxBtcAllowed,
  maximumUsdtAllowedAtReceive,
  maxSatsAllowed,
  minAmountRequiredForLightningUsdt,
  minBtcRequired,
  minLightningUSDTRequired,
  minOneSatRequired,
  reset,
  sats,
  submit,
  usdt,
} from "../messages";
import {
  commonCustomDivider,
  maxAmountAllowedSats,
  maximumAmountUsdtReceive,
  minAmountRequiredSatsAtReceive,
} from "../constants";
import { useMemo, useState } from "react";
import AmountBox from "./SendPayment/AmountBox";
import { satsToBtcAmount } from "@speed/common/src/components/constants";
import { useSelector } from "react-redux";

const EnterCustomAmount = ({
  switchCurrency,
  amount,
  fiatAmount,
  note,
  setAmount,
  setFiatAmount,
  setNote,
  isLoading,
  exchangeRateLoading,
  isNoteClicked,
  exchangeRate,
  setSwitchCurrency,
  currency,
  onSubmit,
  displayAmount,
  selectedTab,
  isAmountClicked,
  selectedMethodName,
}) => {
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const [isLargeAmount, setIsLargeAmount] = useState(false);

  const handleReset = () => {
    setAmount(0);
    setFiatAmount(0);
    setNote("");
    setIsLargeAmount(false);
  };

  const receiveCalculations = useMemo(() => {
    const numAmount = Number(amount);

    let btnLabel = submit,
      btnDisable = false;

    const handleLimits = (minAmount, maxAmount, minLabel, maxLabel) => {
      if (numAmount < minAmount) {
        btnLabel = minLabel;
        btnDisable = true;
      } else if (numAmount > maxAmount) {
        btnLabel = maxLabel;
        btnDisable = true;
      }
    };

    if (currency === usdt) {
      handleLimits(
        minAmountRequiredForLightningUsdt,
        maximumAmountUsdtReceive,
        minLightningUSDTRequired,
        maximumUsdtAllowedAtReceive
      );
    } else if (currency === sats) {
      if (showBtcAmount) {
        const minAmountBtc = satsToBtcAmount(minAmountRequiredSatsAtReceive);
        const maxAmountBtc = satsToBtcAmount(maxAmountAllowedSats);
        handleLimits(
          minAmountBtc,
          maxAmountBtc,
          minBtcRequired(minAmountBtc),
          maxBtcAllowed(maxAmountBtc)
        );
      } else {
        handleLimits(
          minAmountRequiredSatsAtReceive,
          maxAmountAllowedSats,
          minOneSatRequired,
          maxSatsAllowed
        );
      }
    }
    return {
      btnLabel,
      btnDisable,
    };
  }, [amount]);

  const amountBoxProps = {
    switchCurrency,
    setFiatAmount,
    setAmount,
    exchangeRate,
    fiatAmount,
    amount,
    displayAmount,
    currency,
    note,
    setNote,
    setIsLargeAmount,
    isNoteClicked,
    isLargeAmount,
    setSwitchCurrency,
    exchangeRateLoading,
    fromReceive: true,
    selectedTab,
    isAmountClicked,
    selectedMethodName,
  };

  return (
    <Box sx={{ backgroundColor: "#f7fafc" }}>
      <Box className="amount-container">
        <Box className="enter-amount-heading" justifyContent="space-between">
          <Text size={20} font="semibold" className="default-text" variant="h6">
            {enterCustomAmount}
          </Text>
          <Text
            size={16}
            font="semibold"
            sx={{ color: "#2A67FF", cursor: "pointer" }}
            onClick={handleReset}
          >
            {reset}
          </Text>
        </Box>
        <AmountBox {...amountBoxProps} />
        {commonCustomDivider("30px")}
        <Box className="content-center">
          <Button
            sx={{ margin: "24px 0px", width: "520px" }}
            disabled={receiveCalculations?.btnDisable || isLargeAmount}
            variant="contained"
            className="send-amt-btn"
            label={receiveCalculations?.btnLabel}
            onClick={() => onSubmit(amount, note)}
            loading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EnterCustomAmount;
