import React, { forwardRef } from "react";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import { createSwap, swapText } from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import SwapRequestDetails from "./SwapRequestDetails";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateSwapModal = (props) => {
  const {
    openModal,
    isLoading,
    handleModalClose,
    getSpeedFee,
    handleSubmit,
    isValid,
    dirty,
    values,
    setFieldValue,
    touched,
    setTouched,
    errors,
    defaultErrorMessageProps,
    speedFeeLoading,
    swapLimitLoading,
    balanceLoading,
    balanceData,
    disableSwapBtn,
    setDisableSwapBtn,
  } = props;

  const swapProps = {
    values,
    getSpeedFee,
    setFieldValue,
    touched,
    setTouched,
    errors,
    defaultErrorMessageProps,
    speedFeeLoading,
    swapLimitLoading,
    balanceLoading,
    balanceData,
    setDisableSwapBtn,
    isValid,
    dirty,
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openModal}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={handleModalClose}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {createSwap}
          </Text>
          <Button
            loading={isLoading}
            label={swapText}
            variant="contained"
            color="primary"
            className="payment-link-btn"
            onClick={handleSubmit}
            disabled={disableSwapBtn}
          />
        </Toolbar>
      </AppBar>
      <SwapRequestDetails {...swapProps} />
    </Dialog>
  );
};

export default CreateSwapModal;
