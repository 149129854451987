import React, { useContext, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { isEmpty } from "lodash";
import {
  checkLinkOptionsAvailable,
  defaultPaymentMethodForSelectedAsset,
  getFormattedWalletsData,
  getPreviewTotalAmount,
  handleSetInitialPaymentsObj,
  satsToBtcAmount,
} from "../constants";
import { wholeQrSkeleton } from "../CommonSkeletonComponent";
import LinkConfirmView from "../LinkConfirmView";
import QRCodePreview from "./QRCodePreview";
import { PaymentPageContext, WebPreviewContext } from "../../contexts/contexts";
import PayViaOptions from "../PayViaOptions";
import RightSideDetailsSection from "./RightSideDetailsSection";
import { getPaymentAddressOfSelectedCurrency } from "@speed/checkout/src/components/constants";

const RightSection = (props) => {
  const {
    paymentDetails,
    paymentInfo,
    paymentMessage,
    confirmPayment,
    totalAmount,
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    selectedAssetCurrency,
    selectedPaymentMethod,
    paymentDetailsObj,
    setPaymentDetailsObj,
    setAssetAmountLoader,
    setAssetQrLoader,
  } = props;

  const [showEditedContactInfo, setShowEditedContactInfo] = useState(false);
  const [dynamicWalletData, setDynamicWalletData] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [activeSection, setActiveSection] = useState("1");
  const [selectedCurrencyPaymentMethods, setSelectedCurrencyPaymentMethods] =
    useState(defaultPaymentMethodForSelectedAsset);

  // Get context value using consumer
  const paymentPageData = useContext(PaymentPageContext);
  const WebPreviewProps = useContext(WebPreviewContext);

  useEffect(() => {
    if (WebPreviewProps?.visibleInPaymentPage) getWalletsDataFromStrapi();
  }, [WebPreviewProps?.paymentPageSettings]);

  useEffect(() => {
    setSubmitClicked(false);
    if (submitClicked && paymentInfo?.custom_fields?.length > 0) {
      const isAllOptional = paymentInfo.custom_fields.map(
        (item) => item.is_optional
      );
      setSubmitClicked(isAllOptional?.includes(true));
    }
  }, [
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    paymentInfo?.custom_fields,
  ]);

  useEffect(() => {
    setAssetQrLoader(paymentPageData?.qrCodeLoader);
  }, [paymentPageData?.qrCodeLoader]);

  // Helper function: Responsible for show/hide details and form in the preview of Right side section
  const {
    contactFormExist,
    customFieldExist,
    isDetailsSectionVisible,
    visibleDetailForm,
    isContactInfoFormVisible,
    isCustomFieldVisible,
    showCustomerCollections,
    checkedLengthKeys,
  } = checkLinkOptionsAvailable({
    paymentInfo,
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    paymentPageData,
    showEditedContactInfo,
    submitClicked,
  });

  useEffect(() => {
    if (
      props?.from === "checkout" &&
      !visibleDetailForm &&
      selectedPaymentMethod &&
      !isEmpty(paymentDetailsObj)
    ) {
      getPaymentAddressOfSelectedCurrency({
        selectedAssetCurrency,
        selectedPaymentMethod,
        paymentInfo,
        cs_id: props?.id,
        paymentDetailsObj,
        setPaymentDetailsObj,
        setAssetAmountLoader,
        setAssetQrLoader,
        setIsToastOpen: paymentPageData?.setIsToastOpen,
        setToastMessage: paymentPageData?.setToastMessage,
      });
    }
  }, [selectedPaymentMethod, selectedAssetCurrency]);

  useEffect(() => {
    if (
      WebPreviewProps?.accountAsset?.length &&
      paymentDetails?.status !== "paid"
    ) {
      // set initial payments object having currency and their methods to render tabs when the target currency is null
      const result = handleSetInitialPaymentsObj(
        WebPreviewProps,
        paymentInfo,
        props?.from,
        props?.isFromStaticData
      );

      setPaymentDetailsObj(result);
    }
  }, [paymentInfo]);

  const getWalletsDataFromStrapi = async () => {
    const walletNames = WebPreviewProps?.paymentPageSettings?.wallets;
    const formattedWalletsObj = await getFormattedWalletsData(walletNames);
    setDynamicWalletData(formattedWalletsObj);
  };

  useEffect(() => {
    if (
      !paymentInfo?.custom_fields &&
      checkedLengthKeys.every((item) => item === false)
    ) {
      setShowEditedContactInfo(false);
    }
  }, [paymentInfo?.custom_fields, checkedLengthKeys]);

  const showMainContent = () => {
    return !visibleDetailForm ? (
      <Box>
        <QRCodePreview
          {...props}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          amount={getPreviewTotalAmount({
            data: paymentInfo,
            paymentDetailsObj,
            visibleInPaymentPage: true,
            withoutBTC: true,
          })}
          visibleInPaymentPage={WebPreviewProps?.visibleInPaymentPage}
          boxWidth={WebPreviewProps?.commonWidth}
          onChainAvailable={WebPreviewProps?.onChainAvailable}
          lightningLabel={WebPreviewProps?.lightningLabel}
          qrActionBtnWidth={WebPreviewProps?.qrActionBtnWidth}
          dummyLightningAddress={WebPreviewProps?.dummyLightningAddress}
          dummyOnchainAddress={WebPreviewProps?.dummyOnchainAddress}
          dummyEthereumAddress={WebPreviewProps?.dummyEthereumAddress}
          dummyLightningUSDTAddress={WebPreviewProps?.dummyLightningUSDTAddress}
          lightningPaymentAddress={
            paymentDetailsObj?.[selectedAssetCurrency]?.[
              selectedPaymentMethod
            ] ||
            WebPreviewProps?.lightningPaymentAddress ||
            WebPreviewProps?.dummyLightningAddress
          }
          onChainPaymentAddress={
            paymentDetailsObj?.[selectedAssetCurrency]?.[
              selectedPaymentMethod
            ] || WebPreviewProps?.onChainPaymentAddress
          }
          confirmPageProps={WebPreviewProps?.confirmPageProps}
          isFromInvoice={WebPreviewProps?.isFromInvoice}
          boxAmountValue={
            (paymentDetails &&
              `${satsToBtcAmount(paymentDetails.target_amount_paid)} BTC`) ||
            totalAmount
          }
          isDetailsSectionVisible={isDetailsSectionVisible}
          showCustomerCollections={showCustomerCollections}
          setShowEditedContactInfo={setShowEditedContactInfo}
          accountAsset={WebPreviewProps?.accountAsset}
          selectedCurrencyPaymentMethods={selectedCurrencyPaymentMethods}
          setSelectedCurrencyPaymentMethods={setSelectedCurrencyPaymentMethods}
          updateSelectedCurrencyState={
            WebPreviewProps?.updateSelectedCurrencyState
          }
        />
      </Box>
    ) : (
      <RightSideDetailsSection
        {...props}
        showEditedContactInfo={showEditedContactInfo}
        setShowEditedContactInfo={setShowEditedContactInfo}
        showCustomerCollections={showCustomerCollections}
        isContactInfoFormVisible={isContactInfoFormVisible}
        isCustomFieldVisible={isCustomFieldVisible}
        isWebPreview={true}
        updatePaymentInfoFn={WebPreviewProps?.updatePaymentInfoFn}
        visibleInPaymentPage={WebPreviewProps?.visibleInPaymentPage}
        checkoutSessionId={WebPreviewProps?.checkoutSessionId}
        setSubmitClicked={setSubmitClicked}
        contactFormExist={contactFormExist}
        customFieldExist={customFieldExist}
        setCustomFiledUpdatedFrom={WebPreviewProps?.setCustomFiledUpdatedFrom}
      />
    );
  };

  const skeletonLoader = () => {
    return WebPreviewProps?.visibleInPaymentPage &&
      !WebPreviewProps?.accountAsset?.length &&
      paymentPageData?.qrCodeLoader
      ? // Show loader while payment page API is calling
        wholeQrSkeleton({ size: "398px" })
      : showMainContent();
  };

  return (
    <Box
      component="div"
      padding={WebPreviewProps?.rightBoxWidth}
      className="right-side-section"
    >
      <Box className="right-container-box" width={WebPreviewProps?.commonWidth}>
        {confirmPayment ? (
          <Grid className="confirm-payment-wrapper" item lg={12}>
            <LinkConfirmView
              activeSection={activeSection}
              visibleInPaymentPage={WebPreviewProps?.visibleInPaymentPage}
              paymentMessage={paymentMessage}
              paymentDetails={paymentDetails}
              paymentInfo={paymentInfo}
              additionalClass="latest-confirm-preview"
              confirmPageProps={WebPreviewProps?.confirmPageProps}
              isFromInvoice={WebPreviewProps?.isFromInvoice}
              totalAmount={totalAmount}
              selectedAssetCurrency={selectedAssetCurrency}
              paymentDetailsObj={paymentDetailsObj}
              selectedPaymentMethod={selectedPaymentMethod}
            />
          </Grid>
        ) : (
          skeletonLoader()
        )}
      </Box>
      <Box
        marginTop="auto"
        width={WebPreviewProps?.commonWidth}
        className="bottom-footer"
      >
        <PayViaOptions
          from=""
          className="pay-via-options-payment"
          paymentPageSettings={WebPreviewProps?.paymentPageSettings}
          dynamicWalletData={dynamicWalletData}
        />
      </Box>
    </Box>
  );
};

export default RightSection;
