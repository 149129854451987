import { Box } from "@mui/material";
import React from "react";
import Text from "@speed/common/src/components/Text/Text";
import { btc, getStartedTxt, receive, sats, usdt } from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { setPaymentProcessingRecallApis } from "../../redux/common/actions";
import { useDispatch } from "react-redux";

function NewToSpeedWallet({
  currency,
  currencyColor,
  currencyIcon,
  walletBGImage,
  setOpenReceiveModal,
  sendReceiveClassName,
}) {
  const dispatch = useDispatch();
  return (
    <Box
      className={`new-speed-wallet-wrapper ${currencyColor}`}
      padding={"20px"}
      position="relative"
      margin="0 0 10px"
      minHeight="200px"
    >
      <img
        src={currencyIcon}
        alt="welcome-to-speed"
        height="50px"
        width="50px"
      />
      <Text
        className="default-text"
        size={24}
        font="bold"
        sx={{ marginTop: "19px", textAlign: "center" }}
      >
        {getStartedTxt(currency === sats ? btc : usdt)}
      </Text>
      {walletBGImage}
      <Button
        icon="expandCircleIcon"
        className={`receive-btn receive-${sendReceiveClassName}`}
        label={receive}
        variant="outlined"
        color="primary"
        sx={{ width: "150px !important", mt: "24px", mr: "unset !important" }}
        onClick={() => {
          dispatch(setPaymentProcessingRecallApis(false));
          setOpenReceiveModal(true);
        }}
      />
    </Box>
  );
}

export default NewToSpeedWallet;
