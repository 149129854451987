import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Text from "../Text/Text";
import { selectNetwork } from "../../messages";
import { getSelectedMethod, paymentMethodData } from "../constants";

const AssetPaymentMethods = (props) => {
  const {
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    selectedCurrencyPaymentMethods,
    payment,
    assetQrLoader,
    fontStyle,
  } = props;

  // const initialSelectedMethod =
  //   payment?.payment_method || selectedCurrencyPaymentMethods[0]?.id;

  // useEffect(() => {
  //   if (selectedCurrencyPaymentMethods?.length) {
  //     console.log(initialSelectedMethod, "here", selectedPaymentMethod);
  //     setSelectedPaymentMethod(
  //       selectedPaymentMethod !== initialSelectedMethod
  //         ? selectedCurrencyPaymentMethods[0]?.payment_method_name
  //         : initialSelectedMethod
  //     );
  //   }
  // }, [selectedCurrencyPaymentMethods]);

  const handleTabSelect = (methodName) => {
    setSelectedPaymentMethod(getSelectedMethod(methodName));
  };

  const getPaymentMethodName = (method) =>
    method === "onchain" ? "bitcoin" : method;

  return (
    <Box className="asset-payment-methods-wrapper">
      <Text
        size={16}
        font="medium"
        variant="inline"
        className="default-text"
        sx={{ ...fontStyle, cursor: "pointer" }}
        withIcon="start"
      >
        {selectNetwork}
      </Text>
      <Box display="flex" mt="14px">
        {selectedCurrencyPaymentMethods?.map((method, index) => {
          return (
            <Box
              className={`asset-tab-section-preview ${
                (selectedPaymentMethod
                  ? selectedPaymentMethod === method?.payment_method_name
                  : index === 0) && "selected"
              }`}
              sx={{
                marginLeft: index !== 0 ? "10px" : "0px",
              }}
              key={method?.id}
              onClick={() => !assetQrLoader && handleTabSelect(method?.id)}
            >
              <img
                src={paymentMethodData[method?.id]?.icon}
                alt="asset"
                height="20px"
                width="20px"
              />
              <Text
                size={16}
                font="medium"
                variant="inline"
                className="default-text"
                sx={{
                  ...fontStyle,
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
                withIcon="start"
              >
                {getPaymentMethodName(method?.payment_method_name)}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default AssetPaymentMethods;
