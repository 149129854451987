import { Box, Skeleton } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import {
  btc,
  createLnAddress,
  pendingTransaction,
  recentTransactions,
  sats,
  viewAll,
  // assetsCreateLn,
  // setupText,
  // yourLnAddress,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import history from "@speed/common/src/components/history";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import WalletTransactionsList from "../WalletTransaction/WalletTransactionsList";
import { useDispatch, useSelector } from "react-redux";
import {
  createLnAddressIcon,
  hourGlassOrange,
  // lnaddressSetupComplete,
} from "../images";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { setWalletSelectedTransaction } from "../../redux/common/actions";

const PendingTransaction = ({
  isNoTransactionPerformed,
  selectedCurrencyType,
  getTxnCurrency,
  currency,
  setTotalUnconfirmed,
  isPaymentAddressLoading,
  setIsSettingupLnAddress,
  totalUnconfirmed,
  // setIsOpenPaymentAddressModal,
}) => {
  const dispatch = useDispatch();
  const walletPaymentAddress = useSelector(
    (state) => state.common.walletPaymentAddress
  );

  const handlePendingTransaction = () => {
    dispatch(setWalletSelectedTransaction(null));
    history.push(
      `/wallet-transactions?status=pending&target_currency=${getTxnCurrency()}`
    );
  };

  return (
    <Box>
      {selectedCurrencyType?.uuid === sats && (
        <Box>
          {isPaymentAddressLoading || !selectedCurrencyType ? (
            <Skeleton
              style={{
                height: "100px",
                marginLeft: "40px",
                marginRight: "40px",
              }}
            />
          ) : (
            <Box
              mt="16px !important"
              className="assets-right-container pointer-cursor"
            >
              {!walletPaymentAddress ? (
                <Box
                  className="ln-address-setup pending-transaction"
                  onClick={() => {
                    setIsSettingupLnAddress(true);
                  }}
                >
                  <Box className="content-center">
                    <img
                      src={createLnAddressIcon}
                      alt="icon"
                      style={{
                        marginRight: "8px",
                      }}
                    />
                    <Text
                      size={16}
                      className="default-text"
                      variant="h6"
                      font="regular"
                      style={{ marginRight: "8px" }}
                    >
                      {createLnAddress}
                    </Text>
                  </Box>
                  <ArrowForwardIosIcon
                    style={{
                      color: "#848B9E",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </Box>
              ) : (
                <Box className="ln-address-setup pending-transaction">
                  <Box className="content-center">
                    <img
                      src={createLnAddressIcon}
                      alt="icon"
                      style={{
                        marginRight: "8px",
                      }}
                    />
                    <Text
                      size={16}
                      className="default-text"
                      variant="h6"
                      font="semibold"
                    >
                      {walletPaymentAddress}
                    </Text>
                  </Box>
                  <Clipboard
                    text={walletPaymentAddress}
                    style={{
                      cursor: "pointer",
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      )}
      {totalUnconfirmed > 0 && (
        <Box mt="6px !important" className="assets-right-container">
          <Box
            className="ln-address-setup pending-transaction"
            onClick={handlePendingTransaction}
          >
            <Box className="content-center">
              <img
                src={hourGlassOrange}
                alt="icon"
                style={{
                  marginRight: "8px",
                }}
              />
              <Text
                size={16}
                className="default-text"
                variant="h6"
                font="semibold"
                style={{ color: "#D88A2C" }}
              >
                {`${totalUnconfirmed}${
                  totalUnconfirmed > 10 ? "+" : ""
                } ${pendingTransaction}${totalUnconfirmed > 1 ? "s" : ""}`}
              </Text>
            </Box>
            <ArrowForwardIosIcon
              style={{
                color: "#848B9E",
                width: "16px",
                height: "16px",
              }}
            />
          </Box>
        </Box>
      )}
      {!isNoTransactionPerformed && selectedCurrencyType && (
        <Box p="24px 40px">
          <Box mb="22px" display="flex" justifyContent="space-between">
            <Text font="regular" size={20}>
              {recentTransactions}
            </Text>
            <Button
              icon="visibilityIcon"
              withIcon="start"
              variant="outlined"
              style={{ fontSize: "14px" }}
              label={viewAll}
              disabled={!selectedCurrencyType}
              onClick={() => {
                history.push(
                  `/wallet-transactions?status=confirm&target_currency=${getTxnCurrency()}`
                );
              }}
            />
          </Box>
          <CustomDivider />
          {currency && (
            <WalletTransactionsList
              fromMyAssets={true}
              currency={currency === btc ? sats : currency}
              setTotalUnconfirmed={setTotalUnconfirmed}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default PendingTransaction;
