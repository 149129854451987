import React, { useEffect, useState } from "react";
import { find } from "lodash";
import { Box, MenuItem, Skeleton, createFilterOptions } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  addDescription,
  balanceAvailableForModule,
  btc,
  currencyPlaceholder,
  description,
  destinationAccount,
  fromAccount,
  minimumAmountLabel,
  payoutAmountInfoMessage,
  sats,
  selectAccount,
  transferDetailsText,
  transferViaLabel,
} from "../messages";
import Label from "@speed/common/src/components/Label/Label";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { Input } from "@speed/common/src/components/Input/Input";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import {
  btcToSatsAmount,
  fetchPrecisedInteger,
  getCurrencyObj,
  showAmount,
} from "@speed/common/src/components/constants";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { useSelector } from "react-redux";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { callAPIInterface, getAccNameFromId } from "../constants";
import CustomSelect from "@speed/common/src/components/Select/Select";
import AmountWithCurrencyInput from "../Common/AmountWithCurrencyInput";

const TransferRequestDetails = (props) => {
  const {
    touched,
    setTouched,
    values,
    setFieldValue,
    errors,
    defaultErrorMessageProps,
    balanceLoading,
    currentAccountId,
    minimumAmount,
    exchangeRateValue,
    setExchangeRateValue,
    satsAmount,
    setSatsAmount,
  } = props;
  const targetCurrency = values.transfer_via;
  const isSATSPayVia = targetCurrency === sats;
  const isSATSCurrency = values.currency?.code === sats;

  const [noAccountFound, setNoAccountFound] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [exchangeRateLoader, setExchangeRateLoader] = useState(false);

  const { user, currentAccount } = useSelector((state) => state.auth);
  const accountAsset = useSelector((state) => state.common.accountAsset);

  const destinationAccountList = user?.accounts_details?.filter(
    (accountData) => accountData.account?.id !== currentAccount?.account?.id
  );

  const autoCompleteStyle = {
    dropDownSx: {
      "&.auto-complete .MuiAutocomplete-root .MuiFormControl-root": {
        height: "42px",

        ".MuiAutocomplete-input": {
          paddingLeft: "0px",
        },
      },
    },
    optionMainBoxSx: {
      margin: "0px !important",
      padding: "10px !important",
      backgroundColor: "#FFF !important",
      maxHeight: "42px !important",
      display: "flex",
      alignItems: "center !important",
      justifyContent: "space-between !important",
      ":hover": {
        bgcolor: "#f1f4f6 !important",
      },
    },
    accountNameTextSx: {
      fontFamily: "Inter-SemiBold",
      fontSize: "14px !important",
    },
  };

  const findExistedAssociatedAccount = (destinationAccount) =>
    destinationAccountList?.filter(
      (accounts) =>
        accounts.account?.id === destinationAccount ||
        accounts.account?.name === destinationAccount
    )?.[0];

  const setDestinationFormikValue = (
    destinationAccount, // can be accountName ex: "Bussiness Name" or accountNumber/id ex: "acct_l5rsts3bnwIWO08M"
    fromInputChange = false
  ) => {
    const isExistAssociatedAcc =
      findExistedAssociatedAccount(destinationAccount);
    if (isExistAssociatedAcc) {
      setNoAccountFound(false);
      (!fromInputChange ||
        isExistAssociatedAcc?.account?.id !== destinationAccount) &&
        setFieldValue("destination_account", isExistAssociatedAcc?.account?.id);
    } else {
      setFieldValue("destination_account", destinationAccount);
    }
  };

  const getExchangeRate = (currency = sats) => {
    setExchangeRateLoader(true);
    const data = {
      currency,
      target_currency: targetCurrency,
    };
    callAPIInterface("POST", "/utility/exchange-rate", data)
      .then((res) => {
        setExchangeRateLoader(false);
        const exchangeRate = res?.target_lowest_rate;
        setExchangeRateValue(exchangeRate);
      })
      .catch((_e) => setExchangeRateLoader(false));
  };

  const resetToInitialAmountValue = async (currencyObj) => {
    await setFieldValue("amount", "");
    await setFieldValue("transferViaAmount", "");
    await setFieldValue("currency", currencyObj);
  };

  const getTransferViaLabel = (value) => {
    const currency = find(currencyOptions, { value });
    return currency?.label || currencyPlaceholder;
  };

  const handleTransferViaCurrencyChange = (e) => {
    const currency = e.target.value === btc ? sats : e.target.value;
    resetToInitialAmountValue(getCurrencyObj(currency));
    setFieldValue("transfer_via", currency);
  };

  const handleCurrencyOnChange = (currencyObj) => {
    resetToInitialAmountValue(currencyObj);
    setSatsAmount(0);
    targetCurrency !== currencyObj?.code && getExchangeRate(currencyObj?.code);
  };

  const getAmountValueInSATS = (amount) => {
    const convertedAmount = isSATSCurrency
      ? parseFloat(amount)
      : Math.floor(exchangeRateValue * amount); // Fiat to SATs

    return values.currency?.code === "BTC"
      ? btcToSatsAmount(amount)
      : convertedAmount;
  };

  const handleAmountChange = (enteredAmount) => {
    let satsOfEnteredAmount;

    if (isSATSPayVia) {
      satsOfEnteredAmount = getAmountValueInSATS(enteredAmount);
      setSatsAmount(satsOfEnteredAmount);
    }
    const transferViaAmount = calculateAmountInPayViacurrency({
      enteredAmount,
      satsAmountValue: satsOfEnteredAmount,
    });

    setTouched({ ...touched, transferViaAmount: false });
    setFieldValue("amount", enteredAmount);
    setFieldValue("transferViaAmount", transferViaAmount);
  };

  const amountToPayViaCurrencyAmount = (amount, exRate) => {
    const payViaCurrencyAmount = showAmount({
      amount,
      currency: targetCurrency,
      targetedCurrency: targetCurrency === sats ? btc : targetCurrency,
      multiplier: exRate,
    });
    return Number(payViaCurrencyAmount);
  };

  const calculateAmountInPayViacurrency = ({
    satsAmountValue = satsAmount,
    enteredAmount = values.amount,
  }) => {
    const convertedAmount =
      values.currency?.code === targetCurrency
        ? enteredAmount
        : amountToPayViaCurrencyAmount(enteredAmount, exchangeRateValue);
    return Number(isSATSPayVia ? satsAmountValue : convertedAmount);
  };

  useEffect(() => {
    const currencyList = accountAsset?.map((assetData) => ({
      label: assetData.name,
      value: assetData.uuid,
    }));
    setCurrencyOptions(currencyList);
  }, []);

  useEffect(() => {
    //To hide the no option dropdown When there is no account found from search result
    const noDestinationAccFound = document.querySelector(
      "#create-transfer-destination-account .MuiAutocomplete-paper"
    );
    noDestinationAccFound &&
      setNoAccountFound(!noDestinationAccFound.childNodes?.length);
  }, [values]);

  useEffect(() => {
    !destinationAccountList?.length && setNoAccountFound(true);
  }, []);

  const disableCurrencyInput = values?.balance
    ? values.balance < minimumAmount
    : true;

  const amountInPayViacurrency = calculateAmountInPayViacurrency({}) || 0;

  const inputTextPayViaCurrencySection = exchangeRateLoader ? (
    <Skeleton width={50} height={8} />
  ) : (
    <>
      ≈{amountInPayViacurrency} {targetCurrency}
    </>
  );

  const inputProps = {
    values,
    isOptionEqualToValue: (option, value) => option.code === value.code,
    showPopper: true,
    popperInfoMsg: payoutAmountInfoMessage,
    currencyName: "currency",
    amountName: "amount",
    minimumAmountLabel: (
      <Label>
        {minimumAmountLabel("transfer", minimumAmount, targetCurrency)}
      </Label>
    ),
    disableAmountInput: disableCurrencyInput || exchangeRateLoader,
    amountError: errors.transferViaAmount && Boolean(errors.transferViaAmount),
    inputEndAmountTextVisible: values.currency?.code !== targetCurrency,
    inputEndAmountText: inputTextPayViaCurrencySection,
    onBlurInput: () => setTouched({ ...touched, transferViaAmount: true }),
    handleCurrencyOnChange: (value) => handleCurrencyOnChange(value),
    handleInputChange: (value) => handleAmountChange(Number(value)),
  };

  return (
    <Box className="margin-top30" display="flex" justifyContent="center">
      <Box>
        <Text className="default-text" size={20} variant="h2">
          {transferDetailsText}
        </Text>
        <Box width={600} className="margin-top30">
          <CustomSelect
            disabled={!currencyOptions?.length}
            name="transfer_via"
            displayEmpty
            showLabel
            label={transferViaLabel}
            customClass="margin-top30"
            value={targetCurrency}
            placeholder={currencyPlaceholder}
            MenuProps={{
              id: "currency-select",
            }}
            onChange={handleTransferViaCurrencyChange}
            renderValue={getTransferViaLabel}
          >
            {currencyOptions?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </CustomSelect>
          <Input
            type="text"
            disabled
            value={currentAccountId}
            customClass="margin-top30"
            showLabel
            label={fromAccount}
            fullWidth
          />
        </Box>
        <Box width={600} className="margin-top30">
          <CustomAutoComplete
            showLabel
            freeSolo
            disableClearable
            name="destination_account"
            label={destinationAccount}
            placeholder={selectAccount}
            sx={autoCompleteStyle.dropDownSx}
            value={values?.destination_account}
            options={destinationAccountList?.map(
              (option) => option?.account?.id
            )}
            filterOptions={createFilterOptions({
              stringify: (id) =>
                `${getAccNameFromId(id, true, destinationAccountList)} ${id}`, //searchable for both acc name and id
            })}
            disabled={values?.balance ? values.balance < minimumAmount : true}
            isOptionEqualToValue={(option, value) => option === value}
            getOptionLabel={(option) => {
              return (
                getAccNameFromId(
                  option || values?.destination_account,
                  true,
                  destinationAccountList
                ) || ""
              );
            }}
            componentsProps={{
              popper: {
                id: "create-transfer-destination-account",
                sx: noAccountFound && {
                  display: "none",
                },
              },
            }}
            onInputChange={(_e, value) => {
              setDestinationFormikValue(value, true);
            }}
            onBlur={(event) => {
              setTouched({ ...touched, destination_account: true });
              setDestinationFormikValue(event.target.value);
            }}
            onChange={(_e, value) => {
              setTouched({ ...touched, destination_account: false });
              setFieldValue("destination_account", value);
            }}
            renderOption={(optionProps, option) => {
              return (
                <Box
                  {...optionProps}
                  key={option}
                  sx={autoCompleteStyle.optionMainBoxSx}
                >
                  <TruncatedTextTooltip
                    textValue={`${getAccNameFromId(
                      option,
                      true,
                      destinationAccountList
                    )}`}
                    cellWidth="350px"
                    textProps={{
                      sx: autoCompleteStyle.accountNameTextSx,
                    }}
                  />
                  <Box>
                    <Text className="grey-text" size={14} variant="body1">
                      {option}
                    </Text>
                  </Box>
                </Box>
              );
            }}
          />
          <InputErrorMessage
            {...defaultErrorMessageProps}
            inputName="destination_account"
          />
        </Box>
        <>
          <AmountWithCurrencyInput {...inputProps} />
          <InputErrorMessage
            {...defaultErrorMessageProps}
            inputName="transferViaAmount"
          />
        </>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          width={600}
        >
          <Text
            sx={{
              mt: "15px",
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              width: "75%",
            }}
            className="grey-text"
            size={14}
            variant="body1"
          >
            {balanceAvailableForModule("transfer")}
            <Text className="default-text" size={14} variant="caption">
              {balanceLoading ? (
                <Skeleton width={100} />
              ) : (
                `${fetchPrecisedInteger(values?.balance, 8)} ${targetCurrency}`
              )}
            </Text>
          </Text>
        </Box>
        <Box width={600} className="margin-top30">
          <TextAreaComponent
            showLabel
            label={description}
            maxLength={255}
            minRows={3}
            maxRows={3}
            name="description"
            placeholder={addDescription}
            fullWidth
            value={values.description}
            onChange={(e) => {
              setTouched({ ...touched, description: false });
              setFieldValue("description", e.target.value);
            }}
            onBlur={() => setTouched({ ...touched, description: true })}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TransferRequestDetails;
