import {
  Box,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import React, { useEffect, useMemo, useState } from "react";
import {
  confirmationCount,
  currencyLabel,
  date,
  details,
  ethereum,
  failed,
  gasFeeMsg,
  gasFees,
  invoice,
  lightningNetworkFeeInfo,
  maxSettlementTime,
  method,
  networkFeeInfo,
  networkFees,
  note,
  paidTo,
  payment,
  paymentPreimage,
  pending,
  routingFeeInfo,
  routingFeeText,
  sats,
  settlementFeeInfo,
  speedFee,
  status,
  transactionId,
  tron,
  unpaid,
  viewInEtherscan,
  viewInMemepool,
  viewInTronscan,
  withdraw,
} from "../messages";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import {
  getDisplayAmount,
  removeTrailingZeros,
  transactionStatus,
} from "@speed/common/src/components/constants";
import { useSelector } from "react-redux";
import {
  address,
  amount,
  lightningLabel,
  onChainLabel,
} from "@speed/common/src/components/messages";
import { bitCoinSymbol, fullScreenBlue, tronIcon, usdtSymbol } from "../images";
import {
  callAPIInterface,
  clickableTxnType,
  dateTimeFormatInApp,
  getCurrencyForSatsAndBtc,
  getExchangeRate,
  getPaidPaymentMethod,
  getShowAmountBtcToSats,
  getWithdrawPaymentMethod,
} from "../constants";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import ErrorIcon from "@mui/icons-material/Error";
import moment from "moment-timezone";
import { fill } from "@speed/common/src/components/images";

const TransactionJson = ({ tab }) => {
  const walletSelectedTransaction = useSelector(
    (state) => state.common.walletSelectedTransaction
  );
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const walletDefaultCurrency = JSON.parse(
    localStorage.getItem("account_default_currency")
  );
  const targetedCurrency = walletDefaultCurrency?.code;
  const targetedCurrencySymbol = walletDefaultCurrency?.symbol;
  const isWithdraw = walletSelectedTransaction?.type === withdraw;
  const walletTargetedCurrency = walletSelectedTransaction?.target_currency;

  const [isJsonLoading, setIsJsonLoading] = useState(false);
  const [paymentDetailData, setPaymentDetailData] = useState();
  const [withdrawDetailData, setWithdrawDetailData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);
  const [settlementTime, setSettlementTime] = useState(null);
  const [exchangeRate, setExchangeRate] = useState({});
  const open = Boolean(anchorEl);
  const openNetwork = Boolean(anchorEl2);
  const openSettlement = Boolean(anchorEl3);

  const handleClick = (event) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);

  const handleClickNetwork = (event) =>
    setAnchorEl2(anchorEl2 ? null : event.currentTarget);

  const handleClickSettlement = (event) =>
    setAnchorEl3(anchorEl3 ? null : event.currentTarget);

  useEffect(() => {
    if (
      withdrawDetailData?.withdraw_method === lightningLabel.toLowerCase() &&
      tab === pending
    ) {
      setSettlementTime(null);
      handleDecode(withdrawDetailData?.withdraw_request);
    }
  }, [withdrawDetailData]);

  useEffect(() => {
    setPaymentDetailData(null);
    setWithdrawDetailData(null);

    if (walletSelectedTransaction) {
      getTxnDetail(isWithdraw ? withdraw : payment);
    }
  }, [walletSelectedTransaction]);

  const handleDecode = async (address) => {
    try {
      const response = await callAPIInterface("POST", "/withdraws/decode", {
        withdraw_request: address,
      });
      if (response?.expires_at) {
        const expireTime = response.expires_at * 1000;
        const time = new Date().getTime();
        setSettlementTime(expireTime > time ? expireTime : null);
      }
    } catch (err) {}
  };

  const getTransactonStatusInfo = useMemo(() => {
    let status = "";
    if (
      withdrawDetailData?.status === failed.toLowerCase() ||
      paymentDetailData?.status === failed.toLowerCase()
    ) {
      status = failed.toLowerCase();
    } else {
      status = tab || "complete";
    }
    return { status };
  }, [withdrawDetailData, paymentDetailData]);

  const getTxnDetail = async (type) => {
    setIsJsonLoading(true);
    let url = "";
    if (type === payment) {
      url = "/payments";
    } else if (type === withdraw) {
      url = "/withdraws";
    }
    await callAPIInterface(
      "GET",
      `${url}/${
        walletSelectedTransaction?.source || walletSelectedTransaction?.id
      }`
    )
      .then((res) => {
        if (res) {
          if (type === payment) {
            setPaymentDetailData(res);
          } else if (type === withdraw) {
            setWithdrawDetailData(res);
          }
          fetchExchangeRate(res?.target_currency);
        }
        setIsJsonLoading(false);
      })
      .catch(() => {
        setIsJsonLoading(false);
      });
  };

  const fetchExchangeRate = (currency) => {
    getExchangeRate(targetedCurrency, currency)
      .then((res) => {
        setExchangeRate(res);
      })
      .catch((_e) => {});
  };

  const verticalTableLoader = () => (
    <Box className="vertical-table-wrapper">
      <Table sx={{ display: "flex", justifyContent: "space-between" }}>
        <TableBody>
          {Array(2)
            .fill()
            .map((item) => (
              <TableRow key={item}>
                <TableCell variant="head">
                  <Skeleton
                    animation="wave"
                    width={100}
                    height={15}
                    className="get-started-loader"
                  />
                </TableCell>
                <TableCell>
                  <Skeleton
                    animation="wave"
                    width={190}
                    height={15}
                    className="get-started-loader"
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );

  if (isJsonLoading) {
    return (
      <Grid item xs={5.49} height="100%" overflow="auto" position="relative">
        <Box display="flex" flexDirection="column" p="36px">
          <Skeleton
            animation="wave"
            width="50%"
            sx={{
              bgcolor: "#eaeef1 !important",
              marginBottom: "24px",
            }}
          />
          {verticalTableLoader()}
        </Box>
      </Grid>
    );
  }

  const getMethodType = (module) => {
    const moduleUppercase = module.charAt(0).toUpperCase() + module.slice(1);
    let method = "";
    switch (moduleUppercase) {
      case payment:
        method = getPaidPaymentMethod(paymentDetailData);
        break;
      case withdraw:
        method = getWithdrawPaymentMethod(withdrawDetailData);
        break;
      default:
        break;
    }

    return method;
  };

  const getMempoolLink = (module) => {
    let link = "";
    switch (module) {
      case payment:
        link = paymentDetailData?.on_chain_tx_explorer_link;
        break;
      case withdraw:
        link = withdrawDetailData?.on_chain_tx_explorer_link;
        break;
      default:
        break;
    }

    return link;
  };

  const getNote = (module) => {
    let note;
    switch (module) {
      case payment:
        note = paymentDetailData?.statement_descriptor;
        break;
      case withdraw:
        note = withdrawDetailData?.statement_descriptor;
        break;
      default:
        note = "";
    }

    return note || "";
  };

  const getSpeedFeeAmount = (module) => {
    let speedFee;
    switch (module) {
      case payment:
        speedFee = paymentDetailData?.speed_fee?.amount;
        break;
      case withdraw:
        speedFee = withdrawDetailData?.speed_fee?.amount;
        break;
      default:
        speedFee = 0;
    }

    return speedFee;
  };

  const getFeesInfo = (methodType) => {
    let title, info;
    switch (methodType) {
      case lightningLabel:
        title = routingFeeText;
        info = routingFeeInfo;
        break;
      case onChainLabel:
      case tron:
        title = networkFees;
        info = networkFeeInfo;
        break;
      case ethereum:
        title = gasFees;
        info = gasFeeMsg;
        break;
      default:
        break;
    }

    return { title, info };
  };

  const displayAmountInFiatCurrency = (amount) => {
    let displayAmount = amount;
    if (amount) {
      const fiatAmount = isWithdraw
        ? amount / exchangeRate?.target_lowest_rate
        : amount / exchangeRate?.target_highest_rate;

      displayAmount = getDisplayAmount(true, fiatAmount, targetedCurrency);
    }
    return `${targetedCurrencySymbol}${displayAmount}`;
  };

  const showAmountJsx = (amt) => {
    const isSats = walletTargetedCurrency === sats;
    return (
      <Text className="default-text" font="regular" size={16} variant="body2">
        {isSats
          ? getShowAmountBtcToSats(amt, showBtcAmount, walletTargetedCurrency)
          : removeTrailingZeros(amt / 100, walletTargetedCurrency)}{" "}
        {getCurrencyForSatsAndBtc(walletTargetedCurrency, showBtcAmount)}{" "}
        {`(≈ ${displayAmountInFiatCurrency(isSats ? amt : amt / 100)})`}
      </Text>
    );
  };

  const apiDetailsTableData = () => {
    const transactionJsonData = [];
    const methodType =
      walletSelectedTransaction?.status === "paid"
        ? getPaidPaymentMethod(walletSelectedTransaction)
        : getMethodType(walletSelectedTransaction?.type);
    const mempoolLink = walletSelectedTransaction?.on_chain_tx_explorer_link
      ? walletSelectedTransaction.on_chain_tx_explorer_link
      : getMempoolLink(walletSelectedTransaction?.type);
    const noteValue = walletSelectedTransaction?.statement_descriptor
      ? walletSelectedTransaction?.statement_descriptor
      : getNote(walletSelectedTransaction?.type);

    const getScanLabel = () => {
      let name = "";
      if (methodType === onChainLabel) {
        name = viewInMemepool;
      } else if (methodType === ethereum) {
        name = viewInEtherscan;
      } else if (methodType === tron) {
        name = viewInTronscan;
      }
      return name;
    };

    const getWalletPaymentAddress = (
      methodType,
      walletPaymentMethodOptions
    ) => {
      switch (methodType) {
        case lightningLabel:
          return walletPaymentMethodOptions?.lightning?.payment_request;
        case onChainLabel:
          return walletPaymentMethodOptions?.on_chain?.address;
        case tron:
          return walletPaymentMethodOptions?.tron?.address;
        case ethereum:
          return walletPaymentMethodOptions?.ethereum?.address;
        default:
          return "-";
      }
    };

    const getCurrencyIcon = (value) => {
      switch (value) {
        case lightningLabel:
          return fill;
        case onChainLabel:
          return bitCoinSymbol;
        case tron:
          return tronIcon;
        case ethereum:
          return usdtSymbol;
        default:
          return fill;
      }
    };

    transactionJsonData.push({
      header: date,
      cell: (
        <Text className="default-text" font="regular" size={16} variant="body2">
          {dateTimeFormatInApp(walletSelectedTransaction?.created)}
        </Text>
      ),
    });

    transactionJsonData.push({
      header: status,
      cell: (
        <Tag
          text={transactionStatus[getTransactonStatusInfo?.status].label}
          variant={transactionStatus[getTransactonStatusInfo?.status].variant}
          deleteIcon={transactionStatus[getTransactonStatusInfo?.status].icon}
          onDelete={() => {}}
          className="wallet-pending-tag"
        />
      ),
    });

    if (settlementTime) {
      transactionJsonData.push({
        header: (
          <Box display="flex" alignItems="center">
            {maxSettlementTime}
            <ErrorIcon
              aria-describedby={"settlement-fee"}
              sx={{
                height: "18px",
                width: "18px",
                color: "#A4A9B7 !important",
                marginLeft: "4px",
                cursor: "pointer",
              }}
              onClick={(e) => handleClickSettlement(e)}
            />
            <CustomPopper
              id="settlement-fee"
              disablePortal={true}
              open={openSettlement}
              anchorEl={anchorEl3}
              position="top"
              handleClose={() => setAnchorEl3(null)}
              sx={{ width: "332px" }}
            >
              <Text variant="inherit" font="regular" size={16}>
                {settlementFeeInfo}
              </Text>
            </CustomPopper>
          </Box>
        ),
        cell: (
          <Text size={16} variant="body1" font="regular">
            {moment(settlementTime).fromNow(true)}
          </Text>
        ),
      });
    }

    transactionJsonData.push({
      header: currencyLabel,
      cell: (
        <Text
          className="default-text"
          font="regular"
          size={16}
          variant="body2"
          withIcon="start"
        >
          {getCurrencyForSatsAndBtc(walletTargetedCurrency, showBtcAmount)}
        </Text>
      ),
    });

    transactionJsonData.push({
      header: method,
      cell: (
        <Box display="flex" alignItems="center">
          <img
            width="24px"
            height="24px"
            style={{ marginRight: "8px" }}
            src={getCurrencyIcon(methodType)}
            alt="currency-icon"
          />
          <Text
            className="default-text"
            font="regular"
            size={16}
            variant="body2"
          >
            {methodType || "-"}
          </Text>
        </Box>
      ),
    });

    transactionJsonData.push({
      header: amount,
      cell: showAmountJsx(walletSelectedTransaction?.amount),
    });

    transactionJsonData.push({
      header: (
        <Box display="flex" alignItems="center">
          {speedFee}
          <ErrorIcon
            aria-describedby={"account-fee"}
            sx={{
              height: "18px",
              width: "18px",
              color: "#A4A9B7 !important",
              marginLeft: "4px",
              cursor: "pointer",
            }}
            onClick={(e) => handleClick(e)}
          />
          <CustomPopper
            id="account-fee"
            disablePortal={true}
            open={open}
            anchorEl={anchorEl}
            position="top"
            handleClose={() => setAnchorEl(null)}
            sx={{ width: "332px" }}
          >
            <Text variant="inherit" font="regular" size={16}>
              {lightningNetworkFeeInfo}
            </Text>
          </CustomPopper>
        </Box>
      ),
      cell: showAmountJsx(getSpeedFeeAmount(walletSelectedTransaction?.type)),
    });

    if (walletSelectedTransaction?.type === payment) {
      transactionJsonData.push({
        header: confirmationCount,
        cell: (
          <Text
            className="default-text"
            font="regular"
            size={16}
            variant="body2"
          >
            {paymentDetailData?.confirmations
              ? `${paymentDetailData.confirmations}+`
              : 0}
          </Text>
        ),
      });
    }

    if (isWithdraw) {
      const feesInfo = getFeesInfo(methodType);
      transactionJsonData.push({
        header: (
          <Box display="flex" alignItems="center">
            {feesInfo.title}
            <ErrorIcon
              aria-describedby={"network-fee"}
              sx={{
                height: "18px",
                width: "18px",
                color: "#A4A9B7 !important",
                marginLeft: "4px",
                cursor: "pointer",
              }}
              onClick={(e) => handleClickNetwork(e)}
            />
            <CustomPopper
              id="gas-fee"
              disablePortal={true}
              open={openNetwork}
              anchorEl={anchorEl2}
              position="top"
              handleClose={() => setAnchorEl2(null)}
              sx={{ width: "332px" }}
            >
              <Text variant="inherit" font="regular" size={16}>
                {feesInfo.info}
              </Text>
            </CustomPopper>
          </Box>
        ),
        cell: showAmountJsx(withdrawDetailData?.est_fees),
      });
    }

    if (isWithdraw && withdrawDetailData?.ln_pay) {
      transactionJsonData.push({
        header: paidTo,
        cell: (
          <Text
            className="default-text"
            font="regular"
            size={16}
            variant="body2"
          >
            {withdrawDetailData.ln_pay?.payee}
          </Text>
        ),
      });
    }

    if (noteValue.length) {
      transactionJsonData.push({
        header: note,
        cell: (
          <Text
            className="default-text"
            font="regular"
            size={16}
            variant="body2"
          >
            {noteValue}
          </Text>
        ),
      });
    }

    if (
      walletSelectedTransaction?.status !== unpaid &&
      clickableTxnType.includes(walletSelectedTransaction?.type)
    ) {
      let methodValue;
      const method = methodType === lightningLabel ? invoice : address;
      if (isWithdraw) {
        methodValue = withdrawDetailData?.withdraw_request;
      } else {
        const walletPaymentMethodOptions =
          walletSelectedTransaction?.payment_method_options ||
          paymentDetailData?.payment_method_options;
        methodValue = getWalletPaymentAddress(
          methodType,
          walletPaymentMethodOptions
        );
      }

      transactionJsonData.push({
        header: method,
        cell: (
          <Box display="flex" alignItems="center">
            <Text
              size={16}
              font="regular"
              variant="subtitle1"
              className="default-text"
              sx={{ pr: "10px" }}
            >
              {methodValue}
            </Text>
            <Clipboard text={methodValue} />
          </Box>
        ),
      });
    }

    if (isWithdraw && withdrawDetailData?.payment_preimage) {
      transactionJsonData.push({
        header: paymentPreimage,
        cell: (
          <Box className="invoice-clipboard" display="flex" alignItems="center">
            <Text
              size={16}
              font="regular"
              variant="subtitle1"
              className="default-text"
              sx={{ pr: "10px" }}
            >
              {withdrawDetailData.payment_preimage}
            </Text>
            <Clipboard text={withdrawDetailData.payment_preimage} />
          </Box>
        ),
      });
    }

    if (
      tab !== "pending" &&
      clickableTxnType.includes(walletSelectedTransaction?.type)
    ) {
      transactionJsonData.push({
        header: transactionId,
        cell: (
          <Box display="flex" alignItems="center" className="pointer-cursor">
            {walletSelectedTransaction?.id}
          </Box>
        ),
      });
    }

    transactionJsonData.push({
      header: "Id",
      cell: (
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center">
            <Text
              size={16}
              font="regular"
              variant="subtitle1"
              className="default-text"
              sx={{ pr: "10px" }}
            >
              {isWithdraw ? withdrawDetailData?.id : paymentDetailData?.id}
            </Text>
            <Clipboard
              text={isWithdraw ? withdrawDetailData?.id : paymentDetailData?.id}
            />
          </Box>
          {mempoolLink && (
            <Box
              mt="8px"
              display="flex"
              alignItems="center"
              className="pointer-cursor"
              onClick={() => {
                window.open(mempoolLink, "_blank", "noopener");
              }}
            >
              <Text
                size={14}
                font="semibold"
                variant="subtitle1"
                className="default-text"
                style={{ color: "#2A67FF" }}
              >
                {getScanLabel()}
              </Text>
              <img
                src={fullScreenBlue}
                style={{ height: "18px", width: "18px", marginLeft: "10px" }}
                alt="Screen-Enlarge"
              />
            </Box>
          )}
        </Box>
      ),
    });

    return transactionJsonData;
  };

  return (
    <Grid
      className="vertical-table-grid"
      item
      xs={5.49}
      bgcolor="#f7fafc"
      position="relative"
    >
      <Box display="flex" flexDirection="column" p="40px">
        <VerticalTable
          label={details}
          rowData={apiDetailsTableData()}
          className="logs-vertical-data transaction-vertical-data"
        />
      </Box>
    </Grid>
  );
};

export default TransactionJson;
