import { CustomAutoComplete } from "./AutoComplete/AutoComplete";
import { validateEmail } from "./constants";
import classNames from "classnames";

const MultiValueInput = ({
  options,
  customClass,
  inputName,
  formikValue,
  inputPlaceholder,
  inputLabel,
  type,
}) => {
  const { values, setFieldValue } = formikValue;

  const inputBoxSx = {
    "&.auto-complete .MuiAutocomplete-root .MuiFormControl-root .MuiInputBase-root":
      options
        ? { height: "auto" }
        : {
            height: "auto",
            overflow: "auto",
            maxHeight: "190px",
            padding: "0px 5px",
          },
  };

  const handleBlurTagInput = (event) => {
    const currentInputValue = event.target.value.trim();
    if (
      !currentInputValue ||
      values[inputName].indexOf(currentInputValue) !== -1
    ) {
      return;
    }
    if (type === "email") {
      if (validateEmail(currentInputValue)) {
        setFieldValue(inputName, [...values[inputName], currentInputValue]);
      }
      return;
    }
    setFieldValue(inputName, [...values[inputName], currentInputValue]);
  };

  const handleOnchangeTagInput = (event, inputs, reason) => {
    const currentInputValue = event?.target?.value?.trim();
    if (["createOption", "removeOption"].includes(reason)) {
      if (reason === "createOption" && !currentInputValue) return;
      if (type === "email" && reason === "createOption") {
        if (validateEmail(currentInputValue)) {
          setFieldValue(inputName, inputs);
        }
        return;
      }
      setFieldValue(inputName, inputs);
    } else if (reason === "clear") {
      setFieldValue(inputName, []);
    }
  };

  const handleChangeWhenOptionAvailable = (_event, inputs) => {
    const selectedOptions = inputs?.map((option) => option.value);
    setFieldValue(inputName, selectedOptions);
  };

  const commonProps = {
    multiple: true,
    disableClearable: true,
    name: inputName,
    customClass: classNames(customClass, "margin-top15"),
    showLabel: true,
    label: inputLabel,
    sx: inputBoxSx,
  };

  const extraProps = options
    ? {
        options,
        onChange: handleChangeWhenOptionAvailable,
      }
    : {
        freeSolo: true,
        clearOnBlur: true,
        open: false,
        value: values[inputName],
        onBlur: handleBlurTagInput,
        onChange: handleOnchangeTagInput,
      };
  return (
    <CustomAutoComplete
      {...commonProps}
      {...extraProps}
      {...(!values[inputName]?.length && {
        placeholder: inputPlaceholder,
      })}
    />
  );
};

export default MultiValueInput;
