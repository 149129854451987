import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getCurrentTimeInSecond } from "../constants";

const ClockTimer = ({
  previewColor,
  handleClick,
  timerColor,
  expiresAt,
  height = "15px",
  width = "15px",
  strokeWidth = 50,
  clockResetTimeInSec = 600,
  counterClockwise = true,
}) => {
  const getPercent = (expiresAt) => {
    const currentSeconds = getCurrentTimeInSecond(expiresAt);
    return (currentSeconds / clockResetTimeInSec) * 100;
  };

  const [percentage, setPercentage] = useState(100);

  useEffect(() => {
    const timer = setInterval(() => {
      setPercentage(getPercent(expiresAt));
    }, 1000);

    return () => clearInterval(timer);
  }, [expiresAt]);

  return (
    <CircularProgressbar
      onClick={handleClick}
      value={percentage}
      strokeWidth={strokeWidth}
      counterClockwise={counterClockwise}
      styles={{
        root: {
          height,
          width,
          border: `1px solid ${timerColor}`,
          borderRadius: "51%",
          marginRight: "2px",
        },
        path: {
          stroke: previewColor,
          strokeLinecap: "butt",
        },
        trail: {
          stroke: timerColor,
          strokeLinecap: "butt",
          transform: "rotate(0.25turn)",
          transformOrigin: "center center",
        },
      }}
    />
  );
};

export default ClockTimer;
