import React, { useState } from "react";
import { Drawer, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import CustomAccordion from "@speed/common/src/components/Accordion/Accordion";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Button from "@speed/common/src/components/Button/Button";
import {
  chatText,
  contactSuccessManager,
  contactSupportTeam,
  emailText,
  liveChat,
  shareSuggestionMsg,
} from "../messages";
import { openIntercomChatModal } from "@speed/common/src/components/constants";

const SupportCenterDrawer = ({
  openSupportCenterDrawer,
  closeSupportCenterDrawer,
}) => {
  const handleChat = () => {
    handleCloseDrawer();
    openIntercomChatModal();
  };

  const getCommonSupportElement = (
    showDivider,
    boxClassName,
    isMarginLeft,
    icon,
    supportText,
    supportContent
  ) => {
    const textProps = {
      className: "grey-text",
      size: 16,
      variant: "h3",
      font: "regular",
      sx: { fontFamily: "Inter-Medium" },
    };

    if (isMarginLeft) textProps.sx = { ...textProps.sx, marginLeft: "12px" };

    return (
      <>
        {showDivider && <CustomDivider />}
        <Box className={boxClassName}>
          {icon}
          <Text {...textProps}>{supportText}</Text>
          {supportContent}
        </Box>
      </>
    );
  };

  const supportTeamBody = (
    <>
      <CustomDivider />
      <Box className="support-team-box">
        {getCommonSupportElement(
          false,
          "support-details",
          true,
          <ChatBubbleOutlineIcon />,
          chatText,
          <Button
            className="support-chat-btn"
            label={liveChat}
            icon="chatIcon"
            onClick={handleChat}
            variant="default"
          />
        )}
      </Box>
    </>
  );

  const supportManagerDetail = {
    name: "Jay Patel",
    email: "jayneel@tryspeed.com",
    bio: "Jay Patel, the CEO of Speed, takes an active role in customer support, working as a first-level support representative. As a startup entrepreneur, he prioritizes staying customer-facing to help users set up Speed, gather feedback, and gain insights from their experiences.",
  };

  const supportSuccessManagerBody = (
    <>
      <CustomDivider />
      <Box className="success-manager-box">
        <Text className="default-text name-text" size={16}>
          {supportManagerDetail.name}
        </Text>
        <Text
          size={14}
          variant="h3"
          font="regular"
          className="grey-text bio-text"
        >
          {supportManagerDetail.bio}
        </Text>
        {getCommonSupportElement(
          true,
          "success-manager-details",
          null,
          null,
          emailText,
          <Box sx={{ marginLeft: "auto" }}>
            <Text size={14} variant="h5" className="default-text">
              {supportManagerDetail.email}
            </Text>
          </Box>
        )}
      </Box>
    </>
  );

  const supportAccordian = [
    {
      sr: 1,
      headerTitle: contactSupportTeam,
      expanded: true,
      body: supportTeamBody,
    },
    {
      sr: 2,
      headerTitle: contactSuccessManager,
      expanded: true,
      body: supportSuccessManagerBody,
    },
  ];

  const [accordianData, setAccordianData] = useState(supportAccordian);

  const handleCloseDrawer = () => {
    closeSupportCenterDrawer(false);
    setAccordianData(supportAccordian);
  };

  const handleChange = (accordianItem) => (_e) => {
    setAccordianData(
      [...accordianData].map((item) => {
        if (accordianItem.sr === item.sr) {
          return {
            ...item,
            expanded: !item.expanded,
          };
        } else return item;
      })
    );
  };

  const drawerHeader = () => {
    return (
      <Box className="drawer-header">
        <Text size={18} variant="h5" className="default-text">
          {contactSupportTeam}
        </Text>
        <Box
          height={20}
          width={20}
          onClick={handleCloseDrawer}
          component="span"
          className="pointer-cursor"
        >
          <CloseIcon sx={{ height: 20, width: 20 }} htmlColor="#848b9e" />
        </Box>
      </Box>
    );
  };

  const drawerBodyContent = () => (
    <Box className="support-center-body-content">
      {accordianData.map((accordianItem) => {
        return (
          <CustomAccordion
            className="support-center-accordian"
            isTitleShown={true}
            key={accordianItem.sr}
            expandIcon={accordianItem.expanded ? "removeIcon" : "addIcon"}
            onChange={() => handleChange(accordianItem)}
            expanded={accordianItem.expanded}
            accordianPanel={accordianItem}
            title={accordianItem.headerTitle}
            customComponent={accordianItem.body}
          />
        );
      })}

      <Box
        component="a"
        className="share-suggestion-box"
        href="https://www.tryspeed.com/contact-us"
        target="_blank"
      >
        <Box className="suggestion-text-box">
          <Text
            size={16}
            variant="h5"
            className="default-text share-suggestion-text"
          >
            {shareSuggestionMsg[0]}
          </Text>

          <Text
            className="grey-text share-suggestion-subtext"
            size={14}
            variant="h3"
            font="regular"
          >
            {shareSuggestionMsg[1]}
          </Text>
        </Box>
        <Box className="forward-arrow-icon">
          <ArrowForwardIosIcon />
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      id="support-center-drawer"
      className="support-center-drawer"
      anchor="right"
      open={openSupportCenterDrawer}
      onClose={handleCloseDrawer}
    >
      <Box className="drawer-wrapper" role="presentation">
        {drawerHeader()}
        <CustomDivider />
        {drawerBodyContent()}
      </Box>
    </Drawer>
  );
};

export default SupportCenterDrawer;
