export const currencyForSwap = [
  {
    name: "Satoshi",
    code: "SATS",
    symbol: (
      <span
        style={{
          fontFamily: "Satoshi-Symbol",
          height: "24px",
          paddingTop: "1.5px",
        }}
      >
        !
      </span>
    ),
  },
  { name: "USDT", code: "USDT", symbol: "₮" },
];
