import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import React, { forwardRef, useEffect, useState } from "react";
import {
  ethereumLabel,
  lightning,
  on_chain,
  onchain,
  paid,
  receive,
  sats,
  tronLabel,
} from "../../messages";
import { getDisplayAmount } from "@speed/common/src/components/constants";
import ReceivePayment from "./ReceivePayment";
import {
  callAPIInterface,
  generateAmountToPass,
  getExchangeRate,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { app } from "@speed/common/src/util/firebase";
import history from "@speed/common/src/components/history";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getAccountFirebaseToken } from "../../../redux/auth/actions";
import EnterCustomAmount from "./../EnterCustomAmount";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { setPaymentProcessingRecallApis } from "../../../redux/common/actions";
import { fill } from "@speed/common/src/components/images";
import {
  bitCoinSymbol,
  tronIcon,
  usdtLightning,
  usdtSymbol,
} from "../../images";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let unsubscribe;
const ReceiveModal = ({
  openReceiveModal,
  setOpenReceiveModal,
  selectedCurrencyType,
  accountAssets,
}) => {
  const currency = selectedCurrencyType?.uuid || sats;
  const walletDefaultCurrency = JSON.parse(
    localStorage.getItem("account_default_currency")
  );
  const targetedCurrency = walletDefaultCurrency?.code;
  const paymentMethodDetails =
    accountAssets?.find((asset) => asset?.uuid === currency)
      ?.payment_method_details || [];

  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const dispatch = useDispatch();

  const [amount, setAmount] = useState(null);
  const [fiatAmount, setFiatAmount] = useState(null);
  const [isEnterCustomAmount, setIsEnterCustomAmount] = useState(false);
  const [note, setNote] = useState("");
  const [receiveResponse, setReceiveResponse] = useState({});
  const [exchangeRate, setExchangeRate] = useState(1);
  const [exchangeRateLoading, setExchangeRateLoading] = useState(false);
  const [switchCurrency, setSwitchCurrency] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState("1");
  const [isNoteClicked, setIsNoteClicked] = useState(false);
  const [isAmountClicked, setIsAmountClicked] = useState(false);
  const [generatedPaymentMethodArr, setGeneratedPaymentMethodArr] = useState(
    []
  );
  const [selectedMethodName, setSelectedMethodName] = useState();
  const [selectedTab, setSelectedTab] = useState(1);
  const [paymentId, setPaymentId] = useState(null);
  const [statementDesc, setStatementDesc] = useState(null);

  const taprootCondition =
    currency === sats || (currency !== sats && receiveResponse?.amount > 0);
  const showEnterCustomAmount =
    (currency !== sats && !receiveResponse?.amount) ||
    (isEnterCustomAmount && step === "2");

  useEffect(() => {
    if (openReceiveModal) {
      fetchExchangeRate();
      if (currency === sats) fetchPayment();
      else {
        setIsEnterCustomAmount(true);
        setIsAmountClicked(true);
      }
    }
  }, [openReceiveModal]);

  const fetchExchangeRate = () => {
    setExchangeRateLoading(true);
    getExchangeRate(targetedCurrency ?? "USD", currency)
      .then((res) => {
        setExchangeRate(res?.target_highest_rate);
        setExchangeRateLoading(false);
      })
      .catch((_error) => {
        setExchangeRateLoading(false);
      });
  };

  const getPaymentFirestoreData = (paymentId) => {
    const db = getFirestore(app);
    const userRef = doc(
      db,
      "account",
      currentAccount?.account?.id,
      "payments",
      paymentId
    );
    try {
      unsubscribe = onSnapshot(userRef, (querySnapshot) => {
        const data = querySnapshot.data();
        if (data && data.status.toLowerCase() === paid.toLowerCase()) {
          history.push({
            pathname: "/success-page",
            data: {
              paymentData: data,
              fromReceived: true,
              target_currency: currency,
            },
          });
          unsubscribe();
        }
      });
    } catch (err) {}
  };

  const getPaymentMethodIcon = (type) => {
    switch (type) {
      case lightning:
        return selectedCurrencyType?.uuid === sats ? fill : usdtLightning;
      case on_chain:
        return bitCoinSymbol;
      case ethereumLabel:
        return usdtSymbol;
      case tronLabel:
        return tronIcon;
      default:
        return bitCoinSymbol;
    }
  };

  const getPaymentMethodWeight = (method) => {
    const targetMethod = method === on_chain ? onchain : method;

    return paymentMethodDetails?.find(
      (pm) => pm?.payment_method_name === targetMethod
    )?.weight;
  };

  const generatePaymentMethodsObj = (methodObj) => {
    const paymentMethodsArr = Object.keys(methodObj)
      .filter((key) => methodObj[key] !== null)
      .map((paymentMethod) => {
        return {
          payment_method: paymentMethod,
          weight: getPaymentMethodWeight(paymentMethod),
        };
      })
      .sort((a, b) => a.weight - b.weight);

    setSelectedMethodName(paymentMethodsArr?.[0]?.payment_method);

    const methodArr = paymentMethodsArr?.map((method, index) => {
      const paymentMethod = method?.payment_method;
      return {
        tab: index + 1,
        name: paymentMethod,
        icon: getPaymentMethodIcon(paymentMethod),
        address:
          methodObj[paymentMethod]?.address ||
          methodObj[paymentMethod]?.payment_request,
      };
    });

    return methodArr;
  };

  const fetchPayment = async (amount = 0, note = "") => {
    setIsLoading(true);
    const data = {
      amount: generateAmountToPass(amount, currency, showBtcAmount, false),
      currency: currency,
      ttl: 600,
      target_currency: currency,
    };

    note && selectedTab === 1 && (data.statement_descriptor = note);

    if (
      receiveResponse?.amount === data.amount &&
      receiveResponse?.statement_descriptor === data.statement_descriptor
    ) {
      setIsEnterCustomAmount(false);
      setStep("1");
      setIsLoading(false);
    } else {
      try {
        const response = await callAPIInterface(
          "POST",
          "/payments?currency_denomination=higher",
          data
        );
        setPaymentId(response?.id);
        setStatementDesc(response?.statement_descriptor);
        const generatedArr = generatePaymentMethodsObj(
          response?.payment_method_options
        );

        setGeneratedPaymentMethodArr(generatedArr);

        const token = await getAccountFirebaseToken();
        const authObj = getAuth(app);

        if (token?.firebase_token) {
          try {
            await signInWithCustomToken(authObj, token?.firebase_token);
            getPaymentFirestoreData(response?.id);
          } catch (_err) {}
        }
        setReceiveResponse(response);
        setIsEnterCustomAmount(false);
        setStep("1");
      } catch (_err) {
        handleModalClose();
      }
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setSwitchCurrency(false);
    setFiatAmount(null);
    setAmount(null);
    setNote("");
    setIsNoteClicked(false);
    setIsAmountClicked(false);
    dispatch(setPaymentProcessingRecallApis(true));
    setIsEnterCustomAmount(false);
    if (step === "1") {
      setGeneratedPaymentMethodArr([]);
      setSelectedMethodName();
      setPaymentId(null);
      setStatementDesc("");
      setOpenReceiveModal(false);
      setReceiveResponse({});
      setExchangeRateLoading(false);
      setIsLoading(false);
      setSelectedTab(1);
      unsubscribe?.();
    } else {
      setStep("1");
    }
  };

  const displayAmount = (showFiat) => {
    return getDisplayAmount(
      showFiat,
      showFiat ? fiatAmount : amount,
      showFiat ? targetedCurrency : currency,
      showBtcAmount
    );
  };

  const getHeaderTitle = () => {
    if (currency === sats) {
      return `${receive} BTC`;
    }
    return `${receive} ${currency}`;
  };

  const handleReceiveSubmit = (amt, text) => {
    fetchPayment(amt, text);
  };

  const commonProps = {
    switchCurrency,
    amount,
    isLoading,
    selectedMethodName,
  };

  const enterCustomAmountProps = {
    ...commonProps,
    setAmount,
    displayAmount,
    onSubmit: handleReceiveSubmit,
    fiatAmount,
    setFiatAmount,
    note,
    setNote,
    setSwitchCurrency,
    exchangeRateLoading,
    exchangeRate,
    currency,
    isNoteClicked,
    isAmountClicked,
  };

  const receivePaymentProps = {
    ...commonProps,
    setIsEnterCustomAmount,
    receiveResponse,
    selectedCurrencyType,
    setStep,
    setIsNoteClicked,
    setIsAmountClicked,
    generatedPaymentMethodArr,
    setSelectedMethodName,
    paymentId,
    statementDesc,
    setSelectedTab,
    fiatAmount,
    selectedTab,
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openReceiveModal}
      TransitionComponent={Transition}
      className={`fullscreen-modal ${
        step === "2" ? "send-modal-dialog-section" : "receive-modal-dialog"
      }`}
      onClose={handleModalClose}
      sx={{ overflow: "unset" }}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {getHeaderTitle()}
          </Text>
        </Toolbar>
      </AppBar>
      <Box className="receive-modal-container">
        {showEnterCustomAmount ? (
          <EnterCustomAmount {...enterCustomAmountProps} />
        ) : (
          taprootCondition && <ReceivePayment {...receivePaymentProps} />
        )}
      </Box>
    </Dialog>
  );
};

export default ReceiveModal;
