import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import history from "@speed/common/src/components/history";
import * as Sentry from "@sentry/react";
import "@speed/common/src/i18n/config";
import {
  hotJarInitialization,
  sentryInitialization,
} from "@speed/common/src/components/constants";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { basicLogger } from "launchdarkly-js-client-sdk";
import { CookiesProvider } from "react-cookie";
import {
  CacheLocation,
  FpjsProvider,
} from "@fingerprintjs/fingerprintjs-pro-react";

if (process.env.NODE_ENV === "production") {
  sentryInitialization();
}

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  hotJarInitialization();
}

const renderApp = async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
    options: {
      logger: basicLogger({ level: "none" }),
    },
  });

  ReactDOM.render(
    <FpjsProvider
      loadOptions={{
        apiKey: process.env.REACT_APP_FPJS_PUBLIC_KEY,
      }}
      cacheLocation={CacheLocation.NoCache}
    >
      <Sentry.ErrorBoundary>
        <Router history={history}>
          <LDProvider>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </LDProvider>
        </Router>
      </Sentry.ErrorBoundary>
    </FpjsProvider>,
    document.getElementById("root")
  );
};

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
