import React, { useEffect, useState } from "react";
import SetupCommonHeader from "./SetupCommonHeader";
import {
  cancel,
  payoutSchSubText,
  payoutScheduling,
  selectCurrencyLabel,
} from "../../../messages";
import { useFormik } from "formik";
import {
  getScheduledPayout,
  payoutLoaderComponent,
  payoutSchedulingValidationSchema,
  setPayoutSchDefaultVal,
  setupAutoPayoutSchedulingFunc,
} from "../../../constants";
import { useSelector } from "react-redux";
import AutoPayoutSetupCommon from "../../PayoutScheduling/AutoPayoutSetupCommon";
import Button from "@speed/common/src/components/Button/Button";
import { save } from "@speed/common/src/messages";
import { Box, MenuItem } from "@mui/material";
import AlreadySetupPayoutComponent from "../../PayoutScheduling/AlreadySetupPayoutComponent";
import CustomSelect from "@speed/common/src/components/Select/Select";
import Label from "@speed/common/src/components/Label/Label";
import { some, has } from "lodash";

let timeout;
const SetupAutoPaySchedule = ({
  handleNext,
  handleBack,
  currencies,
  accountAsset,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [payoutScheduleId, setPayoutScheduleId] = useState(null);
  const [isPayoutCreating, setIsPayoutCreating] = useState(false);
  const [scheduledPayoutData, setScheduledPayoutData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  const [shouldCallHandleNext, setShouldCallHandleNext] = useState(false);

  const liveMode = useSelector((state) => state.auth.liveMode);

  useEffect(() => {
    setScheduledPayoutData(null);
    getScheduledPayout({
      setIsLoading,
      setScheduledPayoutData,
      accountAsset,
    });
  }, [liveMode]);

  const handleButtonCallback = () => {
    setButtonLoader(false);
    isEditing && setIsEditing(false);
  };

  useEffect(() => {
    if (shouldCallHandleNext && !isEditing) {
      timeout = setTimeout(() => {
        handleNext();
      }, 5000);
    } else clearTimeout(timeout);
  }, [shouldCallHandleNext, isEditing]);

  const handleSetupAutoPayout = (values) => {
    setButtonLoader(true);
    setupAutoPayoutSchedulingFunc({
      values,
      isDeactivating,
      isEditing,
      setIsPayoutCreating,
      setScheduledPayoutData,
      payoutScheduleId,
      scheduledPayoutData,
      parentCallback: handleButtonCallback,
      selectedCurrency,
      setButtonLoader,
      setShouldCallHandleNext,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      auto_payout_enabled: "",
      payout_type: "",
      fees_type: "high",
      wallet_address: "",
      min_payout_amount: "",
      minimum_balance: "0",
      currency_type_payout: "",
      currency_type_balance: "",
      is_boltz: false,
    },
    validationSchema: payoutSchedulingValidationSchema,
    onSubmit: handleSetupAutoPayout,
  });

  const { values, setFieldValue, validateForm, handleSubmit, isValid, dirty } =
    formik;

  useEffect(() => {
    setPayoutSchDefaultVal({
      setFieldValue,
      isEditing,
      scheduledPayoutData,
      selectedCurrency,
    });
  }, [isEditing]);

  useEffect(() => {
    validateForm();
  }, [values.payout_type, values?.is_boltz]);

  useEffect(() => {
    isDeactivating && handleSetupAutoPayout(values);
  }, [values.auto_payout_enabled]);

  const propsToPass = {
    isPayoutCreating,
    setIsDeactivating,
    setPayoutScheduleId,
    formik,
    setIsEditing,
    selectedCurrency,
  };

  //check if any payout is scheduled
  const isWalletPresent = some(scheduledPayoutData, (obj) =>
    has(obj, "wallet")
  );

  const shouldFormVisible = (isEditing || !isWalletPresent) && !isLoading;

  const payoutScheduledCard = isLoading
    ? payoutLoaderComponent()
    : scheduledPayoutData?.map((payoutData) => {
        return (
          <AlreadySetupPayoutComponent
            {...propsToPass}
            scheduledPayoutData={payoutData}
          />
        );
      });

  return (
    <>
      <SetupCommonHeader
        header={payoutScheduling}
        subHeader={payoutSchSubText}
      />

      <Box
        width="450px"
        mt="50px"
        className="auto-payout-wrapper auto-pay-schedule"
      >
        {shouldFormVisible ? (
          <>
            <Label>{selectCurrencyLabel}</Label>
            <CustomSelect
              value={selectedCurrency}
              MenuProps={{
                id: "payout-wallet-address",
                disableScrollLock: true,
              }}
              renderValue={(_value) => {
                if (selectedCurrency) {
                  return selectedCurrency;
                } else {
                  return <Box> {selectCurrencyLabel} </Box>;
                }
              }}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              customClass="margin-bottom30"
            >
              {currencies?.map((dropDownValue) => {
                return (
                  <MenuItem value={dropDownValue} key={dropDownValue}>
                    <Box>{dropDownValue}</Box>
                  </MenuItem>
                );
              })}
            </CustomSelect>

            {selectedCurrency && (
              <AutoPayoutSetupCommon
                setButtonDisabled={setButtonDisabled}
                formik={formik}
                selectedCurrency={selectedCurrency}
              />
            )}
            <Button
              label={save}
              loading={buttonLoader}
              onClick={handleSubmit}
              type="submit"
              disabled={!(isValid && dirty) || buttonDisabled}
            />
            <Button
              label={cancel}
              disabled={buttonLoader}
              variant="outlined"
              sx={{ ml: "16px" }}
              onClick={() => (isEditing ? setIsEditing(false) : handleBack())}
            />
          </>
        ) : (
          payoutScheduledCard
        )}
      </Box>
    </>
  );
};

export default SetupAutoPaySchedule;
