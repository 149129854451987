import { Box, MenuItem, Skeleton, TextField } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  addNote,
  addNotePlaceholder,
  addPayoutWalletAddress,
  balanceAvailableForModule,
  boltzExchangeMsg,
  boltzInfoMsg,
  btc,
  onchain,
  payoutDetails,
  payoutPreference,
  payoutTypeLightning,
  sats,
  searchWalletPlaceholder,
  selectCurrencyLabel,
  selectPayoutType,
  selectWallet,
  sendMax,
  usdt,
  walletAddress,
} from "../messages";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import {
  addNewPayoutWallet,
  availableBalanceTextComponent,
  callAPIInterface,
  noOfRecords,
  payoutWalletCurrencies,
  renderTermsAgreementCheckbox,
} from "../constants";
import {
  btcToSatsAmount,
  clipboardElement,
  excludedCode,
  getCurrencyObj,
  linkStatus,
  satsToBtcAmount,
  satsToFiatAmount,
  showAmount,
} from "@speed/common/src/components/constants";
import Label from "@speed/common/src/components/Label/Label";
import { Input } from "@speed/common/src/components/Input/Input";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import CommonEstimateFeesBox from "../Common/CommonEstimateFeesBox";
import { useSelector } from "react-redux";
import AmountWithCurrencyInput from "../Common/AmountWithCurrencyInput";
import InfoIcon from "@mui/icons-material/Info";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";

const RequestInstantPayout = ({
  formik,
  setSelectedWallet,
  isModalOpen,
  setBalance,
  selectedWallet,
  balance,
  isChecked,
  setIsChecked,
  estimatedFees: calculatedEstimatedFees,
  getPayoutBalance,
  feesLoader,
  setFeesLoader,
  setEstimatedFees,
  balanceLoader,
  validatePayoutAmountAndFees,
  setSendMaxAmount,
  speedFeeValue,
  setSpeedFeeValue,
  currencyAmount,
  setCurrencyAmount,
  getFeeBoxValues,
  getMinMaxAmountLimit,
}) => {
  const queryParam = "?limit=" + noOfRecords;

  const [searchQuery, setSearchQuery] = useState("");
  const [wallets, setWallets] = useState([]);
  const [walletSkeleton, setWalletSkeleton] = useState(false);
  const [walletsFromResult, setWalletsFromResult] = useState([]);
  const [hasMoreWallet, setHasMoreWallet] = useState(true);
  const [hasMoreWalletFromResult, setHasMoreWalletFromResult] = useState(true);
  const [queryParamsOfWallet, setQueryParamsOfWallet] = useState(queryParam);
  const [payoutBalanceLoading, setPayoutBalanceLoading] = useState(false);
  const [walletOptions, setWalletOptions] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [sendMaxLoader, setSendMaxLoader] = useState(false);
  const [exchangeRateValue, setExchangeRateValue] = useState();
  const [exchangeRateLoader, setExchangeRateLoader] = useState(false);
  const [types, setTypes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const initialQueryParamOfSearch =
    "?limit=" + noOfRecords + "&name=" + searchQuery;

  const [queryParamsOfWalletForSearch, setQueryParamsOfWalletForSearch] =
    useState(initialQueryParamOfSearch);

  const accountAsset = useSelector((state) => state.common.accountAsset);

  const {
    values,
    setFieldValue,
    touched,
    errors,
    setTouched,
    resetForm,
    setErrors,
  } = formik;
  const targetCurrency = values?.asset?.uuid;
  const isSATSAssetSelected = targetCurrency === sats;
  const isSATS = values.payoutCurrencyType?.code === sats;
  const disableAmountInput = payoutBalanceLoading || exchangeRateLoader;
  const { message } = getMinMaxAmountLimit(values.payoutType);

  const walletCheck = searchQuery ? hasMoreWalletFromResult : hasMoreWallet;

  //Get Speed Fee in percentage
  const getSpeedFee = () => {
    if (values.payoutType && targetCurrency) {
      const path = `/account-fees?payment_method=${values.payoutType}&transaction_code=04&target_currency=${targetCurrency}`;
      callAPIInterface("GET", path)
        .then((res) => {
          res && setSpeedFeeValue(res.fee_percentage);
        })
        .catch((_e) => {});
    }
  };

  //Get exchange rates when fiat currency is selected
  const getExchangeRate = (currency) => {
    setExchangeRateLoader(true);
    const data = {
      currency,
      target_currency: targetCurrency,
    };
    callAPIInterface("POST", "/utility/exchange-rate", data)
      .then((res) => {
        setExchangeRateLoader(false);
        const exchangeRate = res?.target_lowest_rate;
        setExchangeRateValue(exchangeRate);
      })
      .catch((_e) => setExchangeRateLoader(false));
  };

  // Fetch the payout wallets initially and display them as an options in wallet auto complete.
  const getPayoutWallets = (lines, params) => {
    setWalletSkeleton(true);
    callAPIInterface("POST", `/payout-wallets/filter${params}`, {
      status: linkStatus.verified.value,
    })
      .then((walletRes) => {
        setWalletSkeleton(false);
        if (!walletRes.has_more) {
          setHasMoreWallet(false);
        } else {
          setQueryParamsOfWallet(
            queryParam +
              "&ending_before=" +
              walletRes.data[walletRes.data.length - 1].id
          );
        }
        setWallets(lines.concat(walletRes.data));
      })
      .catch(() => {
        setWalletSkeleton(false);
      });
  };

  // Fetch the payout wallets while searching wallet and display matched wallets in auto complete.
  const getPayoutWalletsFromResult = (lines, params) => {
    setWalletSkeleton(true);
    callAPIInterface("POST", `/payout-wallets/filter${params}`, {
      status: linkStatus.verified.value,
    })
      .then((walletRes) => {
        setWalletSkeleton(false);
        if (!walletRes.has_more) {
          setHasMoreWalletFromResult(false);
        } else {
          setQueryParamsOfWalletForSearch(
            initialQueryParamOfSearch +
              "&ending_before=" +
              walletRes.data[walletRes.data.length - 1].id
          );
        }
        setWalletsFromResult(lines.concat(walletRes.data));
      })
      .catch(() => {
        setWalletSkeleton(false);
      });
  };

  const fetchPayoutWalletsResult = () => {
    setWalletsFromResult([]);
    setHasMoreWalletFromResult(true);
    setQueryParamsOfWalletForSearch(initialQueryParamOfSearch);
    getPayoutWalletsFromResult([], initialQueryParamOfSearch);
  };

  const fetchPayoutWallets = () => {
    setWallets([]);
    setHasMoreWallet(true);
    getPayoutWallets([], queryParamsOfWallet);
  };

  // Set the initial values of the form when modal is open
  const handleCreatePayoutRequest = () => {
    setPayoutBalanceLoading(true);
    fetchPayoutWallets();
  };

  const resetTheFormValue = () => {
    resetForm({});
    setWallets([]);
    setWalletsFromResult([]);
    setHasMoreWallet(true);
    setSelectedWallet({});
    setSearchQuery("");
    setQueryParamsOfWallet(queryParam);
    setQueryParamsOfWalletForSearch(initialQueryParamOfSearch);
  };

  const loadMoreWalletsFromResult = useCallback(() => {
    getPayoutWalletsFromResult(walletsFromResult, queryParamsOfWalletForSearch);
  }, [walletsFromResult]);

  const loadMoreWallets = useCallback(() => {
    getPayoutWallets(wallets, queryParamsOfWallet);
  }, [wallets]);

  const handleSelectedCurrencyTypes = (currency) => {
    return payoutWalletCurrencies?.filter(
      (type) => type?.currency === currency
    );
  };

  const handleResetValues = (selectedCurrency) => {
    setFieldValue("wallet", null);
    setFieldValue("payoutCurrencyType", getCurrencyObj(selectedCurrency));
    setFieldValue("payoutAmount", "");
    setFieldValue("is_boltz", false);
    setSelectedWallet({});
    setErrors({});
    setEstimatedFees(null);
  };

  const handleChangeCurrency = (e) => {
    const selectedCurrency = e.target.value;
    const selectedCurrencyTypes = handleSelectedCurrencyTypes(
      selectedCurrency?.uuid
    );
    setTypes(selectedCurrencyTypes);
    setFieldValue("asset", selectedCurrency);
    setFieldValue("payoutType", selectedCurrencyTypes?.[0]?.value);
    handleResetValues(selectedCurrency?.name);
  };

  const handleChangePayoutType = (e) => {
    const value = e.target.value;
    setTouched({ ...touched, payoutType: false });
    setFieldValue("payoutType", value);
    handleResetValues(values?.asset?.name);
  };

  const handleWalletClick = (e, optionProps, option) => {
    setFieldValue("wallet", option);
    setSelectedWallet(option);
    optionProps.onClick(e);
  };

  const handleCurrencyOnChange = (value) => {
    setFieldValue("payoutAmount", "");
    setFieldValue("payoutCurrencyType", value);
    setEstimatedFees(0);
    if (
      isSATSAssetSelected
        ? !excludedCode.includes(value?.code)
        : targetCurrency !== value?.code
    ) {
      getExchangeRate(value?.code);
      setCurrencyAmount(
        isSATSAssetSelected && value?.code === btc
          ? btcToSatsAmount(values.payoutAmount)
          : parseFloat(values.payoutAmount)
      );
    }
  };

  const setMaxAmountToInput = (amountToSet) => {
    const cryptoAmount = isSATS ? amountToSet : satsToBtcAmount(amountToSet);
    const amount = excludedCode.includes(values.payoutCurrencyType?.code)
      ? cryptoAmount
      : satsToFiatAmount(amountToSet, exchangeRateValue);
    setFieldValue("payoutAmount", amount);
    setCurrencyAmount(amountToSet);
  };

  const getLightningMaxAmount = () => {
    setSendMaxLoader(true);
    callAPIInterface("GET", "/withdraws/lightning-max-amount")
      .then((res) => {
        const lightningMaxLimit = Number(
          process.env.REACT_APP_LIGHTNING_MAX_LIMIT
        );
        const sendMaxAmount = res?.amount;
        if (sendMaxAmount > lightningMaxLimit)
          setMaxAmountToInput(lightningMaxLimit);
        else setMaxAmountToInput(sendMaxAmount);
        setSendMaxAmount(sendMaxAmount);
      })
      .catch((_e) => {})
      .finally(() => setSendMaxLoader(false));
  };

  useEffect(() => {
    if (isModalOpen) handleCreatePayoutRequest();
    else resetTheFormValue();
  }, [isModalOpen]);

  useEffect(() => setPayoutBalanceLoading(balanceLoader), [balanceLoader]);

  useEffect(() => {
    if (values.asset) {
      getPayoutBalance()
        .then((balance) => {
          setPayoutBalanceLoading(false);
          setBalance(balance);
        })
        .catch(() => {
          setPayoutBalanceLoading(false);
        });
    }
  }, [values?.asset]);

  useEffect(() => {
    if (searchQuery) fetchPayoutWalletsResult();
  }, [searchQuery]);

  useEffect(() => {
    const walletOptions = searchQuery ? walletsFromResult : wallets;
    const filteredWallet = walletOptions.filter(
      (wallet) => wallet.type === values.payoutType
    );
    setWalletOptions(filteredWallet);
  }, [searchQuery, values.payoutType, wallets, walletsFromResult]);

  useEffect(() => {
    if (values.payoutAmount) {
      setFeesLoader(true);
      if (timeoutId) clearTimeout(timeoutId);
      const newTimeoutId = setTimeout(async () => {
        const { hideFeeBox } = await validatePayoutAmountAndFees({
          payoutType: values.payoutType,
          currencyAmount,
          callEstimateFeeAPI: true,
        });

        if (currencyAmount === 0 || hideFeeBox) {
          setEstimatedFees(0);
          setFeesLoader(false);
        }
      }, 500);
      setTimeoutId(newTimeoutId);
    }
  }, [currencyAmount, values.payoutAmount]);

  useEffect(() => {
    if (+values.payoutAmount) {
      if (
        isSATSAssetSelected
          ? !excludedCode.includes(values.payoutCurrencyType?.code)
          : values?.payoutCurrencyType?.code !== targetCurrency
      ) {
        if (exchangeRateValue) {
          const satsValueOfAmount = isSATSAssetSelected
            ? Math.floor(exchangeRateValue * values.payoutAmount)
            : exchangeRateValue * values.payoutAmount;

          satsValueOfAmount && setCurrencyAmount(satsValueOfAmount);
        }
      } else {
        setCurrencyAmount(
          values.payoutCurrencyType?.code === btc
            ? btcToSatsAmount(values.payoutAmount)
            : parseFloat(values.payoutAmount)
        );
      }
    } else setCurrencyAmount(0);
  }, [values.payoutAmount, values.payoutCurrencyType, exchangeRateValue]);

  useEffect(() => {
    getSpeedFee();
  }, [values.payoutType]);

  useEffect(() => {
    if (accountAsset?.length) {
      const firstCurrency = accountAsset?.[0]?.uuid;
      const selectedCurrencyTypes = handleSelectedCurrencyTypes(firstCurrency);
      setTypes(selectedCurrencyTypes);
      setFieldValue("asset", accountAsset?.[0]);
      setFieldValue("payoutType", selectedCurrencyTypes?.[0]?.value);
    }
  }, [accountAsset]);

  const renderAssetCurrencySection = () => (
    <>
      <Label>{selectCurrencyLabel}</Label>
      <Box width="100%">
        <CustomSelect
          value={values?.asset?.name}
          MenuProps={{
            id: "instant-payout-asset",
            disableScrollLock: true,
          }}
          renderValue={(_value) => {
            return _value?.name || values?.asset?.name;
          }}
          onChange={(e) => handleChangeCurrency(e)}
          customClass="margin-bottom30"
          name="asset"
        >
          {accountAsset?.map((dropDownValue) => {
            return (
              <MenuItem value={dropDownValue} key={dropDownValue?.uuid}>
                <Box>{dropDownValue?.name}</Box>
              </MenuItem>
            );
          })}
        </CustomSelect>
      </Box>
    </>
  );

  const renderPayoutTypeSection = () => (
    <CustomSelect
      name="payoutType"
      showLabel={true}
      label={selectPayoutType}
      value={values.payoutType}
      MenuProps={{
        id: "instant-payout-type",
      }}
      onChange={handleChangePayoutType}
      fullWidth={true}
    >
      {types.map((type) => (
        <MenuItem key={type.value} value={type.value}>
          {type.name}
        </MenuItem>
      ))}
    </CustomSelect>
  );

  const renderWalletSelection = () => (
    <CustomAutoComplete
      customClass="margin-top30"
      name="wallet"
      label={selectWallet}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={searchWalletPlaceholder}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      )}
      options={walletOptions}
      disableClearable
      noOptionsText={
        <Fragment>
          {addNewPayoutWallet(addPayoutWalletAddress)}
          {walletSkeleton && (
            <Box marginY={1} padding={1} borderRadius={1}>
              <Skeleton
                animation="wave"
                width="50%"
                sx={{ bgcolor: "#eaeef1 !important" }}
              />
              <Skeleton
                animation="wave"
                sx={{ bgcolor: "#eaeef166 !important" }}
              />
            </Box>
          )}
        </Fragment>
      }
      value={values.wallet}
      getOptionLabel={(option) => option.name ?? ""}
      renderOption={(optionProps, option, _state) => {
        const index = walletOptions?.findIndex((wallet) => wallet === option);
        const lastIndex = walletOptions?.findIndex(
          (wallet) => wallet === walletOptions[walletOptions.length - 1]
        );
        return (
          <Fragment key={index}>
            {index === 0 && addNewPayoutWallet(addPayoutWalletAddress)}
            <Box
              marginY={1}
              padding={1}
              className="pointer-cursor"
              onClick={(e) => handleWalletClick(e, optionProps, option)}
              borderRadius={1}
              sx={{
                ":hover": {
                  bgcolor: "#f1f4f6",
                },
              }}
            >
              <Text className="default-text" size={16} variant="body1">
                {option.name}
              </Text>
              <Text className="grey-text" size={14} variant="body1">
                {option.address}
              </Text>
            </Box>
            {walletSkeleton && index === lastIndex && (
              <Box marginY={1} padding={1} borderRadius={1}>
                <Skeleton
                  animation="wave"
                  width="50%"
                  sx={{ bgcolor: "#eaeef1 !important" }}
                />
                <Skeleton
                  animation="wave"
                  sx={{ bgcolor: "#eaeef166 !important" }}
                />
              </Box>
            )}
          </Fragment>
        );
      }}
      ListboxProps={{
        role: "list-box",
        onScroll: (event) => {
          const listBoxNode = event.currentTarget;
          if (
            listBoxNode.scrollTop + listBoxNode.clientHeight ===
            listBoxNode.scrollHeight
          ) {
            if (walletCheck) {
              searchQuery ? loadMoreWalletsFromResult() : loadMoreWallets();
            }
          }
        },
      }}
    />
  );

  const renderWalletAddressSection = () => (
    <Box className="margin-top30" position="relative">
      <Label>{walletAddress}</Label>
      {clipboardElement(
        values?.wallet?.address,
        "active",
        "custom-payout-clipboard-input",
        "inputBox",
        false,
        "instant-payout-clipboard"
      )}
    </Box>
  );

  const renderAmountSection = () => {
    const inputEndAmountText = exchangeRateLoader ? (
      <Skeleton width={50} height={8} />
    ) : (
      <>
        ≈
        {showAmount({
          amount: currencyAmount,
          currency: targetCurrency,
          targetedCurrency: values?.asset?.name,
          showCommaSeparated: true,
          appendCurrency: true,
          multiplier: targetCurrency === usdt,
        })}
      </>
    );

    const inputProps = {
      values,
      showPopper: true,
      popperInfoMsg: message,
      currencyName: "payoutCurrencyType",
      amountName: "payoutAmount",
      isSendMaxVisible:
        !disableAmountInput && values.payoutType === payoutTypeLightning,
      sendMaxLoader,
      sendMaxBtnLabel: sendMax,
      getMaxAmount: getLightningMaxAmount,
      disableAmountInput,
      amountError: errors.payoutAmount,
      inputEndAmountTextVisible: isSATSAssetSelected
        ? !excludedCode.includes(values.payoutCurrencyType?.code)
        : values?.payoutCurrencyType?.code !== targetCurrency,
      inputEndAmountText,
      handleCurrencyOnChange: (value) => handleCurrencyOnChange(value),
      handleInputChange: (value) => setFieldValue("payoutAmount", value),
    };

    return (
      <>
        <AmountWithCurrencyInput {...inputProps} />
        {Boolean(values.payoutAmount) && Boolean(errors.payoutAmount) && (
          <AlertMessage
            message={errors.payoutAmount}
            className="margin-top15"
            severity="error"
          />
        )}
        {!payoutBalanceLoading &&
          availableBalanceTextComponent({
            preText: balanceAvailableForModule("payout"),
            balance,
            loader: balanceLoader,
            sx: { mt: "13px" },
            currency: values?.asset?.name,
          })}
      </>
    );
  };

  const renderNoteSection = () => (
    <Input
      fullWidth={true}
      showLabel={true}
      label={addNote}
      name="note"
      onChange={(e) => setFieldValue("note", e.target.value)}
      inputProps={{ maxLength: 255 }}
      placeholder={addNotePlaceholder}
      value={values.note}
      customClass="margin-top30"
    />
  );

  const renderFeeBoxSection = () => {
    const feesSectionProps = {
      amount: currencyAmount,
      speedFee: getFeeBoxValues(currencyAmount) * (speedFeeValue / 100),
      estimatedFees: getFeeBoxValues(calculatedEstimatedFees),
      exchangeRateValue,
      loader: feesLoader || exchangeRateLoader,
      currencyCode: values?.payoutCurrencyType?.code,
      targetCurrency,
      payoutType: values.payoutType,
    };
    return (
      Boolean(values?.payoutAmount && calculatedEstimatedFees) && (
        <CommonEstimateFeesBox {...feesSectionProps} />
      )
    );
  };

  const handleCheckChange = () => {
    setFieldValue("payoutAmount", "");
    setFieldValue("is_boltz", !values?.is_boltz);
  };

  const renderBoltzSection = () => {
    return (
      <>
        <Box display="flex" className="margin-top30">
          <Label>{payoutPreference}</Label>
          <InfoIcon
            className="label-with-icon"
            sx={{
              height: "20px !important",
              width: "20px !important",
              marginBottom: "14px",
            }}
            onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
          />
          <CustomPopper
            disablePortal={true}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            position="top"
            handleClose={() => setAnchorEl(null)}
          >
            <Text className="grey-text" size={14} variant="subtitle1">
              {boltzInfoMsg}
            </Text>
          </CustomPopper>
        </Box>
        <CustomCheckbox
          checked={values?.is_boltz}
          onChange={() => handleCheckChange()}
          label={boltzExchangeMsg}
        />
      </>
    );
  };

  return (
    <Box
      className="margin-top30 margin-bottom30"
      display="flex"
      justifyContent="center"
    >
      <Box width="600px">
        <Text
          className="default-text"
          size={20}
          variant="h2"
          sx={{ mb: "30px" }}
        >
          {payoutDetails}
        </Text>

        {renderAssetCurrencySection()}
        {renderPayoutTypeSection()}
        {values?.payoutType === onchain && renderBoltzSection()}
        {renderWalletSelection()}

        {selectedWallet && Object.keys(selectedWallet)?.length > 0 && (
          <>
            {renderWalletAddressSection()}
            {renderAmountSection()}
            {renderNoteSection()}
            {renderFeeBoxSection()}
            {renderTermsAgreementCheckbox(isChecked, setIsChecked)}
          </>
        )}
      </Box>
    </Box>
  );
};

export default RequestInstantPayout;
