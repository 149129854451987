import React from "react";
import { Box, Skeleton } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import { sats } from "../messages";

const EstimateFeesRowSection = (props) => {
  const {
    title,
    subtitle = "",
    feesAmount,
    isLast = false,
    loader,
    currencyToShow,
    shouldRangeShow = false,
    showCurrencyAmount = false,
    currencyAmount = 0,
    selectedCurrency = sats,
  } = props;
  /**
   *  For estimated fee; Added check if address method is onchain or lightning
   *  @if method is onchain it will display the fee according to currency selected by user either in sats or btc
   *  @else method is lightning it will display the range of fee; for exp: 0-5 sats
   */
  return (
    <>
      <Box className={`est-fees-row ${isLast && "last"}`}>
        <Text
          font="medium"
          className="default-text title-text"
          size={16}
          variant="subtitle1"
        >
          {title}
          {Boolean(subtitle) && (
            <>
              <br />
              <Text
                size={14}
                className="grey-text"
                font="regular"
                variant="subtitle1"
              >
                {subtitle}
              </Text>
            </>
          )}
        </Text>
        {loader ? (
          <Skeleton
            width={70}
            height={18}
            animation="wave"
            sx={{ bgcolor: "#EAEEF1 !important" }}
          />
        ) : (
          feesAmount && (
            <Text
              font="regular"
              className="default-text"
              size={16}
              align="right"
              variant="subtitle1"
            >
              <b>{shouldRangeShow ? `0 - ${feesAmount}` : feesAmount}</b>{" "}
              {currencyToShow}
              {showCurrencyAmount && (
                <>
                  <br />
                  <Text
                    variant="inline"
                    size={14}
                    className="grey-text"
                    font="medium"
                  >
                    (≈ {currencyAmount} {selectedCurrency})
                  </Text>
                </>
              )}
            </Text>
          )
        )}
      </Box>
      {!isLast && <CustomDivider sx={{ margin: "16px 0" }} />}
    </>
  );
};
export default EstimateFeesRowSection;
