import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import { Text } from "@speed/common/src/components/Text/Text";
import CustomSelect from "@speed/common/src/components/Select/Select";
import {
  addNewAccountText,
  newAccountText,
  switchingAccountText,
} from "../messages";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import {
  generateToken,
  setInitialState,
  setLiveMode,
  setLocalStorageMenu,
  updateUser,
} from "../../redux/auth/actions";
import { accountIcons } from "@speed/common/src/components/images";
import {
  callAPIInterface,
  getTimestamp,
  isAffiliatePartnerDataAvailable,
  walletAccountRoute,
} from "../constants";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { InputBase } from "@mui/material";
import {
  Merchant,
  Wallet,
  noResultfound,
} from "@speed/common/src/components/messages";
import classNames from "classnames";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { setPathname } from "../../redux/common/actions";
import { searchAccount } from "@speed/common/src/messages";
import AddEditAccount from "@speed/common/src/components/AddEditAccount";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { useFlags } from "launchdarkly-react-client-sdk";

const AccountSelection = () => {
  const dispatch = useDispatch();
  const flags = useFlags();

  const { user, currentAccount } = useSelector((state) => state.auth);
  const { history, pathname } = useSelector((state) => state.common);
  const { wwAccountslistFe } = flags;
  const accountType = currentAccount?.account?.account_type;

  const [allAccountsInfo, setAllAccountsInfo] = useState();
  const [selectedAccount, setSelectedAccount] = useState();
  const [open, setOpen] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);

  const accountIconElement = (type) => {
    return (
      <Box className="switch-account-icon">
        {accountIcons()[type ? type : Merchant]}
      </Box>
    );
  };

  const setAccountDetails = async (
    accountList,
    id,
    reloadPage,
    changedAccount,
    mode,
    path = "/dashboard"
  ) => {
    let session = await sessionService.loadSession();
    session &&
      sessionService
        .saveSession({
          ...session,
          speed_acc_id: id,
          liveMode: mode,
          previous_account_type: accountType === Wallet ? Wallet : Merchant,
        })
        .then(async () => {
          if (reloadPage) {
            changedAccount &&
              (await callAPIInterface("GET", "/select-account"));
            localStorage.removeItem("open_ps_hello_bar");
            window.location.assign(`${window.location.origin}${path}`);
          } else {
            let currentSelectedAccount = accountList?.filter(
              (account) => account?.id === id
            )[0];
            setSelectedAccount(currentSelectedAccount);
          }
        });
  };

  const getWalletAccountId = () => {
    const accountLists = user?.accounts_details?.map(
      (accountData) => accountData?.account
    );

    const walletAccount = accountLists?.find(
      (acc) => acc.account_type === "Wallet"
    );
    return walletAccount;
  };

  const loadInitialData = async () => {
    let session = await sessionService.loadSession();
    const fromPartnerDataAvailable = isAffiliatePartnerDataAvailable();
    let accountLists = user.accounts_details.map(
      (accountData) => accountData?.account
    );

    let mode = user.accounts_details.map(
      (accountData) => accountData?.is_last_viewed_livemode
    );
    setAllAccountsInfo(accountLists);
    setAccountDetails(
      accountLists,
      accountLists?.[0]?.id,
      false,
      false,
      mode[0]
    );

    if (!fromPartnerDataAvailable && session?.previous_account_type) {
      if (
        session.previous_account_type === Merchant &&
        accountType === Wallet
      ) {
        setLocalStorageMenu(Wallet);
        dispatch(setLiveMode(true));
      } else if (
        session.previous_account_type === Wallet &&
        accountType === Merchant
      ) {
        setLocalStorageMenu(Merchant);
      }
    } else {
      setLocalStorageMenu(accountType === Merchant ? Merchant : Wallet);
    }
  };

  useEffect(() => {
    loadInitialData();
  }, [user, currentAccount]);

  const handleRedirection = async () => {
    const isWalletRoute = walletAccountRoute.some((wr) =>
      pathname.includes(wr)
    );
    const walletAcc = getWalletAccountId();
    if (isWalletRoute && accountType !== Wallet) {
      await handleAccountChange(walletAcc?.id, pathname);
    } else {
      history.push(pathname);
      dispatch(setPathname(""));
    }
  };

  useEffect(() => {
    if (pathname) {
      handleRedirection();
    }
  }, [pathname]);

  const handleAccountChange = async (menuAccountId, path = "") => {
    let session = await sessionService.loadSession();
    await sessionService.saveSession({
      ...session,
      previous_account_type: accountType,
    });
    const result = user.accounts_details.find(
      (acc) => acc?.account?.id === menuAccountId
    );
    setLoading(true);
    await setAccountDetails(
      allAccountsInfo,
      menuAccountId,
      true,
      true,
      result?.is_last_viewed_livemode,
      path
    );
  };

  const handleAddAccount = async (accountData) => {
    if (accountData) {
      const { token_response: updatedTokens } = accountData;
      if (updatedTokens && Object.keys(updatedTokens).length !== 0) {
        const session = await sessionService.loadSession();
        await sessionService.saveSession({
          ...session,
          access_token: updatedTokens.access_token,
          id_token: updatedTokens.id_token,
          timestamp: getTimestamp(),
        });
      }
      await setAccountDetails(allAccountsInfo, accountData?.account?.id, true);
    }
  };

  const handleUpdateAccount = async (accountData) => {
    if (accountData) {
      const updatedAccountDetails = user.accounts_details.map((accountInfo) => {
        if (accountInfo?.account?.id === accountData.id) {
          return { ...accountInfo, account: accountData };
        }
        return accountInfo;
      });

      let updatedUserData = {
        ...user,
        accounts_details: updatedAccountDetails,
      };
      dispatch(updateUser(updatedUserData));

      let updatedcurrentAccountData = updatedAccountDetails.filter(
        (accountInfo) => accountInfo?.account?.id === accountData.id
      )[0];
      sessionService.loadSession().then((session) => {
        dispatch(
          setInitialState({
            ...session,
            current_acc: updatedcurrentAccountData,
          })
        );
      });
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.currentTarget.click();
      setOpenDropdownMenu(false);
    }
  };

  const getAccountList = () => {
    let accountLists = user.accounts_details.map(
      (accountData) => accountData?.account
    );

    return accountLists;
  };

  const getAccountSelectionOption = () => {
    const walletAccounts = [];
    const merchantAccounts = [];
    let flagMerchant = false;
    let flagWallet = false;

    const accounts = getAccountList();

    accounts?.forEach((account) => {
      const isMerchant = account.account_type === Merchant;
      const obj = {
        id: account.id,
        account_type: account.account_type,
        name: account.name,
        isDivider: isMerchant ? !flagMerchant : !flagWallet,
      };
      if (isMerchant) {
        merchantAccounts.push(obj);
        flagMerchant = true;
      } else {
        if (wwAccountslistFe) {
          walletAccounts.push(obj);
          flagWallet = true;
        }
      }
    });

    return accountType === Merchant
      ? [...merchantAccounts, ...walletAccounts]
      : [...walletAccounts, ...merchantAccounts];
  };

  const SelectAccountUI = () => (
    <CustomSelect
      onKeyDownCapture={(event) => {
        if (event.key === "Tab") {
          event.stopPropagation();
        }
        if (event.key === "Escape") {
          setOpenDropdownMenu(false);
        }
      }}
      open={openDropdownMenu}
      onClose={() => {
        setOpenDropdownMenu(false);
      }}
      onOpen={() => {
        setOpenDropdownMenu(true);
      }}
      className="header-account-select"
      variant="standard"
      labelId="switch-account"
      id="header-account-select"
      onChange={() => setOpenDropdownMenu(false)}
      MenuProps={{
        id: "header-account-select-menu",
      }}
      renderValue={() => {
        if (selectedAccount) {
          return (
            <MenuItem value={selectedAccount?.id}>
              {accountIconElement(selectedAccount.account_type)}
              <Text component="span" size={18} noWrap>
                {selectedAccount.name}
              </Text>
            </MenuItem>
          );
        }
      }}
    >
      <CustomAutoComplete
        open
        id="header-account-select-account"
        autoHighlight
        disablePortal
        noOptionsText={
          <>
            <Text size={14} align="center" className="grey-text">
              {noResultfound}
            </Text>
            <Text
              size={12}
              variant="h4"
              className="grey-text"
              align="center"
              font="regular"
              sx={{ marginTop: "10px" }}
            >
              {addNewAccountText}
            </Text>
          </>
        }
        showLabel={false}
        getOptionLabel={(option) => option.name}
        name="speed-accounts"
        options={getAccountSelectionOption()}
        onChange={(_event, value) => {
          value?.id && setOpenDropdownMenu(false);
          if (value?.id && selectedAccount?.id !== value.id) {
            const path =
              value?.account_type === Wallet ? "/my-assets" : "/dashboard";
            handleAccountChange(value.id, path);
          }
        }}
        renderInput={(params) => {
          return (
            <InputBase
              ref={params.InputProps.ref}
              inputProps={{
                ...params.inputProps,
                value: null,
              }}
              autoFocus
              placeholder={searchAccount}
            />
          );
        }}
        renderOption={(props, option) => {
          return (
            <>
              {option?.isDivider ? (
                <CustomDivider
                  textAlign="left"
                  className="account-divider"
                  text={option.account_type === Merchant ? Merchant : Wallet}
                />
              ) : null}
              <MenuItem
                {...props}
                className={classNames(
                  props["data-option-index"] === 0 &&
                    selectedAccount?.id === option.id &&
                    "Mui-selected"
                )}
                key={option.id}
                value={option.id}
              >
                {accountIconElement(option.account_type)}
                <TruncatedTextTooltip
                  textValue={option.name}
                  cellWidth="134px"
                  className="custom-tooltip"
                />
                <Box
                  className="edit-account-icon"
                  tabIndex={0}
                  onKeyDown={handleEnterKeyPress}
                  onClick={() => {
                    setEdit(true);
                    setOpen(true);
                  }}
                >
                  <EditIcon />
                </Box>
              </MenuItem>
            </>
          );
        }}
      />
      {allAccountsInfo && (
        <Box className="add-new-acccount-box-wrapper">
          <Box
            tabIndex={0}
            onKeyDown={handleEnterKeyPress}
            className="add-new-acccount-box"
            onClick={() => {
              setOpen(true);
              setOpenDropdownMenu(false);
            }}
          >
            <Box className="add-new-account-icon">
              <AddIcon />
            </Box>
            <Text className="default-text" component="span" size={16}>
              {newAccountText}
            </Text>
          </Box>
        </Box>
      )}
    </CustomSelect>
  );

  return (
    <React.Fragment>
      {selectedAccount && <SelectAccountUI />}
      <BackdropLoader
        open={isLoading}
        alt="switching account..."
        text={switchingAccountText}
        customClass="loader"
      />
      {open && (
        <AddEditAccount
          open={open}
          isEdit={isEdit}
          selectedAccount={selectedAccount}
          handleClose={() => {
            setOpen(false);
            setEdit(false);
          }}
          handleUpdateAccount={handleUpdateAccount}
          handleAddAccount={handleAddAccount}
          callAPIInterface={callAPIInterface}
          generateToken={generateToken}
          dispatch={dispatch}
        />
      )}
    </React.Fragment>
  );
};
export default AccountSelection;
