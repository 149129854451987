import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { QRCodeComponent } from "@speed/common/src/components/QRCode/QRCode";
import Text from "@speed/common/src/components/Text/Text";
import { speedQrLogo } from "../../images";
import {
  bitcoin,
  lightning,
  on_chain,
  onchain,
  sats,
  usdt,
} from "../../messages";
import { useSelector } from "react-redux";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import ReceiveQRDetails from "./ReceiveQRDetails";
import { getAccountFees } from "../../constants";
import { satsToBtcAmount } from "@speed/common/src/components/constants";

const ReceivePayment = ({
  setIsEnterCustomAmount,
  receiveResponse,
  amount,
  isLoading,
  selectedCurrencyType,
  setStep,
  setIsNoteClicked,
  setIsAmountClicked,
  switchCurrency,
  generatedPaymentMethodArr,
  setSelectedMethodName,
  selectedMethodName,
  paymentId,
  statementDesc,
  setSelectedTab,
  selectedTab,
  fiatAmount,
}) => {
  const currency = selectedCurrencyType?.uuid || sats;
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const sliderRef = useRef();
  const divRef = useRef();
  const isDisplayGeneratedAddress = !(
    selectedMethodName === lightning ||
    (currency === usdt && selectedMethodName === on_chain)
  );
  const tabLoaderCss = {
    borderRadius: "10px",
    width: "85%",
    height: "55px",
    transform: "scale(1, 1)",
    margin: "24px 0px 8px",
  };

  const [accountFees, setAccountFees] = useState(null);

  useEffect(() => {
    if (selectedMethodName) fetchAccountFees();
  }, [selectedTab, selectedMethodName]);

  const fetchAccountFees = async () => {
    try {
      const speedFee = await getAccountFees(
        selectedMethodName === on_chain ? onchain : selectedMethodName,
        currency,
        "01"
      );
      setAccountFees(speedFee);
    } catch (err) {}
  };

  const handleSwipe = (value) => {
    const arrLength = generatedPaymentMethodArr?.length;
    const leftTab = arrLength === selectedTab ? selectedTab : selectedTab + 1;
    const rightTab = selectedTab === 1 ? selectedTab : selectedTab - 1;
    let selectTab = value === "left" ? leftTab : rightTab;
    const res = generatedPaymentMethodArr?.find(
      (method) => method?.tab == selectTab
    );
    setSelectedMethodName(res?.name);
    setSelectedTab(selectTab);
  };

  const carouselProps = {
    dots: false,
    touchMove: true,
    infinite: false,
    arrows: false,
    onSwipe: handleSwipe,
  };

  const rowPaymentAddress = useMemo(() => {
    return generatedPaymentMethodArr?.find(
      (addr) => addr?.name === selectedMethodName
    )?.address;
  }, [generatedPaymentMethodArr, selectedMethodName, paymentId]);

  const getGeneratedAddress = () => {
    return makeAddress(
      rowPaymentAddress,
      statementDesc,
      amount,
      selectedMethodName === on_chain
        ? bitcoin.toLowerCase()
        : selectedMethodName
    );
  };

  const makeAddress = (
    address,
    statement_descriptor,
    amount,
    addressHeader
  ) => {
    const url = new URL(`${addressHeader}:${address}`);
    if (amount) {
      let amountToSendInAddress = 0;
      if (addressHeader === "bitcoin" && currency === sats) {
        amountToSendInAddress = showBtcAmount
          ? amount
          : satsToBtcAmount(amount);
      } else {
        amountToSendInAddress = amount;
      }
      url.searchParams.append("amount", amountToSendInAddress);
    }
    if (statement_descriptor) {
      url.searchParams.append("label", statement_descriptor);
    }
    return url.toString();
  };

  const handleTabChange = (tab) => {
    sliderRef.current?.slickGoTo(tab === 1 ? 0 : tab - 1);
    setSelectedTab(tab);
  };

  const displayQR = () => {
    const paymentAddress = isDisplayGeneratedAddress
      ? getGeneratedAddress()
      : rowPaymentAddress;
    return (
      <QRCodeComponent
        value={paymentAddress}
        size={410}
        icon
        iconSize={25}
        level={paymentAddress?.length > 100 ? "L" : "H"}
        includeMargin={true}
        borderRadius="10px"
        src={speedQrLogo}
      />
    );
  };

  const renderTabData = (tabId, icon, text) => {
    return (
      <Box
        className={
          selectedTab === tabId ? `selected-tab` : "lightning-onchain-tab"
        }
        onClick={() => {
          handleTabChange(tabId);
          setSelectedMethodName(text);
        }}
      >
        <Text
          size={14}
          font="regular"
          sx={{ display: "flex", flexDirection: "column", mt: "2px" }}
          className="default-class content-center"
        >
          <img alt="icon" src={icon} height="24px" width="24px" />
          {text === on_chain
            ? bitcoin
            : text.charAt(0).toUpperCase() + text.slice(1)}
        </Text>
      </Box>
    );
  };

  const renderLightningOnchainTabs = () => {
    return isLoading ? (
      <Box
        className={`receive-tabs-box ${
          generatedPaymentMethodArr?.length >= 2 && "receive-tabs"
        } `}
        width="520px"
      >
        <CommonSkeleton sx={{ mr: "8px" }} {...tabLoaderCss} />
        <CommonSkeleton {...tabLoaderCss} />
      </Box>
    ) : (
      <Box
        className={`receive-tabs-box ${
          generatedPaymentMethodArr?.length >= 2 && "receive-tabs"
        } `}
      >
        <>
          {generatedPaymentMethodArr?.map((method) => {
            return renderTabData(method?.tab, method?.icon, method?.name);
          })}
        </>
      </Box>
    );
  };

  const handleNoteEdit = () => {
    setIsEnterCustomAmount(true);
    setIsNoteClicked(true);
    setStep("2");
  };

  const handleAmountEdit = () => {
    setIsEnterCustomAmount(true);
    setIsAmountClicked(true);
    setStep("2");
  };

  const receiveQrProps = {
    isLoading,
    amount,
    receiveResponse,
    getGeneratedAddress,
    rowPaymentAddress,
    accountFees,
    handleAmountEdit,
    handleNoteEdit,
    currency,
    fiatAmount,
    switchCurrency,
    selectedMethodName,
  };

  return (
    <Box className="receive-container">
      {renderLightningOnchainTabs()}
      <Box
        className={`payment-qr-code ${
          currency !== sats ? "payment-qr-code-usdt" : "payment-qr-code-btc"
        }`}
      >
        {isLoading ? (
          <Box className="qr-loader">
            <Skeleton className="qr-skeleton" variant="rectangular" />
          </Box>
        ) : (
          <div ref={divRef}>
            <Slider {...carouselProps} ref={sliderRef}>
              {isLoading
                ? displayQR()
                : generatedPaymentMethodArr?.map(() => displayQR())}
            </Slider>
          </div>
        )}
        <ReceiveQRDetails {...receiveQrProps} />
      </Box>
      {/* <ReceiveFooter
        isLoading={isLoading}
        setIsEnterCustomAmount={setIsEnterCustomAmount}
        amount={amount}
        setStep={setStep}
        receiveResponse={receiveResponse}
        getGeneratedAddress={getGeneratedAddress}
        rowPaymentAddress={rowPaymentAddress}
        divRef={divRef}
        isDisplayGeneratedAddress={isDisplayGeneratedAddress}
        selectedTab={selectedTab}
      /> */}
    </Box>
  );
};
export default ReceivePayment;
