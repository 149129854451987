import React from "react";
import { useParams } from "react-router";
import "../../assets/styles/payment-links.scss";
import ModuleEventList from "../ModuleEventList";

const SwapDetailsEvents = (props) => {
  const urlParams = useParams();

  return <ModuleEventList ldFlag urlParams={urlParams} {...props} />;
};

export default SwapDetailsEvents;
