import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import BalanceCard from "./BalanceCard";
import { availableBalance, availableBalanceText, sats } from "../messages";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import "../../assets/styles/balance.scss";
import { useSelector } from "react-redux";
import { callAPIInterface, getExchangeRate } from "../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import {
  fetchRawBalanceData,
  getDefaultWalletCurrencyObj,
  showAmount,
} from "@speed/common/src/components/constants";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";

const Balances = () => {
  const [balanceData, setBalanceData] = useState();
  const [fiatAccountBalance, setFiatAccountBalance] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { liveMode, currentAccount } = useSelector((state) => state.auth);
  const { history, isMobile, accountAsset } = useSelector(
    (state) => state.common
  );
  const {
    poListpayoutrequest,
    poCreatepayoutrequest,
    swListingswapFe,
    swCreateFe,
    trListingFe,
    trCreateFe,
  } = useFlags();

  const isPayout = poListpayoutrequest || poCreatepayoutrequest;

  const isSwap = swListingswapFe || swCreateFe;

  const isTransfer = trListingFe || trCreateFe;

  const setInitialFiatBalance = async (balanceData) => {
    const defaultCurrency = JSON.parse(
      localStorage.getItem("account_default_currency")
    );

    const fiatCurrency = (
      defaultCurrency ||
      getDefaultWalletCurrencyObj(currentAccount?.account?.country)
    )?.code;

    if (fiatCurrency) {
      const exRate = await getExchangeRate(fiatCurrency, sats);
      const satsAmount = balanceData?.find(
        (data) => data?.target_currency === sats
      )?.amount;

      if (satsAmount) {
        const fiatBalace = showAmount({
          amount: satsAmount,
          currency: fiatCurrency,
          appendCurrency: true,
          multiplier: exRate.target_lowest_rate,
          showCommaSeparated: true,
          divideAmount: true,
        });

        setFiatAccountBalance(fiatBalace);
      }
    }
  };

  const getBalanceData = () => {
    setIsLoading(true);
    callAPIInterface("GET", "/balances")
      .then(async (resData) => {
        if (resData) {
          const data = fetchRawBalanceData(resData);
          setBalanceData(data);
          await setInitialFiatBalance(data);
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBalanceData();
  }, [liveMode]);

  const getBalanceObject = (currency) =>
    balanceData?.find((data) => data?.target_currency === currency);

  const commonBalanceCardProps = {
    text: availableBalance,
    msg: availableBalanceText,
    isSwap,
    isPayout,
    isTransfer,
    handleOnClickPayout: () => history.push("/payouts"),
    handleOnClickTransfer: () => history.push("/transfers"),
    handleOnClickSwap: () => history.push("/swaps"),
  };

  const commonSkeleton = ({
    height = 25,
    width = 100,
    borderRadius = "8px",
    color = "#eaeef1",
    sx = {},
    className,
  }) => (
    <CommonSkeleton
      {...{ className, width, height, color, sx: { borderRadius, ...sx } }}
    />
  );

  const renderLoading = () => (
    <Card className="balance-main-card">
      <Grid display="flex" flexDirection="column" gap="15px">
        <Grid item lg={10}>
          <CardContent className="card-body">
            <Box className="card-icon">
              {commonSkeleton({
                height: 50,
                width: 30,
                borderRadius: "50%",
                sx: { transformOrigin: "top" },
              })}
            </Box>
            {commonSkeleton({
              width: 155,
              color: "#c4ccd2",
            })}
          </CardContent>
          <CardContent className="card-body">
            {commonSkeleton({
              className: "card-sub-text",
              width: 130,
              height: 21,
              color: "#c4ccd2",
            })}
          </CardContent>
          <CardContent className="card-body" sx={{ height: "30px" }}>
            {commonSkeleton({ className: "fiat-balnce-text" })}
          </CardContent>
          <CardContent className="card-body" sx={{ height: "30px" }}>
            {commonSkeleton({ className: "fiat-balnce-text" })}
          </CardContent>
        </Grid>
        <Grid item lg={2} gap={2} display="flex" ml="46px" mt="5px">
          {commonSkeleton({ width: 80 })}
          {commonSkeleton({ width: 80 })}
          {commonSkeleton({ width: 80 })}
        </Grid>
      </Grid>
    </Card>
  );

  const renderBalance = () =>
    accountAsset?.map((assetData) => {
      const isBTCBalance = assetData.uuid === sats;
      const targetCurrency = assetData.name;
      const fiatBalance = isBTCBalance ? fiatAccountBalance : 0;
      return (
        <BalanceCard
          icon={
            <img
              src={assetData.icon}
              alt={`${assetData.name}-currency-icon`}
              style={{ height: "30px", width: "30px" }}
            />
          }
          balanceData={getBalanceObject(assetData.uuid)}
          targetCurrency={targetCurrency}
          isBTCBalance={isBTCBalance}
          fiatAccountBalance={fiatBalance}
          {...commonBalanceCardProps}
        />
      );
    });

  return (
    <Box className="section-wrapper">
      <CommonHeader />
      <CustomDivider />
      <Box
        className="balance-wrapper box-container"
        style={{ minHeight: isMobile ? "95vh" : "83vh" }}
        display="flex"
        gap={3}
      >
        {isLoading ? renderLoading() : renderBalance()}
      </Box>
    </Box>
  );
};

export default Balances;
