import { Box } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import React, { useMemo, useState } from "react";
import {
  addAmount,
  addNote,
  amountText,
  btc,
  invoice,
  lightning,
  lightningNetworkFeeInfo,
  note,
  on_chain,
  speedFee,
  usdt,
} from "../../messages";
import {
  makeBoldString,
  showAmount,
} from "@speed/common/src/components/constants";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import ErrorIcon from "@mui/icons-material/Error";
import { penEditIcon } from "../../images";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { useSelector } from "react-redux";
import { address } from "@speed/common/src/components/messages";

const ReceiveQRDetails = ({
  isLoading = false,
  amount = null,
  receiveResponse = null,
  rowPaymentAddress = null,
  getGeneratedAddress = null,
  accountFees,
  currency,
  handleAmountEdit,
  handleNoteEdit,
  fiatAmount,
  switchCurrency,
  selectedMethodName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const walletDefaultCurrency = JSON.parse(
    localStorage.getItem("account_default_currency")
  );
  const open = Boolean(anchorEl);
  const rowsLoader = Array.from(Array(4).keys());
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);

  const handleClick = (event) =>
    setAnchorEl(anchorEl ? null : event.currentTarget);

  const showTaprootAmount = useMemo(
    () => (
      <Text
        size={16}
        className="default-text"
        sx={{ mr: "4px", textAlign: "right" }}
      >
        {showAmount({
          amount,
          currency: showBtcAmount ? btc : currency,
          showCommaSeparated: true,
          appendCurrency: true,
          multiplier: currency === usdt,
        })}
      </Text>
    ),
    [amount]
  );

  const showFiatAmout = useMemo(
    () => (
      <Text
        size={14}
        className="grey-text"
        sx={{ mr: "4px", textAlign: "right" }}
        font="regular"
      >
        {walletDefaultCurrency?.symbol}
        {showAmount({
          amount: fiatAmount,
          currency,
          showCommaSeparated: true,
          multiplier: currency === usdt,
        })}
      </Text>
    ),
    [fiatAmount]
  );

  return isLoading ? (
    <Box className="receive-details-container">
      {rowsLoader.map((val) => (
        <Box key={val} className="receive-details-address">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb={val === 4 ? "12px" : undefined}
          >
            <CommonSkeleton
              color="#c4ccd2"
              width={80}
              height={20}
              sx={{ borderRadius: "5px" }}
            />
            <Box className="content-center">
              <CommonSkeleton
                color="#eaeef1"
                width={80}
                height={20}
                sx={{ borderRadius: "5px" }}
              />
            </Box>
          </Box>
          {val !== 4 && <CustomDivider sx={{ mt: "12px" }} />}
        </Box>
      ))}
    </Box>
  ) : (
    <Box className="receive-details-container">
      <Box className="receive-details-address">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text size={16} className="grey-text">
            {currency === usdt || selectedMethodName === on_chain
              ? address
              : invoice}
          </Text>
          <Box className="content-center">
            <Text size={16} sx={{ mr: "4px" }} className="grey-text">
              {makeBoldString(rowPaymentAddress, 16)}
            </Text>
            <Clipboard text={getGeneratedAddress()} />
          </Box>
        </Box>
        <CustomDivider sx={{ mt: "12px" }} />
      </Box>
      <Box className="receive-details-address">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text size={16} className="grey-text">
            {note}
          </Text>
          <Box
            onClick={() => selectedMethodName === lightning && handleNoteEdit()}
            sx={{ cursor: selectedMethodName === lightning && "pointer" }}
            className="content-center"
          >
            {receiveResponse?.statement_descriptor &&
            selectedMethodName === lightning ? (
              <TruncatedTextTooltip
                textProps={{
                  className: "default-text",
                  font: "semibold",
                  sx: { mr: "4px" },
                }}
                textValue={receiveResponse.statement_descriptor}
                cellWidth="136px"
                fontSize={16}
              />
            ) : (
              <Text
                size={16}
                className={
                  selectedMethodName === lightning ? "blue-text" : "grey-text"
                }
                sx={{ mr: "4px" }}
              >
                {addNote}
              </Text>
            )}
            <img src={penEditIcon} alt="edit_icon" />
          </Box>
        </Box>
        <CustomDivider sx={{ mt: "12px" }} />
      </Box>
      <Box className="receive-details-address">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text size={16} className="grey-text">
            {amountText}
          </Text>
          <Box
            onClick={handleAmountEdit}
            sx={{ cursor: "pointer" }}
            className="content-center"
          >
            {amount > 0 ? (
              <Box display="flex" flexDirection="row" alignItems="flex-start">
                <Box>
                  {switchCurrency ? showFiatAmout : showTaprootAmount}
                  {switchCurrency ? showTaprootAmount : showFiatAmout}
                </Box>
                <img src={penEditIcon} alt="edit_icon" />
              </Box>
            ) : (
              <>
                <Text size={16} className="blue-text" sx={{ mr: "4px" }}>
                  {addAmount}
                </Text>
                <img src={penEditIcon} alt="edit_icon" />
              </>
            )}
          </Box>
        </Box>
        <CustomDivider sx={{ mt: "12px" }} />
      </Box>
      <Box className="receive-details-address">
        <Box
          mb="12px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <Text size={16} className="grey-text">
              {speedFee}
            </Text>
            <ErrorIcon
              aria-describedby={"account-fee"}
              sx={{
                height: "18px",
                width: "18px",
                color: "#A4A9B7 !important",
                marginLeft: "4px",
                cursor: "pointer",
              }}
              onClick={(e) => handleClick(e)}
            />
            <CustomPopper
              id="account-fee"
              disablePortal={true}
              open={open}
              anchorEl={anchorEl}
              position="top"
              handleClose={() => setAnchorEl(null)}
              sx={{ width: "200px" }}
            >
              <Text variant="inherit" font="regular" size={12}>
                {lightningNetworkFeeInfo}
              </Text>
            </CustomPopper>
          </Box>
          <Text size={16} className="default-text">
            {accountFees || "0"}%
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ReceiveQRDetails;
