import { Box, Grid, TableCell, TableRow } from "@mui/material";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import {
  clipboardElement,
  generateBreadcrumbs,
  redirectWhenEntityNotFound,
  showAmount,
} from "@speed/common/src/components/constants";
import Text from "@speed/common/src/components/Text/Text";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  getSwapTargetCurrencyExRate,
  RouteBreadcrumbs,
} from "../constants";
import WarningIcon from "@mui/icons-material/Warning";
import {
  amountYouPay,
  createdOn,
  entityNotFoundMessage,
  events as eventsText,
  exchangeRate,
  from,
  speedFee,
  swapDetailsText,
  to,
  totalAmountYouGet,
} from "../messages";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import PaymentsSkeleton from "@speed/common/src/components/Skeleton/PaymentsSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import { amount } from "@speed/common/src/components/messages";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import { events } from "../Events/Events";

const SwapDetails = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const [data, setData] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [swapEventSkeleton, setSwapEventSkeleton] = useState(true);
  const [swapEventsList, setSwapEventsList] = useState([]);

  const history = useSelector((state) => state.common.history);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const urlParams = useParams();
  const { swListingswapFe } = useFlags();

  const {
    amount: swapAmount,
    currency,
    target_currency_swap_out,
    target_amount_swap_out,
    target_amount_swap_in,
    target_currency_swap_in,
    id,
    speed_fee,
    created,
  } = { ...data };

  const getSwapDetail = () => {
    callAPIInterface(
      "GET",
      `/balances/swap/${urlParams.id}?currency_denomination=higher`
    )
      .then((res) => {
        if (res) {
          getSwapEventsList();
          setData(res);
        }
      })
      .catch((err) => {
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const getSwapEventsList = () => {
    setSwapEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface("POST", "/events/filters", JSON.stringify(filterEvents))
      .then((res) => {
        if (res) {
          setSwapEventSkeleton(false);
          setSwapEventsList(res?.data);
        }
      })
      .catch(() => {
        setSwapEventSkeleton(false);
      });
  };

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getSwapDetail();
  };

  const getTotalAmountPay = () => {
    const amount = showAmount({
      amount: target_amount_swap_out || 0,
      currency: target_currency_swap_out,
      appendCurrency: true,
      showCommaSeparated: true,
    });

    return amount;
  };

  const getTotalAmountYouGet = () => {
    const totalAmountYouGet = showAmount({
      amount: Number(target_amount_swap_in) - Number(speed_fee?.amount),
      currency: target_currency_swap_in,
      appendCurrency: true,
      showCommaSeparated: true,
    });
    return totalAmountYouGet;
  };

  const getCalculatedExRate = () =>
    `1 ${target_currency_swap_out} = ${
      1 * getSwapTargetCurrencyExRate(data)
    } ${target_currency_swap_in}`;

  const getSpeedFeeText = () => {
    const speedFeeInPercentage = `${speed_fee?.percentage}%`;
    const speedFeeInValue =
      speed_fee?.percentage > 0
        ? showAmount({
            amount: Number(speed_fee?.amount),
            currency: target_currency_swap_in,
            appendCurrency: true,
            showCommaSeparated: true,
          })
        : 0;
    return `${speedFeeInPercentage} ${
      speedFeeInValue ? "≈ " + speedFeeInValue : ""
    }`;
  };

  useEffect(
    () => swListingswapFe && loadDetailPage(),
    [swListingswapFe, liveMode]
  );

  const headerContent = () => {
    return (
      <Box
        className="header-content"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text className="btc-amount" variant="h1" size={28}>
          {getTotalAmountYouGet()}
        </Text>
        <Box className="header-price-content" mt="0px !important">
          {clipboardElement(id, true, "payments-detail", "text", true)}
        </Box>
      </Box>
    );
  };

  const renderSwapDetails = () => {
    const gridProps = { keyNameCellWidth: 5, valueCellWidth: 7 };
    return (
      <Box
        gap={2.75}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <KeyValueGrid
          keyName={from}
          value={target_currency_swap_out}
          {...gridProps}
        />
        <KeyValueGrid
          keyName={amountYouPay}
          value={getTotalAmountPay()}
          {...gridProps}
        />
        <KeyValueGrid
          keyName={to}
          value={target_currency_swap_in}
          {...gridProps}
        />
        <KeyValueGrid
          keyName={totalAmountYouGet}
          value={getTotalAmountYouGet()}
          {...gridProps}
        />
        <KeyValueGrid
          keyName={amount}
          value={showAmount({
            amount: swapAmount,
            currency,
            multiplier: 1,
            showCommaSeparated: true,
            appendCurrency: true,
          })}
          {...gridProps}
        />
        <KeyValueGrid
          keyName={exchangeRate}
          value={getCalculatedExRate()}
          {...gridProps}
        />
        <KeyValueGrid
          keyName={speedFee}
          value={getSpeedFeeText()}
          {...gridProps}
        />
        <KeyValueGrid
          keyName={createdOn}
          value={dateTimeFormatInApp(created)}
          {...gridProps}
        />
      </Box>
    );
  };

  const swapEventRows = swapEventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  return !isShowNotFound ? (
    <Grid className="payments-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {swListingswapFe ? (
          <>
            {data ? (
              <>
                {headerContent()}
                <Box className="details-content">
                  <Text size={20} className="default-text">
                    {swapDetailsText}
                  </Text>
                </Box>
                <CustomDivider sx={{ my: "12px" }} />
                {renderSwapDetails()}
              </>
            ) : (
              <PaymentsSkeleton props={props} />
            )}

            {!swapEventSkeleton ? (
              <Box className="product-content">
                <HorizontalTable
                  label={eventsText}
                  isColumnShown={swapEventRows?.length > 0}
                  rows={swapEventRows}
                  rowsPerPage={3}
                  handleViewAllClick={() => {
                    history.push(`/swaps/${urlParams?.id}/events`);
                  }}
                  isShowButton={swapEventRows?.length > 3}
                  tableBodyClassName="border-none"
                />
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        ) : (
          <AccessDenied />
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Swap")}
        subTitle={`Swap ${urlParams.id} is not found.`}
        btnText={`View ${(liveMode && "live") || "test"} mode swaps`}
      />
    </Box>
  );
};

export default SwapDetails;
