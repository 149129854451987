import { Box, MenuItem, Skeleton } from "@mui/material";
import {
  fetchPrecisedInteger,
  getCurrencyObj,
  getCurrentTimeInSecond,
  showAmount,
} from "@speed/common/src/components/constants";
import { currencyForSwap } from "@speed/common/src/components/currencyForSwap";
import { Input } from "@speed/common/src/components/Input/Input";
import CustomSelect from "@speed/common/src/components/Select/Select";
import Text from "@speed/common/src/components/Text/Text";
import React, { useEffect, useState } from "react";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import {
  amountYouGet,
  amountYouPay,
  balanceAvailableForModule,
  from,
  sats,
  speedFee,
  swapDetailsText,
  swapMaxAmount,
  swapText,
  to,
  totalAmountYouGet,
  totalSwapLimitText,
} from "../messages";
import AmountWithCurrencyInput from "../Common/AmountWithCurrencyInput";
import EstimateFeesRowSection from "../Common/EstimateFeesRowSection";
import { callAPIInterface, getSwapTargetCurrencyExRate } from "../constants";
import ClockTimer from "@speed/common/src/components/ClockTimer/ClockTimer";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";

const SwapRequestDetails = (props) => {
  const {
    values,
    getSpeedFee,
    setFieldValue,
    touched,
    setTouched,
    errors,
    defaultErrorMessageProps,
    speedFeeLoading,
    swapLimitLoading,
    balanceLoading,
    balanceData,
    setDisableSwapBtn,
    isValid,
    dirty,
  } = props;

  const fromCurrency = values.currency?.code;
  const toCurrency = values.target_currency?.code;
  const amountCurrency = values.amountCurrency?.code;
  const amountError = touched.targetAmount && Boolean(errors.targetAmount);
  const leftCount = values.swapLimitData?.available_limit || 0;
  const reachedLimit = leftCount === 0;

  const [timeoutId, setTimeoutId] = useState(null);
  const [quoteLoader, setQuoteLoader] = useState(false);
  const [quoteData, setQuoteData] = useState(null);
  const [quoteError, setQuoteError] = useState(true);
  const [refreshQuoteData, setRefreshQuoteData] = useState(false);

  const isBalanceNotAvailable = +values.targetAmount > +values.balance;
  const isValidAmount = values.amount > 0 && !quoteError;

  const getSwapQuoteDetails = () => {
    setQuoteLoader(true);
    const body = {
      target_currency_swap_out: fromCurrency, // FROM
      target_currency_swap_in: toCurrency, // TO
      currency: amountCurrency,
      amount: values.amount,
    };

    callAPIInterface("POST", "/balances/swap/quote", body)
      .then((res) => {
        if (res) {
          const amountPay = showAmount({
            amount: res.target_amount_swap_out,
            currency: res.target_currency_swap_out,
          });
          setQuoteData(res);
          fromCurrency !== amountCurrency &&
            setFieldValue("targetAmount", amountPay);
          setFieldValue("swap_quote_id", res.swap_quote_id);
          setQuoteError(false);
          setQuoteLoader(false);
        }
      })
      .catch(() => {
        setQuoteError(true);
        setQuoteLoader(false);
      });
  };

  const displayFormatedAmount = (amount, currency, isForSpeedFee = false) => {
    const mathMethod = isForSpeedFee ? "ceil" : "floor";
    const calculatedAmount =
      currency === sats
        ? Math[mathMethod]?.(amount)
        : fetchPrecisedInteger(amount, 2);

    const commonProps = {
      amount: calculatedAmount || 0,
      currency,
      ...(isForSpeedFee && { multiplier: 1 }),
    };

    const amountObj = {
      withComma: showAmount({
        ...commonProps,
        showCommaSeparated: true,
      }),
      withoutComma: showAmount({
        ...commonProps,
      }),
    };
    return amountObj;
  };

  const handleChangeCurrency = async (e) => {
    const currency = e.target.value;
    const targetCurrency = currencyForSwap?.find(
      (currencyObj) => currencyObj.code !== currency
    );
    const amountBalance =
      balanceData?.find((value) => value.target_currency === currency)
        ?.amount || 0;
    getSpeedFee(targetCurrency?.code);
    setFieldValue("balance", showAmount({ amount: amountBalance, currency }));
    setFieldValue("currency", getCurrencyObj(currency));
    setFieldValue("target_currency", targetCurrency);
    setFieldValue("amountCurrency", getCurrencyObj(currency));
    setFieldValue("amount", "");
    setFieldValue("targetAmount", "");
    setTouched({ ...touched, amount: false });
  };

  const handleSwapMaxClick = () => {
    setFieldValue("amount", values.balance);
    setFieldValue("targetAmount", values.balance);
  };

  const handleAmountCurrencyChange = (value) => {
    setTouched({ ...touched, amount: false });
    setFieldValue("amountCurrency", value);
    setFieldValue("amount", "");
    setFieldValue("targetAmount", "");
  };

  const handleInputChange = (enteredAmount) => {
    let targetAmount = "";
    if (fromCurrency === amountCurrency) {
      targetAmount = enteredAmount;
    } else {
      targetAmount = !enteredAmount || enteredAmount > 0 ? "" : 0;
    }
    setFieldValue("amount", enteredAmount);
    setFieldValue("targetAmount", targetAmount);
  };

  useEffect(
    () =>
      refreshQuoteData &&
      isValidAmount &&
      !isBalanceNotAvailable &&
      getSwapQuoteDetails(),
    [refreshQuoteData]
  );

  useEffect(() => {
    if (quoteData?.swap_quote_expires_at) {
      const timer = setInterval(() => {
        const currentSeconds = getCurrentTimeInSecond(
          quoteData?.swap_quote_expires_at
        );
        setRefreshQuoteData(currentSeconds <= 0);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [quoteData?.swap_quote_expires_at]);

  useEffect(() => {
    setQuoteLoader(true);
    if (timeoutId) clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(() => {
      if (values.amount > 0 && !isBalanceNotAvailable) {
        getSwapQuoteDetails();
      }
    }, 500);
    setTimeoutId(newTimeoutId);
  }, [values.amount, isBalanceNotAvailable]);

  useEffect(() => {
    const shouldDisable =
      !(isValid && dirty) || !isValidAmount || quoteLoader || speedFeeLoading;
    setDisableSwapBtn(shouldDisable);
  }, [
    dirty,
    isValid,
    isValidAmount,
    quoteLoader,
    values.amount,
    values.targetAmount,
  ]);

  const renderHeaderSection = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Text className="default-text" size={20} variant="h2">
        {swapDetailsText}
      </Text>
      <Text
        className="default-text"
        size={20}
        variant="h2"
        sx={{ color: "#2A67FF !important" }}
      >
        {swapLimitLoading ? (
          <Skeleton width={100} height={25} />
        ) : (
          `${swapText} (${leftCount} Left)`
        )}
      </Text>
    </Box>
  );

  const renderLimitReachedSection = () =>
    reachedLimit &&
    values.swapLimitData && (
      <AlertMessage
        sx={{
          "&.MuiPaper-root": {
            margin: "30px 0px 24px 0px",
            alignItems: "flex-start !important",
            ".MuiAlert-icon .MuiSvgIcon-root": {
              fontSize: "24px",
            },
          },
        }}
        severity="error"
        message={
          <>
            <Text size={12} variant="h3">
              {totalSwapLimitText(values.swapLimitData)[0]}
            </Text>
            <Text font="regular" size={12} variant="h3">
              {totalSwapLimitText(values.swapLimitData)[1]}
            </Text>
          </>
        }
      />
    );

  const renderFromSection = () => (
    <Box width={600} className="margin-top30">
      <CustomSelect
        showLabel
        label={from}
        customClass="margin-top30"
        disabled={!values.balance || reachedLimit}
        value={
          values.currency && Object.keys(values.currency).length > 0
            ? fromCurrency
            : setFieldValue("currency", getCurrencyObj(sats))
        }
        MenuProps={{
          id: "payout-wallet-address",
          disableScrollLock: true,
        }}
        onChange={handleChangeCurrency}
        renderValue={() => {
          if (fromCurrency) {
            return fromCurrency;
          }
        }}
      >
        {currencyForSwap?.map((dropDownValue) => (
          <MenuItem value={dropDownValue.code} key={dropDownValue.code}>
            <Box>{dropDownValue.code}</Box>
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  );

  const renderExchangeRateText = () => {
    const renderText = () => {
      const swapInExRate = getSwapTargetCurrencyExRate(quoteData);
      const clockResetTimeInSec = getCurrentTimeInSecond(
        quoteData?.swap_quote_expires_at
      );
      const textElement = `1 ${fromCurrency} ≈ ${
        toCurrency === sats ? Math.floor(swapInExRate) : swapInExRate
      } ${toCurrency}`;

      return (
        <>
          <ClockTimer
            expiresAt={quoteData?.swap_quote_expires_at}
            strokeWidth={15}
            clockResetTimeInSec={clockResetTimeInSec}
            counterClockwise={false}
            height="16px"
            width="16px"
            timerColor="#fff"
            previewColor="#2A67FF"
          />
          {textElement}
        </>
      );
    };

    return (
      <Text
        className="grey-text"
        font="regular"
        size={14}
        variant="body1"
        sx={{
          gap: "5px",
          marginTop: "10px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {quoteLoader ? <Skeleton width={200} height={21} /> : renderText()}
      </Text>
    );
  };

  const renderToSection = () => {
    return (
      <Box width={600} className="margin-top30">
        <Input
          showLabel
          label={to}
          value={toCurrency}
          name="target_currency"
          fullWidth
          disabled
        />
        {isValidAmount && !isBalanceNotAvailable && renderExchangeRateText()}
      </Box>
    );
  };

  const inputEndAmountText = quoteLoader ? (
    <Skeleton width={50} height={8} />
  ) : (
    <>
      ≈{" "}
      {
        displayFormatedAmount(
          quoteData?.target_amount_swap_out,
          quoteData?.target_currency_swap_out
        )?.withComma
      }{" "}
      {fromCurrency}
    </>
  );

  const renderAmountSection = () => {
    const isSendMaxVisible =
      !reachedLimit && fromCurrency === amountCurrency && values.balance > 0;
    const disableAmountInput =
      values.balance <= 0 ||
      balanceLoading ||
      speedFeeLoading ||
      swapLimitLoading ||
      reachedLimit;
    const inputEndAmountTextVisible =
      isValidAmount && fromCurrency !== amountCurrency;

    const inputProps = {
      values,
      currencyName: "amountCurrency",
      amountName: "amount",
      isOptionEqualToValue: (option, value) => option.code === value.code,
      isSendMaxVisible,
      sendMaxBtnLabel: swapMaxAmount,
      getMaxAmount: handleSwapMaxClick,
      disableAmountInput,
      amountError,
      inputEndAmountTextVisible,
      inputEndAmountText,
      onBlurInput: () => setTouched({ ...touched, targetAmount: true }),
      handleCurrencyOnChange: handleAmountCurrencyChange,
      handleInputChange,
    };

    return (
      <>
        <AmountWithCurrencyInput {...inputProps} />
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="targetAmount"
        />
        {!reachedLimit && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="baseline"
            width={600}
          >
            <Text
              sx={{
                mt: "15px",
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                width: "75%",
              }}
              className="grey-text"
              size={14}
              variant="body1"
            >
              {balanceAvailableForModule("swap")}
              <Text className="default-text" size={14} variant="caption">
                {balanceLoading ? (
                  <Skeleton width={100} />
                ) : (
                  `${fetchPrecisedInteger(values.balance, 8)} ${fromCurrency}`
                )}
              </Text>
            </Text>
          </Box>
        )}
      </>
    );
  };

  const renderSwapBoxSection = () => {
    const {
      target_currency_swap_out: fromCurrency,
      target_currency_swap_in: toCurrency,
      target_amount_swap_out,
      target_amount_swap_in,
      currency,
      amount: currencyAmount,
    } = { ...quoteData };

    const amountPay = displayFormatedAmount(
      target_amount_swap_out,
      fromCurrency
    )?.withComma;

    const { withComma: amountGet, withoutComma: amountGetWithoutComma } =
      displayFormatedAmount(target_amount_swap_in, toCurrency);

    const { withComma: speedFees, withoutComma: feesWithoutComma } =
      displayFormatedAmount(
        amountGetWithoutComma * (values.speedFees / 100),
        toCurrency,
        true
      );

    const totalAmountGet = displayFormatedAmount(
      Number(amountGetWithoutComma) - Number(feesWithoutComma),
      toCurrency,
      true
    )?.withComma;

    const feesData = [
      {
        title: amountYouPay,
        feesAmount: amountPay,
        currencyToShow: fromCurrency,
      },
      {
        title: amountYouGet,
        feesAmount: amountGet,
        currencyToShow: toCurrency,
      },
      {
        title: speedFee,
        feesAmount: speedFees,
        currencyToShow: toCurrency,
      },
      {
        title: totalAmountYouGet,
        feesAmount: totalAmountGet,
        currencyToShow: toCurrency,
        showCurrencyAmount: true,
        selectedCurrency: currency,
        currencyAmount: displayFormatedAmount(currencyAmount, currency, true)
          ?.withComma,
        isLast: true,
      },
    ];

    return (
      <Box className="est-fees-box">
        {feesData.map((item, index) => (
          <EstimateFeesRowSection
            key={`${item.title}_${index}`}
            {...item}
            loader={quoteLoader}
          />
        ))}
      </Box>
    );
  };

  return (
    <Box
      className="margin-top30 margin-bottom30"
      display="flex"
      justifyContent="center"
    >
      <Box>
        {renderHeaderSection()}
        {renderLimitReachedSection()}
        {renderFromSection()}
        {renderToSection()}
        {renderAmountSection()}
        {isValidAmount && !isBalanceNotAvailable && renderSwapBoxSection()}
      </Box>
    </Box>
  );
};

export default SwapRequestDetails;
