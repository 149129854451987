import { useSelector } from "react-redux";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import {
  callAPIInterface,
  dateFromTimestampInApp,
  dateTimeFormatInApp,
  noOfRecords,
} from "../../constants";
import { Box, TableCell, TableRow } from "@mui/material";
import CustomTable from "@speed/common/src/components/Table/Table";
import { useCallback, useEffect, useState } from "react";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import {
  capitalizeFirstChar,
  reportStatus,
} from "@speed/common/src/components/constants";
import Button from "@speed/common/src/components/Button/Button";
import { download } from "../../messages";
import { emptyPayout } from "@speed/common/src/components/images";
import { emptySubText } from "@speed/common/src/components/messages";
import { saveAs } from "file-saver";

const columns = [
  { title: "Date requested", width: "250px", paddingLeft: "36px !important" },
  { title: "Export by", width: "150px" },
  { title: "Time period", width: "250px" },
  { title: "Type", width: "100px" },
  { title: "Source", width: "250px" },
  { title: "Statement", width: "100px" },
  { title: "Status", width: "120px" },
  { title: "Document", width: "120px" },
  { title: "Action", width: "190px", align: "center" },
];

function Reports() {
  const [rows, setRows] = useState([]);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState("?limit=" + noOfRecords);

  const currentAccount = useSelector((state) => state.auth.currentAccount);

  const loadReports = async () => {
    setTableRowSkeleton(true);
    callAPIInterface("GET", "/report" + queryParams)
      .then((res) => {
        setTableRowSkeleton(false);
        if (!res.has_more) {
          setHasMore(false);
        } else {
          setQueryParams(
            "?limit=" +
              noOfRecords +
              "&ending_before=" +
              res.data[res.data.length - 1].report_id
          );
        }
        setRows(rows.concat(res.data));
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(() => {
    loadReports();
  }, []);

  const loadMore = useCallback(() => {
    loadReports();
  }, [rows]);

  const columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ minWidth: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = rows?.map((rowItem) => {
    const {
      document_type,
      report_id,
      report_filters,
      s3_url,
      statement_type,
      status,
      created,
      first_name,
      last_name,
    } = { ...rowItem };
    const {
      report_from_timestamp,
      report_to_timestamp,
      transaction_source,
      transaction_type,
    } = {
      ...report_filters,
    };
    const exportedBy =
      ((first_name || "") + " " + (last_name || ""))?.trim() || "-";
    return (
      <TableRow key={report_id}>
        <TableCell sx={{ paddingLeft: "36px !important" }}>
          {dateTimeFormatInApp(created) || "-"}
        </TableCell>
        <TableCell>{exportedBy}</TableCell>
        <TableCell>
          {dateFromTimestampInApp(report_from_timestamp) +
            " to " +
            dateFromTimestampInApp(report_to_timestamp)}
        </TableCell>
        <TableCell>{capitalizeFirstChar(transaction_type) || "All"}</TableCell>
        <TableCell>
          {transaction_source?.map((type) => (
            <Tag
              key={type}
              sx={{ margin: "3px" }}
              text={type}
              variant="success-res"
            />
          )) || "All"}
        </TableCell>
        <TableCell>{capitalizeFirstChar(statement_type)}</TableCell>
        <TableCell>
          <Tag
            text={reportStatus[status]?.label}
            variant={reportStatus[status]?.variant}
          />
        </TableCell>
        <TableCell>{document_type?.toUpperCase()}</TableCell>
        <TableCell align="center">
          <Button
            sx={{
              "&.MuiButtonBase-root": {
                height: "36px",
                padding: "11px 18px !important",
                ">div": {
                  margin: "0px !important",
                },
              },
            }}
            disabled={!s3_url}
            variant="contained"
            label={download}
            onClick={() => {
              const accountId = currentAccount?.account?.id;
              const filename = `${report_id}_${accountId}_${statement_type}.${document_type}`;
              saveAs(s3_url, filename);
            }}
          />
        </TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton,
    hasMore,
    loadMore,
    noDataImage: emptyPayout,
    textOnNoData: emptySubText(true, "", "Reports"),
  };

  return (
    <>
      <CustomDivider />
      <Box className="main-content">
        <CustomTable {...tableProps} />
      </Box>
    </>
  );
}

export default Reports;
