import { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Label from "@speed/common/src/components/Label/Label";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import Text from "@speed/common/src/components/Text/Text";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { currency } from "@speed/common/src/components/currency";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Input } from "@speed/common/src/components/Input/Input";
import { enterAmount, fiat, sats } from "../messages";
import { amount } from "@speed/common/src/components/messages";
import { excludedCode } from "@speed/common/src/components/constants";
import { allowedFourAfterDecimalRegex } from "../constants";

const AmountWithCurrencyInput = ({
  values,
  showPopper = false,
  popperInfoMsg = "",
  minimumAmountLabel = "",
  isSendMaxVisible = false,
  sendMaxLoader,
  getMaxAmount,
  sendMaxBtnLabel,
  disableAmountInput,
  isOptionEqualToValue,
  currencyName,
  amountName,
  inputEndAmountTextVisible,
  inputEndAmountText,
  amountError,
  onBlurInput,
  handleAmountKeyDown,
  handleCurrencyOnChange,
  handleInputChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const amountCurrency = values[currencyName]?.code;
  const isSATSCurrency = amountCurrency === sats;

  const onKeyDown = (event) => {
    const excludedKey = ["e", "E", "+", "-"];
    if (isSATSCurrency) excludedKey.push(".");
    if (excludedKey.includes(event.key)) event.preventDefault();
    handleAmountKeyDown?.();
  };

  const onChangeInput = (e) => {
    const value = e.target.value;
    const isFiat = !excludedCode.includes(amountCurrency);
    if (value && isFiat && !allowedFourAfterDecimalRegex.test(value)) {
      e.preventDefault();
    } else handleInputChange?.(value.slice(0, isSATSCurrency ? 9 : 10));
  };

  const labelWithPopperElement = () => (
    <>
      <Box display="flex" alignItems="center">
        <Label>{amount}</Label>
        {showPopper && (
          <>
            <InfoIcon
              className="label-with-icon"
              sx={{
                height: "20px !important",
                width: "20px !important",
                marginBottom: "14px",
              }}
              onClick={(e) => setAnchorEl(anchorEl ? null : e.currentTarget)}
            />
            <CustomPopper
              disablePortal={true}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              position="top"
              handleClose={() => setAnchorEl(null)}
            >
              <Text className="grey-text" size={14} variant="subtitle1">
                {popperInfoMsg}
              </Text>
            </CustomPopper>
          </>
        )}
      </Box>
      {minimumAmountLabel}
    </>
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="margin-top30"
      >
        {labelWithPopperElement()}
        {isSendMaxVisible &&
          (sendMaxLoader ? (
            <Skeleton width={70} height={8} sx={{ mb: "14px" }} />
          ) : (
            <Label
              className="action-label instant-payout"
              tabIndex={0}
              onClick={getMaxAmount}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  getMaxAmount?.();
                }
              }}
            >
              {sendMaxBtnLabel}
            </Label>
          ))}
      </Box>
      <Box
        className={`dropdown-input-wrapper instant-payout-select-input ${
          disableAmountInput && "disable-amount-input"
        }`}
      >
        <CustomAutoComplete
          name={currencyName}
          options={currency}
          getOptionLabel={(value) => {
            return `${value.code}`;
          }}
          onChange={(_e, value) => handleCurrencyOnChange?.(value)}
          value={values[currencyName]}
          showLabel={false}
          disableClearable
          renderOption={(_props, option) => {
            return (
              <>
                <li {..._props}>{option.code}</li>
                {option.isDivider ? (
                  <CustomDivider
                    textAlign="left"
                    className="currency-divider"
                    text={fiat}
                  />
                ) : null}
              </>
            );
          }}
          customClass="instant-payout-select"
          disabled={disableAmountInput}
          {...{ isOptionEqualToValue }}
        />
        <Box
          className="auto-payout-vertical-line"
          sx={{
            margin: "auto",
            border: "1px solid #e4e9ee",
            height: "22px",
          }}
        />
        <Input
          name={amountName}
          value={values[amountName]}
          tabIndex={0}
          type="number"
          showLabel={false}
          className="auto-payout-amount-input"
          disabled={disableAmountInput}
          placeholder={enterAmount}
          error={Boolean(amountError)}
          onKeyDown={onKeyDown}
          onChange={onChangeInput}
          onBlur={onBlurInput}
          onWheel={(e) => e.target.blur()}
          isTextVisible={inputEndAmountTextVisible}
          inputText={inputEndAmountText}
          inputProps={{
            min: 0,
          }}
        />
      </Box>
    </>
  );
};

export default AmountWithCurrencyInput;
